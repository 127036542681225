export const LIVES_WITH_VALUES = ['SHARED_HOUSEHOLD', 'MOTHER', 'FATHER', 'PARTLY_MOTHER', 'PARTLY_FATHER'] as const
export type LivesWith = (typeof LIVES_WITH_VALUES)[number]

export const LIVES_WITH_NAMES: Record<LivesWith, string> = {
  SHARED_HOUSEHOLD: 'Gemeinsamer Haushalt',
  MOTHER: 'Mutter',
  FATHER: 'Vater',
  PARTLY_MOTHER: 'Teils Mutter',
  PARTLY_FATHER: 'Teils Vater',
}
