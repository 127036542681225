import { t } from '@lingui/macro'
import { Button, Card, Col, Modal, Row, Select, Typography } from 'antd'
import { nanoid } from 'nanoid'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Address } from '../types'
import ButtonSection from './form/button-section'
import { Field } from './form/field'
import SingleLineTextInput from './form/single-line-text-input'
import YesNoRadioButtons from './form/yes-no-radio-buttons'
const { Text } = Typography

type Props = {
  defaultValues?: Address
  familyId: string
  guardianAddresses: Address[]
  submitButtonText?: string
  handleSubmit: (data: Address) => void
  handlePrevious?: () => void
}

const BillingAddressForm = ({
  defaultValues,
  familyId,
  guardianAddresses,
  submitButtonText,
  handleSubmit,
  handlePrevious,
}: Props) => {
  const billingAddressIsNotGuardian = !guardianAddresses.some((address) => address.id === defaultValues?.id)

  const {
    control,
    register,
    formState: { errors },
    watch,
    setValue,
    getValues,
    handleSubmit: formHandleSubmit,
  } = useForm<{
    billingAddressIsNotGuardian: boolean
    billingAddress: Address
  }>({
    defaultValues: {
      billingAddressIsNotGuardian,
      billingAddress: defaultValues,
    },
  })

  const [newAddressModalOpen, setNewAddressModalOpen] = useState(false)

  const handleNewAddressModalOk = formHandleSubmit((values) => {
    setNewAddressModalOpen(false)
  })

  const mapIdToAddress = (id: string): Address => {
    const foundAddress = guardianAddresses.find((address) => address.id === id)
    if (foundAddress) {
      return foundAddress
    } else {
      throw new Error('No address found')
    }
  }

  const onSubmitHandler = formHandleSubmit((values) => {
    handleSubmit(values.billingAddress)
  })

  const watchBillingAddressIsNotGuardian = watch('billingAddressIsNotGuardian', billingAddressIsNotGuardian)
  const watchBillingAddress = watch('billingAddress', undefined)

  const handleOnChangeBillingAddressIsNotGuardian = (value: boolean) => {
    if (value) {
      setValue('billingAddress', {
        id: nanoid(),
        familyId,
        organisation: '',
        firstName: '',
        lastName: '',
        street: '',
        zip: '',
        city: '',
        email: '',
      })
    }
  }

  const currentBillingAddressIsGuardian = () =>
    guardianAddresses.some((address) => address.id === watchBillingAddress?.id)

  const billingAddressHasValue =
    !!watchBillingAddress && Object.values(watchBillingAddress).filter((value) => !!value).length > 2

  const billingAddressIsComplete = () => {
    const hasAddress =
      watchBillingAddress.street !== undefined &&
      watchBillingAddress.zip !== undefined &&
      watchBillingAddress.city !== undefined &&
      watchBillingAddress.email !== undefined

    if (watchBillingAddress.organisation || (watchBillingAddress.firstName && watchBillingAddress.lastName)) {
      return hasAddress
    } else {
      return false
    }
  }

  return (
    <>
      <Field
        label={t({ message: 'Rechnung an Drittperson stellen' }) + ' *'}
        error={errors?.billingAddressIsNotGuardian}
      >
        <YesNoRadioButtons
          control={control}
          register={register}
          name="billingAddressIsNotGuardian"
          firstButton="no"
          onChange={handleOnChangeBillingAddressIsNotGuardian}
        />
      </Field>
      {!watchBillingAddressIsNotGuardian && (
        <Field label={t({ message: 'Rechnungsadresse' }) + ' *'} error={errors?.billingAddress}>
          <Controller
            control={control}
            {...register('billingAddress')}
            rules={{ required: true, validate: currentBillingAddressIsGuardian }}
            render={() => (
              <Select
                id="billingAddress"
                style={{ width: '100%' }}
                onChange={(value) => {
                  setValue('billingAddress', mapIdToAddress(value))
                }}
                defaultValue={currentBillingAddressIsGuardian() ? getValues('billingAddress')?.id : undefined}
              >
                {guardianAddresses.map((address) => {
                  return (
                    <Select.Option key={address.id} value={address.id}>
                      {`${address.organisation} ${address.firstName} ${address.lastName}`}
                    </Select.Option>
                  )
                })}
              </Select>
            )}
          />
        </Field>
      )}
      {watchBillingAddressIsNotGuardian && (
        <Field label={`Rechnungsadresse`} error={errors?.billingAddress}>
          <Controller
            control={control}
            {...register('billingAddress')}
            rules={{ required: true, validate: billingAddressIsComplete }}
            render={() => (
              <>
                {billingAddressHasValue && (
                  <Card size="small" style={{ marginBottom: 8 }}>
                    <Row>
                      <Col span={24}>
                        {getValues('billingAddress.organisation') && (
                          <Field label={t({ message: 'Organisation' })} error={errors.billingAddress?.organisation}>
                            <Text>{getValues('billingAddress.organisation')}</Text>
                          </Field>
                        )}
                        {getValues('billingAddress.firstName') && (
                          <Field label={t({ message: 'Vorname' })} error={errors.billingAddress?.firstName}>
                            <Text>{getValues('billingAddress.firstName')}</Text>
                          </Field>
                        )}
                        {getValues('billingAddress.lastName') && (
                          <Field label={t({ message: 'Nachname' })} error={errors.billingAddress?.lastName}>
                            <Text>{getValues('billingAddress.lastName')}</Text>
                          </Field>
                        )}
                        <Field label={t({ message: 'Strasse und Hausnummer' })} error={errors.billingAddress?.street}>
                          <Text>{getValues('billingAddress.street')}</Text>
                        </Field>
                        <Field label={t({ message: 'PLZ' })} error={errors.billingAddress?.zip}>
                          <Text>{getValues('billingAddress.zip')}</Text>
                        </Field>
                        <Field label={t({ message: 'Ort' })} error={errors.billingAddress?.city}>
                          <Text>{getValues('billingAddress.city')}</Text>
                        </Field>
                        <Field label={t({ message: 'Email' })} error={errors.billingAddress?.email}>
                          <Text>{getValues('billingAddress.email')}</Text>
                        </Field>
                      </Col>
                    </Row>
                  </Card>
                )}

                <Button style={{ marginBottom: 12, width: '100%' }} onClick={() => setNewAddressModalOpen(true)}>
                  {billingAddressHasValue ? t({ message: 'Adresse ändern' }) : t({ message: 'Adresse erfassen' })}
                </Button>

                <Modal
                  title={t({ message: 'Rechnungsadresse hinzufügen' })}
                  open={newAddressModalOpen}
                  onOk={handleNewAddressModalOk}
                  onCancel={() => setNewAddressModalOpen(false)}
                >
                  <Field label={t({ message: 'Organisation' })} error={errors.billingAddress?.organisation}>
                    <SingleLineTextInput
                      name="billingAddress.organisation"
                      control={control}
                      maxLength={200}
                      validate={(value, formValue) =>
                        !!value ||
                        !!formValue.billingAddress.firstName ||
                        !!formValue.billingAddress.lastName ||
                        t({ message: `Bitte entweder Organisation oder Vor- und Nachname angeben` })
                      }
                    />
                  </Field>
                  <Field label={t({ message: 'Vorname' })} error={errors.billingAddress?.firstName}>
                    <SingleLineTextInput
                      name="billingAddress.firstName"
                      control={control}
                      maxLength={200}
                      validate={(value, formValue) =>
                        !!formValue.billingAddress.organisation ||
                        !!value ||
                        t({ message: `Bitte entweder Organisation oder Vor- und Nachname angeben` })
                      }
                    />
                  </Field>
                  <Field label={t({ message: 'Nachname' })} error={errors.billingAddress?.lastName}>
                    <SingleLineTextInput
                      name="billingAddress.lastName"
                      control={control}
                      maxLength={200}
                      validate={(value, formValue) =>
                        !!formValue.billingAddress.organisation ||
                        !!value ||
                        t({ message: `Bitte entweder Organisation oder Vor- und Nachname angeben` })
                      }
                    />
                  </Field>
                  <Field label={t({ message: 'Strasse und Hausnummer' }) + ' *'} error={errors.billingAddress?.street}>
                    <SingleLineTextInput
                      name="billingAddress.street"
                      control={control}
                      required={true}
                      maxLength={200}
                    />
                  </Field>
                  <Field label={t({ message: 'PLZ' }) + ' *'} error={errors.billingAddress?.zip}>
                    <SingleLineTextInput name="billingAddress.zip" control={control} required={true} maxLength={32} />
                  </Field>
                  <Field label={t({ message: 'Ort' }) + ' *'} error={errors.billingAddress?.city}>
                    <SingleLineTextInput name="billingAddress.city" control={control} required={true} maxLength={200} />
                  </Field>
                  <Field label={t({ message: 'Email' }) + ' *'} error={errors.billingAddress?.email}>
                    <SingleLineTextInput
                      type="email"
                      name="billingAddress.email"
                      control={control}
                      required={true}
                      maxLength={200}
                    />
                  </Field>
                </Modal>
              </>
            )}
          />
        </Field>
      )}

      <ButtonSection
        submitButtonText={submitButtonText}
        onNext={onSubmitHandler}
        onPrevious={handlePrevious && handlePrevious}
      />
    </>
  )
}

export default BillingAddressForm
