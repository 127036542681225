import { Capacitor } from '@capacitor/core'
import { Trans, t } from '@lingui/macro'
import { Layout, Typography } from 'antd'
import { SafeArea } from 'capacitor-plugin-safe-area'
import { useEffect, useState } from 'react'

const { Content, Footer } = Layout
const { Title, Text, Paragraph } = Typography

export const DeleteAccount = () => {
  const [mobileInsets, setMobileInsets] = useState<
    | {
        top: number
        right: number
        bottom: number
        left: number
      }
    | undefined
  >()

  useEffect(() => {
    if (Capacitor.getPlatform() === 'ios') {
      SafeArea.getSafeAreaInsets().then(({ insets }) => {
        setMobileInsets(insets)
      })
    }
  }, [])

  return (
    <Layout className="layout" style={{ minHeight: '100vh' }}>
      <Content>
        <div style={{ display: 'flex', flexDirection: 'column', padding: '2em', gap: '2em' }}>
          <Title>{t({ message: `So löschen Sie Ihr Leoba-Konto` })}</Title>
          <Paragraph>
            {t`Sie können in der App oben rechts auf Ihr Benutzer-Symbol und anschliessend auf "Einstellungen" tippen. Dort
            finden Sie eine Option "Konto löschen". Damit löschen Sie Ihr Zugang und können sich nicht mehr mit Ihrer 
            E-Mail-Adresse und Passwort einloggen.`}
          </Paragraph>
          <Paragraph>{t`Die Leoba-App für Eltern ist eine Ergänzung zu Ihrem Betreuungsvertrag mit Ihrer Institution. Mit dem Löschen
          des Zugangs wird der Vertrag allerdings nicht gekündigt. Die erhobenen Daten werden für Ihre
          Institution und die Leoba GmbH weiter einzusehen bleiben.`}</Paragraph>
          <Paragraph>
            <Trans>
              Wünschen Sie eine Löschung aller Daten, melden Sie sich bitte bei{' '}
              <a href="mailto:info@leoba.ch">info@leoba.ch</a>. Wir kümmern uns dann um Ihr Anliegen persönlich.
            </Trans>
          </Paragraph>
        </div>
      </Content>
      <Footer style={{ textAlign: 'center' }}>
        <Text>©2023 Leoba GmbH</Text>
      </Footer>
      <div style={{ height: mobileInsets ? `${mobileInsets.bottom}px` : '0px' }} />
    </Layout>
  )
}
