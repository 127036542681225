import { Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import GuardianForm1 from '../../../../components/guardian/guardian-1-form'
import GuardianForm2 from '../../../../components/guardian/guardian-2-form'
import { useInstitutionContext } from '../../institution-context'
import { useGuardianContext } from '../guardian-context'
import FormPage from '../../../../components/form/form-page'
import { t } from '@lingui/macro'
import { NewGuardian } from '../../../../components/guardian/guardian-form-fields'
import { Guardian, TaxInformation } from '../../../../types'
import { dateString } from '../../../../helper/date-string'
import TaxInformationForm from '../../../../components/tax-information-form'
import { persistTaxInformation } from '../tax-information'
import FamilyInformationForm, { FamilyInformation } from '../../../../components/family-information-form'

const CheckGuardianDataRouter = () => {
  const { family, fetchFamily, taxInformation } = useGuardianContext()
  const { institution } = useInstitutionContext()
  const navigate = useNavigate()
  const location = useLocation()
  const guardianCount = family?.guardians?.length || 0

  const routes = [
    { path: 'guardian-one', active: true },
    { path: 'guardian-two', active: guardianCount > 1 },
    { path: 'subsidies', active: institution?.features.subsidiesHuenenberg },
    {
      path: 'family-information',
      active: institution?.features.surbtalPricing || institution?.features.ennetbadenPricing,
    },
  ]

  const getActiveRoutes = () => routes.filter((route) => route.active)
  const getActiveRouteIndex = () => getActiveRoutes().findIndex((route) => location.pathname.endsWith(`/${route.path}`))
  const getLastActiveRoute = () => getActiveRoutes()[getActiveRoutes().length - 1]
  const getNextRoute = () => getActiveRoutes()[getActiveRouteIndex() + 1]
  const getPrevRoute = () => getActiveRoutes()[getActiveRouteIndex() - 1] ?? getLastActiveRoute()

  const navigateToNextPage = () => {
    const activeRouteIndex = getActiveRouteIndex()
    if (activeRouteIndex >= 0 && activeRouteIndex < getActiveRoutes().length - 1) {
      navigate(getNextRoute().path)
    } else if (activeRouteIndex === getActiveRoutes().length - 1) {
      finishGuardianDataCheck()
    }
  }

  const navigateToPrevPage = () => {
    if (getActiveRouteIndex() > 0) {
      navigate(getPrevRoute().path)
    }
  }

  const finishGuardianDataCheck = async () => {
    await updateFamilyData(family!.id)
    fetchFamily()
    navigate('../')
  }

  const handleSubmitGuardianFormOne = async (data: NewGuardian) => {
    await updateGuardianData(data)
    navigateToNextPage()
  }

  const handleSubmitGuardianFormTwo = async (data: NewGuardian) => {
    await updateGuardianData(data)
    navigateToNextPage()
  }

  const handleSubmitTaxInformation = async (data: TaxInformation) => {
    if (institution && family) {
      await persistTaxInformation({ institutionId: institution.id, familyId: family.id, taxInformation: data })
      navigateToNextPage()
    }
  }

  const handleSubmitFamilyInformation = async (data: FamilyInformation) => {
    await updateFamilyData(family!.id, data)
    navigateToNextPage()
  }

  const updateGuardianData = async (data: NewGuardian) => {
    const body = JSON.stringify(data)
    await fetch(
      `${process.env.REACT_APP_LEOBA_SERVER}/api/eltern-app/${institution?.id}/guardian/guardian-data?guardianId=${data.id}`,
      {
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
        },
        body,
      }
    )
  }

  const updateFamilyData = async (familyId: string, familyInformation?: FamilyInformation) => {
    const body = JSON.stringify({ lastChecked: dateString(new Date()), ...familyInformation })
    await fetch(
      `${process.env.REACT_APP_LEOBA_SERVER}/api/eltern-app/${institution?.id}/guardian/family-data?familyId=${familyId}`,
      {
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
        },
        body,
      }
    )
  }

  return (
    <>
      <Outlet />
      <Routes>
        {family && institution && (
          <Route
            path="guardian-one"
            element={
              <FormPage title={t({ message: 'Erziehungsberechtigte Person erfassen' })}>
                <GuardianForm1
                  familyId={family.id}
                  isSecondParent={true}
                  defaultValues={convertToNewGuardian(family.guardians[0])}
                  registrationConfiguration={institution.registrationConfig}
                  handleSubmit={handleSubmitGuardianFormOne}
                  handlePrevious={navigateToPrevPage}
                />
              </FormPage>
            }
          />
        )}
        {family && institution && (
          <Route
            path="guardian-two"
            element={
              <FormPage title={t({ message: 'Erziehungsberechtigte Person erfassen' })}>
                <GuardianForm2
                  familyId={family.id}
                  isSecondParent={true}
                  defaultValues={convertToNewGuardian(family.guardians[1])}
                  registrationConfiguration={institution.registrationConfig}
                  handleSubmit={handleSubmitGuardianFormTwo}
                  handlePrevious={navigateToPrevPage}
                />
              </FormPage>
            }
          />
        )}
        {family && institution && (
          <Route
            path="subsidies"
            element={
              <FormPage title={t({ message: 'Subventionierung' })}>
                <TaxInformationForm
                  familyId={family.id}
                  defaultOutOfTown={family.children.some((child) => child.isOutOfTown)}
                  handleSubmit={handleSubmitTaxInformation}
                  handleOutOfTownChild={() => {}}
                  initialValues={taxInformation}
                  handlePrevious={navigateToPrevPage}
                />
              </FormPage>
            }
          />
        )}
        {family && institution && (
          <Route
            path="family-information"
            element={
              <FormPage title={t({ message: 'Informationen zur Familie' })}>
                <FamilyInformationForm
                  handleSubmit={handleSubmitFamilyInformation}
                  handlePrevious={navigateToPrevPage}
                />
              </FormPage>
            }
          />
        )}
        <Route path="*" element={<>404</>} />
      </Routes>
    </>
  )
}

export { CheckGuardianDataRouter }

const convertToNewGuardian = (guardian: Guardian): NewGuardian => {
  return {
    ...guardian,
    soleCustodyPdf: '',
    soleCustodyPdfName: '',
  }
}
