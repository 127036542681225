import { Contract } from '../types'
import { dateString } from './date-string'

export const getActiveContract = (contracts: Contract[], now: string = dateString(new Date())): Contract => {
  const result = findActiveContract(contracts, now)

  if (result) {
    return result
  } else {
    throw new Error('no Active Contract')
  }
}

export const getActiveContractOrUndefined = (
  contracts: Contract[],
  now: string = dateString(new Date())
): Contract | undefined => {
  const result = findActiveContract(contracts, now)

  if (result) {
    return result
  } else {
    return undefined
  }
}

const findActiveContract = (contracts: Contract[], now: string = dateString(new Date())): Contract | undefined => {
  contracts.sort((a, b) => a.startDate.getTime() - b.startDate.getTime())
  const result = contracts
    .filter((contract) => contract.state === 'accepted' || contract.state === 'cancelled')
    .find((contract) => {
      const endDate = contract.endDate ? dateString(contract.endDate) : '9999-99-99'
      return now <= endDate
    })

  return result
}
