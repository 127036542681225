import { useLingui } from '@lingui/react'
import formatChf from '../../../helper/format-chf'
import { toDateWithWeekday } from '../../../helper/format-date'
import { HolidayCare, HolidayCareBooking } from '../../../types'
import ButtonSection from '../../form/button-section'
import styles from './child-holiday-care-form.module.css'
import { Button, Card, Checkbox, Typography } from 'antd'
import { useState } from 'react'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import dayjs from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek'
import { t } from '@lingui/macro'

const { Title, Paragraph } = Typography

export type NewHolidayCareBookingType = Pick<HolidayCareBooking, 'id' | 'approvalState'>

type Props = {
  holidayCare: HolidayCare[]
  defaultValues?: NewHolidayCareBookingType[]
  isEditMode?: boolean
  onSubmit: (data: NewHolidayCareBookingType[]) => void
  onPrevious?: (data: NewHolidayCareBookingType[]) => void
}

type ViewData = {
  week: string
  days: { date: Date; dateString: string; holidayCare: HolidayCare[] }[]
}[]

const ChildHolidayCareForm = ({ holidayCare, onSubmit, isEditMode, defaultValues = [], onPrevious }: Props) => {
  const [bookings, setBookings] = useState(defaultValues)
  const { i18n } = useLingui()
  const handleOnNext = () => {
    onSubmit(bookings)
  }
  const handlePrevious = () => {
    if (onPrevious) {
      onPrevious(bookings)
    }
  }

  const handleChange = (e: CheckboxChangeEvent) => {
    if (e.target.id) {
      if (e.target.checked) {
        setBookings([...bookings, { id: e.target.id, approvalState: 'new' }])
      } else {
        setBookings(bookings.filter((booking) => booking.id !== e.target.id))
      }
    }
  }

  const submitButtonText = isEditMode ? t({ message: 'Speichern' }) : undefined

  const holidayCareSorted = [...holidayCare].sort(compareHolidayCare)
  const viewData: ViewData = holidayCareSorted
    .map((hc) => {
      return {
        date: hc.date,
        dateString: toDateWithWeekday(hc.date, i18n.locale),
        holidayCare: [hc],
      }
    })
    .reduce(groupByDate, [] as ViewData)

  return (
    <>
      {!isEditMode && (
        <Button type="primary" onClick={handleOnNext}>
          {t({ message: 'Keine Ferienmodule buchen' })}
        </Button>
      )}
      <div className={styles.container}>
        <Paragraph></Paragraph>
        {viewData.map((week) => (
          <div key={week.week} className={styles.week}>
            {week.days.map((day) => (
              <div key={day.dateString} className={styles.day}>
                <Title level={3}>{day.dateString}</Title>
                {day.holidayCare.map((holidayCare) => (
                  <div key={holidayCare.id}>
                    <label htmlFor={holidayCare.id}>
                      <Card
                        size="small"
                        title={holidayCare.title}
                        style={{
                          boxShadow: bookings.some((booking) => booking.id === holidayCare.id)
                            ? 'inset 0 0 3em var(--main-accent-color)'
                            : 'none',
                        }}
                        extra={
                          <Checkbox
                            checked={bookings.some((booking) => booking.id === holidayCare.id)}
                            style={{ marginLeft: '0.5em' }}
                            id={holidayCare.id}
                            onChange={handleChange}
                          />
                        }
                      >
                        <Paragraph>
                          {holidayCare.startTime} - {holidayCare.endTime}
                        </Paragraph>
                        <Paragraph>{holidayCare.description}</Paragraph>
                        <Paragraph>{formatChf(holidayCare.cost.toNumber())}</Paragraph>
                      </Card>
                    </label>
                  </div>
                ))}
              </div>
            ))}
          </div>
        ))}
      </div>
      <ButtonSection
        submitButtonText={submitButtonText}
        onNext={handleOnNext}
        onPrevious={onPrevious && handlePrevious}
      />
    </>
  )
}

dayjs.extend(isoWeek)
const groupByDate = (
  acc: ViewData,
  current: { date: Date; dateString: string; holidayCare: HolidayCare[] }
): ViewData => {
  const date = dayjs(current.date)
  const isoWeekYear = `${date.isoWeekYear()}-${date.isoWeek()}`
  if (acc.length > 0 && acc[acc.length - 1].week === isoWeekYear) {
  } else {
    acc.push({ week: isoWeekYear, days: [] })
  }
  const weekObject = acc[acc.length - 1]
  if (weekObject.days.length > 0 && weekObject.days[weekObject.days.length - 1].dateString === current.dateString) {
    weekObject.days[weekObject.days.length - 1].holidayCare.push(current.holidayCare[0])
  } else {
    weekObject.days.push({ date: current.date, dateString: current.dateString, holidayCare: current.holidayCare })
  }
  return acc
}

export const compareHolidayCare = (a: HolidayCare, b: HolidayCare) =>
  holidayCareSortString(a).localeCompare(holidayCareSortString(b))

const holidayCareSortString = (hc: HolidayCare) => `${hc.date.toISOString()} ${hc.startTime} ${hc.title}`

export default ChildHolidayCareForm
