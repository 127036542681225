import { BookingMutation } from '../components/guardian-page/booking-mutation-card'
import { HolidayCare, HolidayCareBooking, HolidayCareGroup, Institution } from '../types'
import { translateExecutionDecision } from './translate-execution-decision'

const holidayCareBookingToBookingMutation = (
  holidayCareBooking: HolidayCareBooking & { holidayCare: HolidayCare & { groups: HolidayCareGroup[] } },
  institution: Institution
): BookingMutation => {
  const group = holidayCareBooking.holidayCareGroupId
    ? holidayCareBooking.holidayCare.groups.find((g) => g.id === holidayCareBooking.holidayCareGroupId)?.name
    : undefined
  return {
    id: holidayCareBooking.id,
    title: holidayCareBooking.holidayCare.title,
    date: new Date(holidayCareBooking.holidayCare.date).toDateString(),
    comment: translateExecutionDecision(holidayCareBooking.holidayCare, holidayCareBooking),
    group,
    approvalState: holidayCareBooking.approvalState,
    location: institution.locations.find((location) => location.id === holidayCareBooking.holidayCare.locationId)?.name,
  }
}

export default holidayCareBookingToBookingMutation
