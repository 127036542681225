import { i18n } from '@lingui/core'
import { messages as deMessages } from './de/messages.js'
import { messages as frMessages } from './fr/messages.js'
import { messages as itMessages } from './it/messages.js'
import { messages as enMessages } from './en/messages.js'
import { de as dePlurals, fr as frPlurals, it as itPlurals, en as enPlurals } from 'make-plural/plurals'
import { fromNavigator, multipleDetect } from '@lingui/detect-locale'

const configureLingui = (detectFromNavigator: typeof fromNavigator = fromNavigator) => {
  i18n.load('de', deMessages)
  i18n.load('fr', frMessages)
  i18n.load('it', itMessages)
  i18n.load('en', enMessages)
  i18n.loadLocaleData('de', { plurals: dePlurals })
  i18n.loadLocaleData('fr', { plurals: frPlurals })
  i18n.loadLocaleData('it', { plurals: itPlurals })
  i18n.loadLocaleData('en', { plurals: enPlurals })

  const detectedLanguages = multipleDetect(detectFromNavigator(), () => 'de').map(stripLocale)
  detectedLanguages.push('de')
  const matchedLanguages = detectedLanguages.filter(onlyAvailableLanguage)

  i18n.activate(matchedLanguages[0])
  document.documentElement.lang = matchedLanguages[0]
  return i18n
}

const stripLocale = (langAndLocal: string) => {
  return langAndLocal.split('-')[0]
}

const onlyAvailableLanguage = (language: string) => {
  return ['de', 'fr', 'it', 'en'].indexOf(language) >= 0
}

export default configureLingui
