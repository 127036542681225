import { Affix } from 'antd'
import { Content } from 'antd/es/layout/layout'
import { Institution } from '../../types'
import GuardianNavigation from './guardian-navigation'

type Props = {
  children: JSX.Element | JSX.Element[]
  institution?: Institution
}
const GuardianPage = ({ children, institution }: Props) => {
  return (
    <>
      {institution && (
        <Affix>
          <GuardianNavigation />
        </Affix>
      )}
      <Content style={{ padding: '0 1em' }}>
        <div className="site-layout-content">
          <>{children}</>
        </div>
      </Content>
    </>
  )
}

export default GuardianPage
