import { LoadingOutlined } from '@ant-design/icons'
import { FileOpener } from '@capacitor-community/file-opener'
import { Capacitor } from '@capacitor/core'
import { Directory, Filesystem } from '@capacitor/filesystem'
import { Button } from 'antd'
import React, { useEffect, useState } from 'react'
import { ContractDocument } from '../types'

const ContractAdditionalDocumentDownloadLink = ({
  contractDocument,
  institutionId,
}: {
  contractDocument: ContractDocument
  institutionId: string
}) => {
  const [contentBlob, setContentBlob] = useState<Blob | undefined>(undefined)
  const [contentType, setContentType] = useState('')

  const contentUrl = `${process.env.REACT_APP_LEOBA_SERVER}/api/common/${institutionId}/contract-additional-documents-download?contractId=${contractDocument.contractId}&fileName=${contractDocument.fileName}`

  useEffect(() => {
    const getContent = async () => {
      const response = await fetch(contentUrl)
      setContentType(response.headers.get('Content-Type') || '')
      const content = await response.blob()
      setContentBlob(content)
    }
    if (Capacitor.isNativePlatform() && contentBlob === undefined) {
      getContent()
    }
  })

  if (Capacitor.isNativePlatform() && !contentBlob) {
    return <LoadingOutlined />
  } else {
    return (
      <React.Fragment>
        {Capacitor.isNativePlatform() ? (
          <>
            {contentBlob !== undefined && (
              <Button type="link" onClick={() => openOnNativePlatform(contentBlob, contractDocument, contentType)}>
                <u>{contractDocument.fileName}</u>
              </Button>
            )}
          </>
        ) : (
          <Button type="link" href={contentUrl} target="_blank" rel="noopener noreferrer">
            <u>{contractDocument.fileName}</u>
          </Button>
        )}
      </React.Fragment>
    )
  }
}

export default ContractAdditionalDocumentDownloadLink

const openOnNativePlatform = (contractBlob: Blob, contractDocument: ContractDocument, contentType: string) => {
  const reader = new FileReader()
  reader.onloadend = async function () {
    const data = reader.result as string
    await Filesystem.writeFile({
      path: contractDocument.fileName,
      data,
      directory: Directory.Cache,
    })
    const getUriResult = await Filesystem.getUri({
      directory: Directory.Cache,
      path: contractDocument.fileName,
    })
    const filePath = getUriResult.uri
    FileOpener.open({ filePath, contentType })
  }
  reader.readAsDataURL(contractBlob)
}
