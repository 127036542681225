import { t } from '@lingui/macro'
import formatChf from '../../../../helper/format-chf'
import { ModulePrice } from '../../../../types'

export const renderModulePrice = (price: ModulePrice) => {
  if (!price) return null
  return (
    <>
      <p>
        {t({ message: price.type === 'MONTHLY' ? 'Preis pro Monat' : 'Preis pro Besuch' }) +
          ': ' +
          formatChf(price.amount)}
      </p>
      <p>
        {' '}
        {price.amountForSiblings < price.amount &&
          t({ message: 'mit Geschwister' }) + ': ' + formatChf(price.amountForSiblings)}
      </p>
    </>
  )
}
