import { Button, Col, Row } from 'antd'
import { Trans } from '@lingui/macro'
import { useState } from 'react'

type Props = {
  onNext?: () => Promise<void> | void
  onPrevious?: () => void
  submitButtonText?: string
  previousButtonText?: string
}

const ButtonSection = ({ onNext, onPrevious, submitButtonText, previousButtonText }: Props) => {
  const [nextProcessing, setNextProcessing] = useState(false)

  const handleOnClick = async () => {
    if (onNext) {
      setNextProcessing(true)
      try {
        await onNext()
      } finally {
        setNextProcessing(false)
      }
    }
  }

  return (
    <Row style={{ gap: '0.5em', flexWrap: 'nowrap', paddingBlock: '1em' }}>
      <Col flex={1}>
        {onPrevious && (
          <Button type="primary" onClick={onPrevious}>
            {previousButtonText ? previousButtonText : <Trans>Zurück</Trans>}
          </Button>
        )}
      </Col>
      <Col flex={1} style={{ textAlign: 'right' }}>
        {onNext && (
          <Button type="primary" loading={nextProcessing} onClick={handleOnClick}>
            {submitButtonText ? submitButtonText : <Trans>Weiter</Trans>}
          </Button>
        )}
      </Col>
    </Row>
  )
}
export default ButtonSection
