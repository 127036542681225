import { t, Trans } from '@lingui/macro'
import { Row } from 'antd'
import FormPage from '../../../../components/form/form-page'
import { useInstitutionContext } from '../../institution-context'
import LeobaMarkdown from '../../../../components/LeobaMarkdown'
import { useEffect } from 'react'
import { useRegistrationContext } from '../registration-context'
import { useNewChildContext } from './child-form-pages/new-child-context'

const DoneFormPage = () => {
  const { institution } = useInstitutionContext()
  useEffect(() => {
    useRegistrationContext.getState().reset()
    useNewChildContext.getState().reset()
  }, [])
  return (
    <FormPage title={t({ message: 'Anmeldung erfolgreich abgeschickt' })}>
      {institution?.registrationConfig.finishText ? (
        <LeobaMarkdown markdown={institution?.registrationConfig.finishText} />
      ) : (
        <>
          <Row style={{ marginBlock: '1em' }}>
            <Trans>
              Besten Dank für das Ausfüllen des Anmeldeformulars. Die Institutionsleitung wird sich so bald wie möglich
              mit Ihnen in Verbindung setzten.
            </Trans>
          </Row>
          <Row style={{ marginBlock: '1em' }}>
            <Trans>Freundliche Grüsse</Trans>
          </Row>
          <Row style={{ marginBlock: '1em' }}>{institution?.name || t({ message: 'Institution' })}</Row>
        </>
      )}
    </FormPage>
  )
}

export default DoneFormPage
