import { ArrowLeftOutlined, ArrowRightOutlined, UserOutlined } from '@ant-design/icons'
import { Avatar, Button, Typography } from 'antd'
import { useEffect, useState } from 'react'
import { useGuardianContext } from '../../routes/institution/guardian/guardian-context'
import { Child } from '../../types'

const { Title } = Typography

export const ChildSwitcher = () => {
  const family = useGuardianContext((state) => state.family)
  const currentChild = useGuardianContext((state) => state.currentChild)
  const setCurrentChild = useGuardianContext((state) => state.setCurrentChild)
  const [prevChild, setPrevChild] = useState<Child | undefined>(undefined)
  const [nextChild, setNextChild] = useState<Child | undefined>(undefined)

  useEffect(() => {
    if (currentChild && family) {
      const childIndex = family.children.findIndex((child) => child.id === currentChild.id)
      if (childIndex > 0) {
        setPrevChild(family.children[childIndex - 1])
      } else {
        setPrevChild(undefined)
      }
      if (childIndex < family.children.length - 1) {
        setNextChild(family.children[childIndex + 1])
      } else {
        setNextChild(undefined)
      }
    }
  }, [currentChild, family])

  return (
    <div
      style={{
        flexWrap: 'wrap',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      }}
    >
      {family && (
        <>
          {prevChild ? (
            <Button icon={<ArrowLeftOutlined />} onClick={() => setCurrentChild(prevChild)} />
          ) : (
            <div style={{ width: '32px' }} />
          )}
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Avatar icon={<UserOutlined />} size={100}></Avatar>
            <Title level={3}>{currentChild?.firstName}</Title>
          </div>
          {nextChild ? (
            <Button icon={<ArrowRightOutlined />} onClick={() => setCurrentChild(nextChild)} />
          ) : (
            <div style={{ width: '32px' }} />
          )}
        </>
      )}
    </div>
  )
}
