export const RELIGIONS = ['ROMAN_CATHOLIC', 'REFORMED', 'MUSLIM', 'OTHER'] as const

export type Religion = (typeof RELIGIONS)[number]

export const RELIGION_NAMES: Record<Religion, string> = {
  ROMAN_CATHOLIC: 'römisch-katholisch',
  REFORMED: 'reformiert',
  MUSLIM: 'muslimisch',
  OTHER: 'andere/konfessionslos',
}
