import dataDE from 'localized-countries/data/de_CH.json'
import dataEN from 'localized-countries/data/en.json'
import dataFR from 'localized-countries/data/fr_CH.json'
import dataIT from 'localized-countries/data/it_CH.json'

const localizedCountryList = (locale: string) => {
  const data = getDataFromLocale(locale)

  const localizedCountries = Object.keys(data)
    .map((key, index) => ({ value: key, label: (data as any)[key] as string }))
    .reduce((acc: { value: string; label: string }[], curr) => {
      if (curr.value.toLowerCase() === 'ch') {
        return [curr, ...acc]
      }
      return [...acc, curr]
    }, [])

  return localizedCountries
}

export default localizedCountryList

const getDataFromLocale = (locale: string) => {
  switch (locale) {
    case 'en':
      return dataEN
    case 'fr':
      return dataFR
    case 'it':
      return dataIT
    case 'de':
    default:
      return dataDE
  }
}
