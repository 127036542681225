import { useEffect } from 'react'
import { Outlet, useLoaderData } from 'react-router-dom'
import { HolidayCare, Institution, RegistrationConfiguration, School } from '../../types'
import { useInstitutionContext } from './institution-context'
import { useRegistrationContext } from './registration/registration-context'
import { useNewChildContext } from './registration/form-pages/child-form-pages/new-child-context'

export const InstitutionPage = () => {
  const institution: Institution & {
    registrationConfig: RegistrationConfiguration
    schools: School[]
    holidayCare: HolidayCare[]
  } = useLoaderData() as Institution & {
    registrationConfig: RegistrationConfiguration
    schools: School[]
    holidayCare: HolidayCare[]
  }
  const setInstitution = useInstitutionContext((state) => state.setInstitution)
  const { institutionId, setInstitutionId, reset } = useRegistrationContext((state) => ({
    institutionId: state.institutionId,
    setInstitutionId: state.setInstitutionId,
    reset: state.reset,
  }))
  const resetNewChildContext = useNewChildContext((state) => state.reset)

  useEffect(() => {
    setInstitution(institution)
    if (institutionId && institution.id !== institutionId) {
      reset()
      resetNewChildContext()
    } else {
      setInstitutionId(institution.id)
    }
  }, [institution, setInstitution, setInstitutionId, institutionId, reset, resetNewChildContext])
  return (
    <div>
      <Outlet />
    </div>
  )
}
