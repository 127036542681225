import { t } from '@lingui/macro'
import BillingAddressForm from '../../../../components/billing-address-form'
import FormPage from '../../../../components/form/form-page'
import { Address } from '../../../../types'

type Props = {
  defaultValues?: Address
  familyId: string
  guardianAddresses: Address[]
  handleSubmit: (data: Address) => void
  handlePrevious?: () => void
}

const BillingAddressFormPage = ({
  defaultValues,
  familyId,
  guardianAddresses,
  handleSubmit,
  handlePrevious,
}: Props) => {
  return (
    <FormPage title={t({ message: 'Rechnungsadresse' })} progress={7}>
      <BillingAddressForm
        defaultValues={defaultValues}
        familyId={familyId}
        guardianAddresses={guardianAddresses}
        handleSubmit={handleSubmit}
        handlePrevious={handlePrevious}
      />
    </FormPage>
  )
}

export default BillingAddressFormPage
