import { AgeGroup, Location, Module } from '../../../../types'

type ModuleChoice = Module & {
  chosen: boolean
  ageGroup?: AgeGroup
  location: Location
  comingFrom?: string
  comingFromTime?: string
  goingTo?: string
  goingToTime?: string
}

export const guessAgeGroup = (locations: Location[], chosenModules: ModuleChoice[]) => {
  return locations
    .flatMap((loc) => loc.ageGroups.map((ag) => ({ location: loc, ageGroup: ag })))
    .map((locationAndAgeGroup) => {
      return {
        ageGroup: locationAndAgeGroup.ageGroup,
        ageGroupCount: chosenModules.filter((choice) => choice.ageGroup?.id === locationAndAgeGroup.ageGroup.id).length,
      }
    })
    .reduce((acc, curr) => (acc.ageGroupCount > curr.ageGroupCount ? acc : curr)).ageGroup
}
