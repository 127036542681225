import { Capacitor } from '@capacitor/core'
import { App } from '@capacitor/app'

export const configureErrorLogging = () => {
  window.addEventListener('error', (error) => {
    reportError(error)
  })
  window.addEventListener('unhandledrejection', (rejection) => {
    reportRejection(rejection)
  })
}

export const reportError = async (error: Pick<ErrorEvent, 'message' | 'error'>) => {
  const body = { message: error.message } as any
  body.platform = Capacitor.getPlatform()
  if (body.platform !== 'web') {
    body.build = (await App.getInfo()).build
  }
  if (error.error instanceof Error) {
    body.stack = error.error.stack
    body.cause = error.error.cause
    body.name = error.error.name
  } else if (typeof error.error === 'string') {
    body.string = error.error
  }

  fetch(`${process.env.REACT_APP_LEOBA_SERVER}/api/eltern-app/log?path=${window.location.href}`, {
    method: 'PUT',
    body: JSON.stringify(body),
  })
}

const reportRejection = async (rejection: PromiseRejectionEvent) => {
  const body = {} as any
  body.platform = Capacitor.getPlatform()
  if (body.platform !== 'web') {
    body.build = (await App.getInfo()).build
  }
  if (rejection.reason instanceof Error) {
    body.message = rejection.reason.message
    body.name = rejection.reason.name
    body.stack = rejection.reason.stack
    body.cause = rejection.reason.cause
  } else if (typeof rejection.reason === 'string') {
    body.string = rejection.reason
  }
  fetch(`${process.env.REACT_APP_LEOBA_SERVER}/api/eltern-app/log?path=${window.location.href}`, {
    method: 'PUT',
    body: JSON.stringify(body),
  })
}
