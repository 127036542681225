import { t } from '@lingui/macro'
import { Alert, Button, Divider, Form, Input, Typography } from 'antd'
import { useEffect, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { signIn } from 'supertokens-web-js/recipe/emailpassword'
import { isEmailVerified } from 'supertokens-web-js/recipe/emailverification'
import FormPage from '../../components/form/form-page'
import AuthLayoutPage from './auth-layout-page'
import { reportError } from '../../reportErrors'

const { Paragraph } = Typography

type LoginFormType = { email: string; password: string }
type FieldError = { validateStatus: '' | 'success' | 'error'; message: string }

const LoginPage = () => {
  let [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const [form] = Form.useForm<LoginFormType>()
  const [fieldError, setFieldError] = useState<{ email: FieldError; password: FieldError }>({
    email: { validateStatus: '', message: '' },
    password: { validateStatus: '', message: '' },
  })
  const [credentialsError, setCredentialsError] = useState(false)
  const [generalError, setGeneralError] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    if (searchParams.get('signup') === '') {
      navigate('/auth/signup')
    }
  }, [searchParams, navigate])

  const handleOnFormSubmit = async (values: LoginFormType) => {
    const signInData = {
      formFields: [
        { id: 'email', value: values.email },
        { id: 'password', value: values.password },
      ],
    }
    setSubmitting(true)
    setGeneralError(false)
    setCredentialsError(false)
    try {
      const response = await signIn(signInData)
      switch (response.status) {
        case 'FIELD_ERROR':
          const emailMessage = response.formFields.find((f) => f.id === 'email')?.error || ''
          const passwordMessage = response.formFields.find((f) => f.id === 'password')?.error || ''
          setFieldError({
            email: { message: emailMessage, validateStatus: emailMessage ? 'error' : 'success' },
            password: { message: passwordMessage, validateStatus: passwordMessage ? 'error' : 'success' },
          })
          break
        case 'WRONG_CREDENTIALS_ERROR':
          setFieldError({
            email: { validateStatus: 'error', message: '' },
            password: { validateStatus: 'error', message: '' },
          })
          setCredentialsError(true)
          break
        default:
          const isVerified = await isEmailVerified()
          if (isVerified.isVerified) {
            navigate('/institution-index')
          } else {
            navigate('/auth/verify-email')
          }
      }
    } catch (e: any) {
      setGeneralError(true)
      reportError({ message: e.message, error: e })
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <AuthLayoutPage>
      <FormPage title={t({ message: `Anmelden` })}>
        <Form<LoginFormType> form={form} name="login" layout="vertical" onFinish={handleOnFormSubmit}>
          <Paragraph>
            {t({ message: `Noch nicht registriert?` })}{' '}
            <Link to={'/auth/signup'}>{t({ message: `Registrieren` })}</Link>
          </Paragraph>

          <Divider />

          {credentialsError && <Alert message={t({ message: `E-Mail oder Passwort falsch` })} type="error" />}
          {generalError && (
            <Alert message={t({ message: `Etwas ist schief gegangen. Bitte versuchen Sie es erneut.` })} type="error" />
          )}
          <Form.Item
            label={t({ message: `E-Mail` })}
            name="email"
            validateStatus={fieldError.email.validateStatus}
            help={fieldError.email.message}
          >
            <Input type="email" placeholder={t({ message: `E-Mail-Adresse` })} />
          </Form.Item>

          <Form.Item
            label={t({ message: `Passwort` })}
            name="password"
            validateStatus={fieldError.password.validateStatus}
            help={fieldError.password.message}
          >
            <Input.Password placeholder={t({ message: `Passwort` })} />
          </Form.Item>

          <Button loading={submitting} type="primary" htmlType="submit">
            {t({ message: `Anmelden` })}
          </Button>
          <Paragraph style={{ marginBlockStart: '1em' }}>
            <Link to="/auth/reset-password">{t({ message: `Passwort vergessen?` })}</Link>
          </Paragraph>
        </Form>
      </FormPage>
    </AuthLayoutPage>
  )
}

export default LoginPage
