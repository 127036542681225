import React from 'react'
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form'
import { Col, Row } from 'antd'
import ErrorMessage from '../error-message'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Typography } from 'antd'
const { Text } = Typography

type Props = {
  children: JSX.Element
  label: string
  description?: string
  error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | { message: string } | undefined
  alwaysBreak?: boolean
}

export const Field = ({ children, label, description, alwaysBreak, error }: Props) => {
  const id = getChildId(children)

  return (
    <>
      <Row
        gutter={16}
        style={{
          marginBottom: 4,
        }}
      >
        <Col
          md={alwaysBreak ? 24 : 12}
          xs={24}
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: 0 }}
        >
          <label htmlFor={id} className="form-label" style={{ fontWeight: 'bold' }}>
            <Text>{label}</Text>
            {!!description && <small style={{ fontWeight: 'normal' }}> ({description})</small>}
          </label>
          {error && <ExclamationCircleOutlined style={{ color: 'red' }} />}
        </Col>
        <Col md={alwaysBreak ? 24 : 12} xs={24}>
          {children}
        </Col>
      </Row>
      <Row gutter={16} style={{ marginBottom: 4 }}>
        {error && error.message && <ErrorMessage message={JSON.stringify(error.message)} />}
      </Row>
    </>
  )
}

export const getChildId = (children: JSX.Element) => {
  const child = React.Children.only(children)

  if ('id' in child?.props) {
    return child.props.id
  }
}
