import { toDateString } from '../helper/format-date'
import { Field } from './form/field'

type Props = {
  label: string
  value: Date | undefined
}

const DateField = ({ label, value }: Props) => {
  return (
    <>
      {value !== undefined && (
        <Field label={label}>
          <>{toDateString(value)}</>
        </Field>
      )}
    </>
  )
}

export default DateField
