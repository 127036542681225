import { t } from '@lingui/macro'
import ChildFurtherInformationForm, {
  NewChildFurtherInformation,
} from '../../../../../components/child/child-form/child-further-information-form'
import FormPage from '../../../../../components/form/form-page'

type Props = {
  handleSubmit: (data: any) => void
  defaultValues?: NewChildFurtherInformation
  handlePrevious: () => void
}

const FurtherInformationPage = ({ handleSubmit, defaultValues, handlePrevious }: Props) => {
  return (
    <FormPage title={t({ message: 'Weitere Informationen' })} progress={5}>
      <ChildFurtherInformationForm
        handleSubmit={handleSubmit}
        handlePrevious={handlePrevious}
        defaultValues={defaultValues}
      />
    </FormPage>
  )
}

export default FurtherInformationPage
