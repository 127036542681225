import { EditOutlined } from '@ant-design/icons'
import { Trans } from '@lingui/macro'
import { Button, Grid } from 'antd'

const EditButton = ({ onClick }: { onClick: () => void }) => {
  const { useBreakpoint } = Grid
  const screens = useBreakpoint()

  return (
    <>
      {screens.sm === true ? (
        <Button onClick={onClick} icon={<EditOutlined />}>
          &nbsp;<Trans>Bearbeiten</Trans>
        </Button>
      ) : (
        <Button onClick={onClick} icon={<EditOutlined />} />
      )}
    </>
  )
}

export default EditButton
