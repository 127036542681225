import { t } from '@lingui/macro'
import { Button } from 'antd'
import { useNavigate } from 'react-router-dom'
import { Contract } from '../../types'
import { BellFilled } from '@ant-design/icons'
import styles from './book-holiday-care-button.module.css'

type Props = {
  contract: Contract
}

const BookHolidayCareButton = ({ contract }: Props) => {
  const navigate = useNavigate()

  return (
    <>
      <Button
        className={styles.button}
        type="primary"
        onClick={() => {
          navigate(`../new-holiday-care-page/${contract.childId}/`)
        }}
        icon={<BellFilled className={styles.shakeBell} />}
      >
        {t({ message: 'Jetzt Ferienbetreuung etc. buchen' })}
      </Button>
    </>
  )
}

export default BookHolidayCareButton
