const getLeafletUrl = async (institutionId: string): Promise<string> => {
  if (institutionId) {
    const response = await fetch(`${process.env.REACT_APP_LEOBA_SERVER}/api/eltern-app/${institutionId}/get-leaflet`, {
      headers: { 'Content-Type': 'application/pdf' },
    })
    const leaflet = await response.blob()

    return URL.createObjectURL(leaflet)
  } else {
    throw Error('Need institutionId as param in order to load leaflet.')
  }
}

export default getLeafletUrl
