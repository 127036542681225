import { useLingui } from '@lingui/react'
import { DatePicker } from 'antd'
import dayjs, { Dayjs } from 'dayjs'

type Props = {
  value?: Date | string
  onChange: (value: string) => void
  onlyAllow?: 'future' | 'past'
  futureStartDate?: dayjs.Dayjs
  onlyAllowSpecificDates?: string[]
  max?: Date
}

export const LocalizedDatePicker = ({
  value,
  onChange,
  onlyAllow,
  futureStartDate,
  onlyAllowSpecificDates,
  max,
  ...props
}: Props) => {
  const { i18n } = useLingui()

  const displayFormat = new Intl.DateTimeFormat(i18n.locale, { year: 'numeric', month: '2-digit', day: '2-digit' })
    .formatToParts(new Date())
    .map((part) => {
      switch (part.type) {
        case 'day':
          return 'D'.repeat(part.value.length)
        case 'month':
          return 'M'.repeat(part.value.length)
        case 'year':
          return 'Y'.repeat(part.value.length)
        case 'literal':
          return part.value
        default:
          return ''
      }
    })
    .join('')

  const dayjsValue = value === undefined ? undefined : dayjs(value)

  return (
    <DatePicker
      allowClear={false}
      format={displayFormat}
      value={dayjsValue && dayjsValue.isValid() ? dayjsValue : undefined}
      style={{ width: '100%' }}
      onChange={(_, dateString) => {
        if (typeof dateString === 'string') {
          const value = dayjs(dateString, displayFormat).format('YYYY-MM-DD')
          onChange(value)
        }
      }}
      disabledDate={(date) => {
        if (max && date.isAfter(dayjs(max))) return true
        const date1 = filterOnlyFutureOrPast(onlyAllow)(date)
        const date2 = filterFutureStartDate(futureStartDate)(date1)
        const date3 = filterSpecificDates(onlyAllowSpecificDates)(date2)
        return date3 === undefined
      }}
      {...props}
    />
  )
}

const filterOnlyFutureOrPast =
  (onlyAllow?: 'future' | 'past') =>
  (date: Dayjs | undefined): Dayjs | undefined => {
    if (date !== undefined && onlyAllow) {
      const startOfToday = dayjs().startOf('date')
      const endOfToday = dayjs().endOf('date')
      if (onlyAllow === 'future' && date.isBefore(startOfToday)) {
        return undefined
      }
      if (onlyAllow === 'past' && date.isAfter(endOfToday)) {
        return undefined
      }
    }
    return date
  }

const filterFutureStartDate =
  (futureStartDate?: dayjs.Dayjs) =>
  (date: Dayjs | undefined): Dayjs | undefined => {
    if (date !== undefined && futureStartDate !== undefined) {
      if (date.isBefore(futureStartDate)) {
        return undefined
      }
    }
    return date
  }

const filterSpecificDates =
  (onlyAllowSpecificDates?: string[]) =>
  (date: Dayjs | undefined): Dayjs | undefined => {
    if (date !== undefined && onlyAllowSpecificDates !== undefined && onlyAllowSpecificDates.length > 0) {
      const day = date.get('date')
      const month = date.get('month') + 1
      const matches = onlyAllowSpecificDates.some((possibleDate) => {
        const split = possibleDate.split('.')
        const possibleDay = parseInt(split[0])
        const possibleMonth = parseInt(split[1])
        return possibleDay === day && possibleMonth === month
      })
      if (matches) {
        return date
      } else {
        return undefined
      }
    }
    return date
  }
