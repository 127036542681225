import { Trans, t } from '@lingui/macro'
import { Form, Radio, Typography } from 'antd'
import { useInstitutionContext } from '../routes/institution/institution-context'
import ButtonSection from './form/button-section'
import { FamilyInformationFormEnnetbaden } from './institution-specific/family-information-form-ennetbaden'
const { Title } = Typography

export type FamilyInformation = {
  applyForSubsidies?: boolean
  countAdultsInHousehold?: number
  countChildrenInHousehold?: number
  taxIncomePartner1?: number
  taxIncomePartner2?: number
}

type Props = {
  initialValues?: FamilyInformation
  submitButtonText?: string
  handleSubmit: (data: FamilyInformation) => Promise<void>
  handlePrevious?: () => void
}

const FamilyInformationForm = ({ initialValues, submitButtonText, handleSubmit, handlePrevious }: Props) => {
  const [form] = Form.useForm<FamilyInformation>()
  const { institution } = useInstitutionContext()

  const initial: Partial<FamilyInformation> = initialValues || { applyForSubsidies: undefined }

  const onSubmitHandler = async (values: FamilyInformation) => {
    const submitValues: FamilyInformation = {
      ...values,
    }
    await handleSubmit(submitValues)
  }

  return (
    <Form<FamilyInformation>
      form={form}
      name="family-information"
      initialValues={initial}
      onFinish={onSubmitHandler}
      colon={false}
      wrapperCol={{ style: { paddingLeft: 8 } }}
      labelCol={{
        span: 12,
        style: {
          paddingRight: 8,
          paddingBottom: 8,
          fontWeight: 'bold',
        },
      }}
      labelWrap={true}
      labelAlign="left"
      requiredMark={false}
    >
      {institution?.features.surbtalPricing && (
        <>
          <Title level={4}>{t({ message: 'Subventionen' })}</Title>
          <Form.Item
            name="applyForSubsidies"
            label={
              t({
                message: 'Ich beantrage Subventionen',
              }) + ' *'
            }
            rules={[{ required: true, message: t({ message: 'Dieses Feld ist zwingend' }) }]}
          >
            <Radio.Group>
              <Radio value={true}>
                <Trans>Ja</Trans>
              </Radio>
              <Radio value={false}>
                <Trans>Nein</Trans>
              </Radio>
            </Radio.Group>
          </Form.Item>
        </>
      )}
      {institution?.features.ennetbadenPricing && <FamilyInformationFormEnnetbaden />}

      <ButtonSection
        submitButtonText={submitButtonText}
        onNext={form.submit}
        onPrevious={handlePrevious && handlePrevious}
      />
    </Form>
  )
}

export default FamilyInformationForm
