import { CheckOutlined } from '@ant-design/icons'
import { t } from '@lingui/macro'
import { Button, Collapse, Form, Input, Modal, Radio, TimePicker, Typography } from 'antd'
import { useForm } from 'antd/es/form/Form'
import dayjs from 'dayjs'
import { CSSProperties, MouseEventHandler, useEffect, useState } from 'react'
import LeobaMarkdown from '../../../../components/LeobaMarkdown'
import DateField from '../../../../components/date-field'
import TextField from '../../../../components/text-field'
import formatChf from '../../../../helper/format-chf'
import { HolidayCare, HolidayCareBooking } from '../../../../types'
import { useInstitutionContext } from '../../institution-context'
import Decimal from 'decimal.js'
const { Text, Title } = Typography

type Props = {
  holidayCareItem: HolidayCare
  selectHolidayCare: (hci: HolidayCare) => void
  isSelected: boolean
  isBooked: boolean
  childId?: string
}
const HolidayCareCard = ({ holidayCareItem, selectHolidayCare, isSelected, isBooked, childId }: Props) => {
  const { institution } = useInstitutionContext()
  const [form] = useForm()
  const [subsidizedPrice, setSubsidizedPrice] = useState<Decimal | undefined>(undefined)

  const [showAskForLocationBeforeAndAfterModuleForm, setShowAskForLocationBeforeAndAfterModuleForm] = useState(false)

  const { Panel } = Collapse

  const clickSelect: MouseEventHandler = (e) => {
    e.stopPropagation()
    if (
      (askForLocationBeforeAndAfterModuleIsMandatory || askForLocationBeforeAndAfterModuleIsOptional) &&
      !isSelected
    ) {
      setShowAskForLocationBeforeAndAfterModuleForm(true)
    } else {
      selectHolidayCare(holidayCareItem)
    }
  }

  useEffect(() => {
    if (institution?.features.subsidiesHuenenberg && childId) {
      const today = dayjs(new Date()).format('YYYY-MM-DD')
      const deadline = holidayCareItem.registrationDeadline
      if (deadline >= today) {
        fetch(
          `${process.env.REACT_APP_LEOBA_SERVER}/api/eltern-app/${institution?.id}/guardian/holiday-care-price-huenenberg?childId=${childId}`,
          {
            method: 'GET',
          }
        ).then(async (response) => {
          if (response.status === 200) {
            const price = await response.json()
            setSubsidizedPrice(new Decimal(price.holidayCarePricePerDay))
          }
        })
      }
    }
  }, [childId, holidayCareItem.registrationDeadline, institution?.features.subsidiesHuenenberg, institution?.id])

  const formSubmit = (
    values: Pick<HolidayCareBooking, 'arrival' | 'arrivalTime' | 'departure' | 'departureTime' | 'destination'>
  ) => {
    selectHolidayCare({ ...holidayCareItem, ...values })
    setShowAskForLocationBeforeAndAfterModuleForm(false)
  }

  const collapseStyle: CSSProperties = isSelected
    ? {
        border: '0.1em solid var(--main-accent-color)',
        alignItems: 'center',
        marginBlock: '1em',
        backgroundColor: 'var(--main-bg-color)',
        color: 'var(--main-fg-color)',
      }
    : {
        alignItems: 'center',
        marginBlock: '1em',
        backgroundColor: 'var(--main-bg-color)',
        color: 'var(--main-fg-color)',
      }

  const askForLocationBeforeAndAfterModule = institution?.registrationConfig.askForLocationBeforeAndAfterModule
  const askForLocationBeforeAndAfterModuleIsMandatory = askForLocationBeforeAndAfterModule === 'mandatory'
  const askForLocationBeforeAndAfterModuleIsOptional = askForLocationBeforeAndAfterModule === 'optional'
  const showPricePerVisist =
    (subsidizedPrice !== undefined && subsidizedPrice.toNumber() > 0) || holidayCareItem.cost.toNumber() > 0

  return (
    <div style={{}}>
      <Collapse style={collapseStyle} {...(isBooked && { ghost: true })}>
        <Panel
          key="1"
          header={
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '0.5em', paddingRight: '0.5em' }}>
              <Text style={{ fontWeight: 'bold' }}>{holidayCareItem.title} </Text>
              <Text>
                {holidayCareItem.startTime} bis {holidayCareItem.endTime}
              </Text>
            </div>
          }
          extra={
            <div style={{ textAlign: 'right' }}>
              {isBooked && (
                <Text>
                  <i>{t({ message: `bereits gebucht` })} </i>
                </Text>
              )}
              <Button type="default" shape="circle" disabled={isBooked} onClick={clickSelect}>
                {(isSelected || isBooked) && (
                  <CheckOutlined
                    style={{
                      fontSize: '1em',
                      paddingLeft: '0.2em',
                      color: 'var(--main-accent-color)',
                    }}
                  />
                )}
              </Button>
            </div>
          }
        >
          <LeobaMarkdown markdown={holidayCareItem.description} />

          {holidayCareItem.locationId !== undefined && institution !== undefined && (
            <TextField
              label={t({ message: 'Standort' })}
              value={institution.locations.find((l) => l.id === holidayCareItem.locationId)?.name}
            />
          )}

          {showPricePerVisist && (
            <TextField
              label={t({ message: 'Preis pro Besuch' })}
              value={formatChf((subsidizedPrice !== undefined ? subsidizedPrice : holidayCareItem.cost).toNumber())}
            />
          )}
          <TextField label={t({ message: 'Teilnehmende min.' })} value={holidayCareItem.minOccupancy.toString()} />
          <TextField label={t({ message: 'Teilnehmende max.' })} value={holidayCareItem.maxOccupancy.toString()} />
          <TextField
            label={t({ message: 'Anzahl bisherige Buchungen' })}
            value={holidayCareItem.bookingCount?.toString()}
          />
          <DateField
            label={t({ message: 'Anmeldeschluss' })}
            value={dayjs(holidayCareItem.registrationDeadline).toDate()}
          />

          <Modal
            open={showAskForLocationBeforeAndAfterModuleForm}
            onCancel={() => setShowAskForLocationBeforeAndAfterModuleForm(false)}
            okText={t({ message: `Bestätigen` })}
            okButtonProps={{ onClick: form.submit }}
          >
            <Form form={form} onFinish={formSubmit} style={{ marginBottom: '2em' }}>
              <Title level={5}>{t({ message: `Anreise` })}</Title>
              <Form.Item
                name={'arrival'}
                rules={[
                  {
                    required: askForLocationBeforeAndAfterModuleIsMandatory,
                    message: t({ message: 'Dieses Feld ist zwingend' }),
                  },
                ]}
                style={{ marginBlock: '0.5em' }}
              >
                <Radio.Group>
                  <Radio value="brought">{t({ message: `Wird gebracht` })}</Radio>
                  <Radio value="independent">{t({ message: `Kommt alleine` })}</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name={'arrivalTime'}
                rules={[
                  {
                    required: askForLocationBeforeAndAfterModuleIsMandatory,
                    message: t({ message: 'Dieses Feld ist zwingend' }),
                  },
                ]}
                style={{ marginBottom: '1em' }}
                labelCol={{ style: { padding: 0 } }}
                label={t({ message: `Um welche Zeit?` })}
              >
                <TimePicker format="HH:mm" allowClear={false} suffixIcon={null} />
              </Form.Item>

              <Title level={5}>{t({ message: `Abreise` })}</Title>
              <Form.Item
                name={'departure'}
                rules={[
                  {
                    required: askForLocationBeforeAndAfterModuleIsMandatory,
                    message: t({ message: 'Dieses Feld ist zwingend' }),
                  },
                ]}
                style={{ marginBlock: '0.5em' }}
              >
                <Radio.Group>
                  <Radio value="fetched">{t({ message: `Wird abgeholt` })}</Radio>
                  <Radio value="independent">{t({ message: `Geht alleine` })}</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name={'destination'}
                rules={[
                  {
                    required: askForLocationBeforeAndAfterModuleIsMandatory,
                    message: t({ message: 'Dieses Feld ist zwingend' }),
                  },
                ]}
                style={{ marginBottom: '1em' }}
                labelCol={{ style: { padding: 0 } }}
                label={t({ message: `Wohin?` })}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={'departureTime'}
                rules={[
                  {
                    required: askForLocationBeforeAndAfterModuleIsMandatory,
                    message: t({ message: 'Dieses Feld ist zwingend' }),
                  },
                ]}
                style={{ marginBottom: '1em' }}
                labelCol={{ style: { padding: 0 } }}
                label={t({ message: `Um welche Zeit?` })}
              >
                <TimePicker format="HH:mm" allowClear={false} suffixIcon={null} />
              </Form.Item>
            </Form>
          </Modal>
        </Panel>
      </Collapse>
    </div>
  )
}

export default HolidayCareCard
