import { I18nProvider } from '@lingui/react'
import { ConfigProvider } from 'antd'
import localeDE from 'antd/locale/de_DE'
import localeEN from 'antd/locale/en_GB'
import localeFR from 'antd/locale/fr_FR'
import localeIT from 'antd/locale/it_IT'
import React from 'react'
import 'react-app-polyfill/stable'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom'
import SuperTokens from 'supertokens-web-js'
import { frontendConfig } from './auth/frontend-config'
import './index.css'
import { configureDayJs } from './locales/dayjs'
import configureLingui from './locales/lingui'
import { configureErrorLogging } from './reportErrors'
import reportWebVitals from './reportWebVitals'
import Router from './routes/router'

configureErrorLogging()
const lingui = configureLingui()

SuperTokens.init(frontendConfig(lingui.locale))

const router = createBrowserRouter(createRoutesFromElements(Router()))

const getAntLocale = () => {
  switch (lingui.locale) {
    case 'en':
      return localeEN
    case 'fr':
      return localeFR
    case 'it':
      return localeIT
    default:
      return localeDE
  }
}
configureDayJs()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <I18nProvider i18n={lingui}>
      <ConfigProvider
        locale={getAntLocale()}
        theme={{
          token: {
            colorPrimary: '#61bb96',
            colorText: '#123c6b',
          },
          components: {
            Select: {
              colorTextDisabled: 'var(--main-fg-color)',
            },
            DatePicker: {
              colorTextDisabled: 'var(--main-fg-color)',
            },
          },
        }}
      >
        <RouterProvider router={router} />
      </ConfigProvider>
    </I18nProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
