import { Trans } from '@lingui/macro'
import { Button, Typography } from 'antd'
import { useEffect } from 'react'
import { isRouteErrorResponse, useLocation, useNavigate, useRouteError } from 'react-router-dom'

const { Title, Paragraph } = Typography

export const GuardianErrorElement = () => {
  const location = useLocation()
  const error = useRouteError()
  const navigate = useNavigate()

  useEffect(() => {
    let errorMessage = 'Unknown error'
    let errorStack = ''

    if (isRouteErrorResponse(error)) {
      // error is type `ErrorResponse`
      errorMessage = error.statusText
    } else if (error instanceof Error) {
      errorMessage = error.message
      errorStack = error.stack || ''
    } else if (typeof error === 'string') {
      errorMessage = error
    }

    fetch(`${process.env.REACT_APP_LEOBA_SERVER}/api/eltern-app/log?path=${location.pathname}`, {
      method: 'PUT',
      body: JSON.stringify({ message: errorMessage, stack: errorStack }),
    })
  }, [error, location.pathname])

  const handleGoToRoot = () => {
    navigate('/')
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '2em', gap: '2em' }}>
      <Title>
        <Trans>Es tut uns leid...</Trans>
      </Title>
      <img
        style={{ width: '80%', maxWidth: '20em', height: 'auto' }}
        src="/fixing-bug.png"
        alt="Indicating that ther was a bug in the software"
      />
      <Paragraph>
        <Trans>
          Leider ist ein Fehler aufgetreten. Bitte entschuldigen Sie die Umstände. Wir haben die Fehlermeldung in einer
          anonymen Form an unseren Server geschickt und kümmern uns darum.
        </Trans>
      </Paragraph>
      <Button onClick={handleGoToRoot}>
        <Trans>Zurück zur Hauptseite</Trans>
      </Button>
    </div>
  )
}
