import { t } from '@lingui/macro'
import { Form, InputNumber, Radio, Typography } from 'antd'

const { Title, Paragraph } = Typography

type Props = {}
export const FamilyInformationFormEnnetbaden = (props: Props) => {
  const giveInformation = Form.useWatch<boolean | undefined>('applyForSubsidies')

  return (
    <>
      <Title level={4}>{t({ message: 'Subventionen' })}</Title>

      <Paragraph>
        <Form.Item
          name="applyForSubsidies"
          label={
            t({
              message: 'Subventionen',
            }) + ' *'
          }
          rules={[{ required: true, message: t({ message: 'Dieses Feld ist zwingend' }) }]}
        >
          <ApplyForSubsidiesRadio />
        </Form.Item>
      </Paragraph>
      {giveInformation === true && (
        <>
          <Form.Item
            rules={[{ required: true }]}
            name="countAdultsInHousehold"
            label={t({ message: 'Anzahl Erwachsene im Haushalt' })}
          >
            <InputNumber min={0} />
          </Form.Item>
          <Form.Item
            rules={[{ required: true }]}
            name="countChildrenInHousehold"
            label={t({
              message: 'Anzahl Kinder im Haushalt (Für die das Sorgerecht und eine Unterstützungspflicht bestehen.)',
            })}
          >
            <InputNumber min={0} />
          </Form.Item>
          <Form.Item
            rules={[{ required: true }]}
            name="taxIncomePartner1"
            label={t({
              message: 'Steuerbares Einkommen Partner 1',
            })}
          >
            <InputNumber min={0} addonAfter="CHF" />
          </Form.Item>
          <Form.Item
            name="taxIncomePartner2"
            label={t({
              message: 'Steuerbares Einkommen Partner 2',
            })}
          >
            <InputNumber min={0} addonAfter="CHF" />
          </Form.Item>
        </>
      )}
    </>
  )
}

const ApplyForSubsidiesRadio = ({
  value,
  onChange,
}: {
  value?: boolean | undefined
  onChange?: (value: boolean | undefined) => void
}) => {
  return (
    <Radio.Group
      onChange={(e) => {
        onChange?.(e.target.value === 'yes' ? true : e.target.value === 'no' ? false : undefined)
      }}
      value={value === true ? 'yes' : value === false ? 'no' : undefined}
      style={{ display: 'flex', flexDirection: 'column' }}
    >
      <Radio value={'yes'} style={{ marginBottom: '1em' }}>
        {t({
          message:
            'Ich / wir möchte Angaben zu meiner / unserer finanziellen Situation machen um Subventionen zu beantragen. Ich bin / wir sind einverstanden, dass die Institution Steuerdaten von der Gemeinde erfragt.',
        })}
      </Radio>
      <Radio value={'no'}>
        {t({
          message:
            'Ich / wir bezahlen den Maximaltarif und geben kein Einverständnis für die Einsicht in die Steuerdaten.',
        })}
      </Radio>
    </Radio.Group>
  )
}
