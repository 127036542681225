import { Navigate, Outlet, Route, Routes, useNavigate } from 'react-router-dom'
import { NewChildBaseInformation } from '../../../../../components/child/child-form/child-base-information-form'
import { NewChildFurtherInformation } from '../../../../../components/child/child-form/child-further-information-form'
import { NewChildImportantInformation } from '../../../../../components/child/child-form/child-important-information-form'
import { TaxInformation } from '../../../../../types'
import { useInstitutionContext } from '../../../institution-context'
import { ModuleBooking, useRegistrationContext } from '../../registration-context'
import BaseInformationPage from './base-information-page'
import FurtherInformationPage from './further-information-page'
import ImportantInformationPage from './important-information-page'
import ModuleFormPage from './module-form-page'
import { useNewChildContext } from './new-child-context'

type Props = {
  taxInformation: TaxInformation | undefined
  handleSubmit: (
    newChildBaseInformation: NewChildBaseInformation,
    newChildImportantInformation: NewChildImportantInformation,
    newChildFurtherInformation: NewChildFurtherInformation,
    moduleBooking: ModuleBooking
  ) => Promise<void>
  handlePrevious: () => void
}

const NewChildFormPage = ({ handleSubmit, handlePrevious, taxInformation }: Props) => {
  const navigate = useNavigate()

  const {
    getBaseInformationData,
    getImportantInformationData,
    getFurtherInformationData,
    getModulesData,
    setBaseInformationData,
    setImportantInformationData,
    setFurtherInformationData,
    setModulesData,
    getChild,
    resetForNextChild,
  } = useNewChildContext()
  const { institution } = useInstitutionContext()
  const isOutOfTown = useRegistrationContext((state) => !!state.isOutOfTown)

  function baseInformationSubmitHandler(data: any): void {
    setBaseInformationData(data)
    navigate('important-information')
  }

  function baseInformationPreviousHandler(): void {
    handlePrevious()
  }

  function importantInformationSubmitHandler(data: NewChildImportantInformation): void {
    setImportantInformationData(data)
    navigate('further-information')
  }

  function importantInformationPreviousHandler(): void {
    navigate('base-information')
  }

  async function furtherInformationSubmitHandler(data: NewChildFurtherInformation): Promise<void> {
    setFurtherInformationData(data)
    if (isOutOfTown) {
      const firstLocation = institution?.locations[0]
      const firstAgeGroup = firstLocation?.ageGroups[0]
      setModulesData({ flexibleBooking: false, locationId: firstLocation?.id, ageGroup: firstAgeGroup, bookings: [] })
      await addChild()
    } else {
      navigate('modules')
    }
  }

  function furtherInformationPreviousHandler(): void {
    navigate('important-information')
  }

  async function moduleSubmitHandler(data: ModuleBooking): Promise<void> {
    setModulesData(data)
    await addChild()
  }
  function modulePreviousHandler(data: ModuleBooking): void {
    setModulesData(data)
    navigate('further-information')
  }

  async function addChild(): Promise<void> {
    const newChild = getChild()
    if (newChild.moduleBooking) {
      handleSubmit(
        newChild.baseInformation,
        newChild.importantInformation,
        newChild.furtherInformation,
        newChild.moduleBooking
      )
      resetForNextChild()
      navigate('../billing-address')
    } else {
      throw new Error('ModuleBooking is missing')
    }
  }

  return (
    <>
      <Outlet />
      <Routes>
        <Route
          path="base-information"
          element={
            <BaseInformationPage
              handleSubmit={baseInformationSubmitHandler}
              handlePrevious={baseInformationPreviousHandler}
              defaultValues={getBaseInformationData()}
              isOutOfTown={isOutOfTown}
            />
          }
        />
        <Route
          path="important-information"
          element={
            getBaseInformationData() ? (
              <ImportantInformationPage
                handleSubmit={importantInformationSubmitHandler}
                handlePrevious={importantInformationPreviousHandler}
                defaultValues={getImportantInformationData()}
              />
            ) : (
              <Navigate to="../base-information" replace />
            )
          }
        />
        <Route
          path="further-information"
          element={
            getImportantInformationData() ? (
              <FurtherInformationPage
                handleSubmit={furtherInformationSubmitHandler}
                handlePrevious={furtherInformationPreviousHandler}
                defaultValues={getFurtherInformationData()}
              />
            ) : (
              <Navigate to="../important-information" replace />
            )
          }
        />
        <Route
          path="modules"
          element={
            getFurtherInformationData() ? (
              <ModuleFormPage
                schoolId={getBaseInformationData()?.schoolId}
                handleSubmit={moduleSubmitHandler}
                handlePrevious={modulePreviousHandler}
                defaultValues={getModulesData()}
                taxInformation={taxInformation}
              />
            ) : (
              <Navigate to="../further-information" replace />
            )
          }
        />

        <Route path="*" element={<>404</>} />
      </Routes>
    </>
  )
}

export default NewChildFormPage
