import { LoaderFunctionArgs } from 'react-router-dom'
import { Guardian, User } from '../../../types'
import { useGuardianContext } from './guardian-context'

const loadGuardian = async (args: LoaderFunctionArgs): Promise<Guardian | null> => {
  const {
    params: { guardianId },
  } = args

  if (guardianId) {
    if (guardianId !== useGuardianContext.getState().guardian?.id) {
      await loadNewGuardian(guardianId)
    }
    const guardians = useGuardianContext.getState().guardians
    if (guardians) {
      return guardians.find((guard) => guard.id === guardianId) || null
    } else {
      throw Error('Could not load guardian')
    }
  } else {
    throw Error('Need guardianId as param in order to load institution.')
  }
}

const loadNewGuardian = async (guardianId: string) => {
  const user = await getUser()
  if (user === 'not allowed') {
    return
  } else {
    useGuardianContext.getState().setUser(user.user)
    useGuardianContext.getState().guardians = user.guardians
    const guardian = user.guardians.find((guard) => guard.id === guardianId) || null
    if (guardian) {
      useGuardianContext.getState().setGuardian(guardian)
      useGuardianContext.getState().fetchFamily()
    }
  }
}

export default loadGuardian

export const getUser = async () => {
  const response = await fetch(`${process.env.REACT_APP_LEOBA_SERVER}/api/eltern-app/user`, {
    headers: { accept: 'application/json' },
  })

  if (response.ok) {
    const json = await response.json()
    const user = json.user as User
    const guardians = json.guardian as (Guardian & { institutionId: string })[]

    return { user, guardians }
  } else {
    return 'not allowed'
  }
}
