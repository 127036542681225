import { t } from '@lingui/macro'
import { KindergartenLevel } from '../types'

const translateKindergartenLevel = (KindergartenLevel?: KindergartenLevel): string => {
  let result
  switch (KindergartenLevel) {
    case 'first':
      result = t({ message: '1. Kindergartenjahr' })
      break
    case 'second':
      result = t({ message: '2. Kindergartenjahr' })
      break
    default:
      result = ''
      break
  }
  return result
}

export default translateKindergartenLevel
