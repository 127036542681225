import { t, Trans } from '@lingui/macro'
import FormPage from '../../../../components/form/form-page'

const ErrorFormPage = () => {
  return (
    <FormPage title={t({ message: 'Anmeldung nicht erfolgreich' })}>
      <div>
        <Trans>
          Leider ist beim Versenden der Anmeldung ein Fehler aufgetreten. Bitte kontaktieren Sie uns, damit wir Ihnen
          weiterhelfen können.
        </Trans>
      </div>
    </FormPage>
  )
}

export default ErrorFormPage
