import { t } from '@lingui/macro'
import { HolidayCare, HolidayCareBooking } from '../types'

export const translateExecutionDecision = (holidayCare: HolidayCare, holidayCareBooking: HolidayCareBooking) => {
  switch (holidayCare.executionDecision) {
    case 'open':
      return t({ message: `Durchführung noch offen` })
    case 'takesPlace':
      return holidayCareBooking.approvalState === 'rejected'
        ? t({ message: `Ausgebucht` })
        : t({ message: `Findet statt` })
    case 'cancelled':
      return t({ message: `Findet nicht statt` })
  }
}
