import { t } from '@lingui/macro'
import { useEffect, useState } from 'react'
import { identity } from 'remeda'
import FormPage from '../../../components/form/form-page'
import BookingMutationCard from '../../../components/guardian-page/booking-mutation-card'
import ExpandableInfoList from '../../../components/guardian-page/expandable-info-list'
import { ChildSwitcher } from '../../../components/guardian/child-switcher'
import holidayCareBookingToBookingMutation from '../../../helper/holiday-care-booking-to-booking-mutation'
import moduleMutationToBookingMutation from '../../../helper/module-mutation-to-booking-mutation'
import { Child, HolidayCare, HolidayCareBooking, HolidayCareGroup, Institution, ModuleMutation } from '../../../types'
import { useInstitutionContext } from '../institution-context'
import { useGuardianContext } from './guardian-context'

const GuardianBookingsPage = () => {
  const { currentChild } = useGuardianContext()
  const { institution } = useInstitutionContext()

  const [moduleMutations, setModuleMutations] = useState<ModuleMutation[] | undefined>(undefined)
  const [holidayCareBookings, setHolidayCareBookings] = useState<
    (HolidayCareBooking & { holidayCare: HolidayCare & { groups: HolidayCareGroup[] } })[] | undefined
  >(undefined)

  useEffect(() => {
    if (currentChild && institution) {
      getAdditionalBookings(currentChild, institution).then(setModuleMutations)
      getHolidayCareBookings(currentChild, institution).then(setHolidayCareBookings)
    }
  }, [institution, currentChild])

  const handleOnBookingDelete = async () => {
    if (currentChild && institution) {
      const additionalBookings = await getAdditionalBookings(currentChild, institution)
      setModuleMutations(additionalBookings)
    }
  }

  return (
    <FormPage title={''}>
      <>
        <ChildSwitcher />
        {moduleMutations === undefined && holidayCareBookings === undefined && (
          <div>{t({ message: 'Zusatzbuchungen werden geladen...' })}</div>
        )}
        {institution && (moduleMutations || holidayCareBookings) && (
          <ExpandableInfoList
            data={[
              ...(moduleMutations?.map((moduleMutation) =>
                moduleMutationToBookingMutation(moduleMutation, institution, currentChild)
              ) ?? []),
              ...(holidayCareBookings?.map((holidayCareBooking) =>
                holidayCareBookingToBookingMutation(holidayCareBooking, institution)
              ) ?? []),
            ].sort((a, b) => +new Date(b.date) - +new Date(a.date))}
            dateTimeKey={'effectiveAt'}
            mapFunction={(mutation) => (
              <BookingMutationCard
                institution={institution}
                key={mutation.id}
                bookingMutation={mutation}
                onDelete={handleOnBookingDelete}
              />
            )}
            noDataText={t({ message: 'Es gibt noch keine Zusatzbuchungen.' })}
          />
        )}
      </>
    </FormPage>
  )
}

export default GuardianBookingsPage

const getAdditionalBookings = async (child: Child, institution: Institution) => {
  const additionalBookings = await Promise.all(
    child.contracts.map(async (contract) => {
      const response = await fetch(
        `${process.env.REACT_APP_LEOBA_SERVER}/api/eltern-app/${institution.id}/guardian/module-mutation?contractId=${contract.id}`,
        {
          headers: { accept: 'application/json' },
        }
      )
      if (response.ok) {
        const result = (await response.json()) as ModuleMutation[]
        const additionalBookings = result.filter((mut) => mut.mutationType === 'additionalBooking')
        return additionalBookings
      } else {
        return []
      }
    })
  )
  return additionalBookings.flatMap(identity)
}

const getHolidayCareBookings = async (child: Child, institution: Institution) => {
  const response = await fetch(
    `${process.env.REACT_APP_LEOBA_SERVER}/api/eltern-app/${institution.id}/guardian/holiday-care-booking?childId=${child.id}`,
    {
      headers: { accept: 'application/json' },
    }
  )
  if (response.ok) {
    const result = (await response.json()) as (HolidayCareBooking & {
      holidayCare: HolidayCare & { groups: HolidayCareGroup[] }
    })[]
    return result
  }
  return []
}
