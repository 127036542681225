import { t } from '@lingui/macro'
import ChildBaseInformationForm, {
  NewChildBaseInformation,
} from '../../../../../components/child/child-form/child-base-information-form'
import FormPage from '../../../../../components/form/form-page'

type Props = {
  handleSubmit: (data: any) => void
  handlePrevious: () => void
  isOutOfTown: boolean
  defaultValues?: NewChildBaseInformation
}

const BaseInformationPage = ({ handleSubmit, handlePrevious, isOutOfTown, defaultValues }: Props) => {
  return (
    <FormPage title={t({ message: 'Kind erfassen' })} progress={3}>
      <ChildBaseInformationForm
        handleSubmit={handleSubmit}
        handlePrevious={handlePrevious}
        isOutOfTown={isOutOfTown}
        defaultValues={defaultValues}
      />
    </FormPage>
  )
}

export default BaseInformationPage
