import { Card } from 'antd'
import { toDateTimeString } from '../../helper/format-date'
import { translateLogbookType } from '../../helper/translate-logbook-type'
import { ChildLogbook } from '../../types'

const LogbookCard = ({ childLogbook }: { childLogbook: ChildLogbook }) => {
  return (
    <Card
      size="small"
      style={{ width: '100%', marginBottom: '0.5em' }}
      key={childLogbook.id}
      title={
        <div
          style={{
            flexWrap: 'wrap',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <div>{translateLogbookType(childLogbook.logbookType)}</div>
          <div>{toDateTimeString(new Date(childLogbook.dateTime))}</div>
        </div>
      }
    >
      {childLogbook.description}
    </Card>
  )
}

export default LogbookCard
