import { t } from '@lingui/macro'
import { Button, Form, Input, Typography } from 'antd'
import { useEffect, useState } from 'react'
import { sendPasswordResetEmail, submitNewPassword } from 'supertokens-web-js/recipe/emailpassword'
import FormPage from '../../components/form/form-page'
import AuthLayoutPage from './auth-layout-page'
import { useNavigate, useSearchParams } from 'react-router-dom'

const { Paragraph } = Typography

type GetEmailFormType = { email: string }
type EnterPasswordFormType = { password: string }
type FieldError = { validateStatus: '' | 'success' | 'error'; message: string }

const ResetPassword = () => {
  const [formState, setFormState] = useState<
    'GET_EMAIL' | 'MAIL_SENT' | 'ENTER_PASSWORD' | 'RESET_PASSWORD_INVALID_TOKEN_ERROR' | 'DONE'
  >('GET_EMAIL')
  const [fieldError, setFieldError] = useState<{ password: FieldError }>({
    password: { validateStatus: '', message: '' },
  })
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [sendingResetEmail, setSendingResetEmail] = useState(false)
  const [email, setEmail] = useState('')

  useEffect(() => {
    const token = searchParams.get('token')
    if (token) {
      setFormState('ENTER_PASSWORD')
    }
  }, [searchParams, navigate])

  const handleGetEmail = async (values: GetEmailFormType) => {
    try {
      setSendingResetEmail(true)
      setEmail(values.email)
      await sendPasswordResetEmail({ formFields: [{ id: 'email', value: values.email }] })
      setFormState('MAIL_SENT')
    } finally {
      setSendingResetEmail(false)
    }
  }

  const handleEnterPassword = async (values: EnterPasswordFormType) => {
    const response = await submitNewPassword({ formFields: [{ id: 'password', value: values.password }] })
    switch (response.status) {
      case 'OK':
        setFormState('DONE')
        break
      case 'FIELD_ERROR':
        const passwordMessage = response.formFields.find((f) => f.id === 'password')?.error || ''
        setFieldError({
          password: { message: passwordMessage, validateStatus: passwordMessage ? 'error' : 'success' },
        })
        break
      case 'RESET_PASSWORD_INVALID_TOKEN_ERROR':
        setFormState('RESET_PASSWORD_INVALID_TOKEN_ERROR')
        break
    }
  }

  return (
    <AuthLayoutPage>
      <FormPage title={t({ message: `Passwort zurücksetzen` })}>
        <>
          {formState === 'GET_EMAIL' && (
            <>
              <Paragraph>
                {t({ message: `Wir werden Ihnen ein E-Mail zum Zurücksetzen Ihres Passwortes senden.` })}
              </Paragraph>

              <Form<GetEmailFormType> name="getEmail" layout="vertical" onFinish={handleGetEmail}>
                <Form.Item label={t({ message: `E-Mail` })} name="email">
                  <Input type="email" placeholder={t({ message: `E-Mail-Adresse` })} />
                </Form.Item>
                <Button type="primary" loading={sendingResetEmail} htmlType="submit">
                  {t({ message: `Mir senden` })}
                </Button>
              </Form>
            </>
          )}
          {formState === 'MAIL_SENT' && (
            <Paragraph>
              {t({ message: `Ein E-Mail zum Zurücksenden des Passwortes wurde gesendet an ` })} {email}
              {t({ message: `, falls es in unserem System existiert. ` })}
            </Paragraph>
          )}
          {formState === 'ENTER_PASSWORD' && (
            <>
              <Paragraph>{t({ message: `Geben Sie ein neues Passwort unten ein um es zu ändern.` })}</Paragraph>
              <Form<EnterPasswordFormType> name="enterPassword" layout="vertical" onFinish={handleEnterPassword}>
                <Form.Item
                  label={t({ message: `Passwort` })}
                  name="password"
                  validateStatus={fieldError.password.validateStatus}
                  help={fieldError.password.message}
                >
                  <Input.Password />
                </Form.Item>
                <Button type="primary" loading={sendingResetEmail} htmlType="submit">
                  {t({ message: `Passwort ändern` })}
                </Button>
              </Form>
            </>
          )}
          {formState === 'RESET_PASSWORD_INVALID_TOKEN_ERROR' && (
            <Paragraph>{t({ message: `Ungültiges Passwort-Reset-Token` })}</Paragraph>
          )}
          {formState === 'DONE' && (
            <>
              <Paragraph>{t({ message: `Ihr Passwort wurde erfolgreich geändert.` })}</Paragraph>
              <Button onClick={() => navigate('/auth')} type="primary">
                {t({ message: `Anmelden` })}
              </Button>
            </>
          )}
        </>
      </FormPage>
    </AuthLayoutPage>
  )
}

export default ResetPassword
