import { Capacitor } from '@capacitor/core'
import { Trans } from '@lingui/macro'
import { Layout, Typography, theme } from 'antd'
import { SafeArea } from 'capacitor-plugin-safe-area'
import { useEffect, useState } from 'react'
import { isRouteErrorResponse, useLocation, useRouteError } from 'react-router-dom'

const { Header, Content, Footer } = Layout
const { Title, Text, Paragraph } = Typography

export const GeneralErrorElement = () => {
  const location = useLocation()
  const error = useRouteError()
  const {
    token: { colorPrimary },
  } = theme.useToken()
  const [mobileInsets, setMobileInsets] = useState<
    | {
        top: number
        right: number
        bottom: number
        left: number
      }
    | undefined
  >()
  useEffect(() => {
    if (Capacitor.getPlatform() === 'ios') {
      SafeArea.getSafeAreaInsets().then(({ insets }) => {
        setMobileInsets(insets)
      })
    }
  }, [])

  useEffect(() => {
    let errorMessage = 'Unknown error'
    let errorStack = ''

    if (isRouteErrorResponse(error)) {
      // error is type `ErrorResponse`
      errorMessage = error.statusText
    } else if (error instanceof Error) {
      errorMessage = error.message
      errorStack = error.stack || ''
    } else if (typeof error === 'string') {
      errorMessage = error
    }

    fetch(`${process.env.REACT_APP_LEOBA_SERVER}/api/eltern-app/log?path=${location.pathname}`, {
      method: 'PUT',
      body: JSON.stringify({ message: errorMessage, stack: errorStack }),
    })
  }, [error, location.pathname])

  return (
    <Layout className="layout" style={{ minHeight: '100vh' }}>
      <Header
        style={{
          background: colorPrimary,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingInline: '0 1em',
        }}
      >
        <div style={{ paddingInlineStart: '1.2rem', lineHeight: '1.2em', fontWeight: 'bold', fontSize: '1.3em' }}>
          Leoba
        </div>
      </Header>
      <Content>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '2em', gap: '2em' }}>
          <Title>
            <Trans>Es tut uns leid...</Trans>
          </Title>
          <img
            style={{ width: '80%', maxWidth: '20em', height: 'auto' }}
            src="/fixing-bug.png"
            alt="Indicating that ther was a bug in the software"
          />
          <Paragraph>
            <Trans>
              Leider ist ein Fehler aufgetreten. Bitte entschuldigen Sie die Umstände. Wir haben die Fehlermeldung in
              einer anonymen Form an unseren Server geschickt und kümmern uns darum.
            </Trans>
          </Paragraph>
        </div>
      </Content>
      <Footer style={{ textAlign: 'center' }}>
        <Text>©2023 Leoba GmbH</Text>
      </Footer>
      <div style={{ height: mobileInsets ? `${mobileInsets.bottom}px` : '0px' }} />
    </Layout>
  )
}
