import { t } from '@lingui/macro'
import { MaritalStatus } from '../types'

const translatedMaritialStatus = (maritialStatus?: MaritalStatus): string | undefined => {
  let result
  switch (maritialStatus) {
    case 'married':
      result = t({ message: 'Verheiratet' })
      break
    case 'divorced':
      result = t({ message: 'Geschieden' })
      break
    case 'widowed':
      result = t({ message: 'Verwitwet' })
      break
    case 'single':
      result = t({ message: 'Ledig' })
      break
    case 'concubinage':
      result = t({ message: 'Konkubinat' })
      break
    case 'other':
      result = t({ message: 'Sonstiges' })
      break
  }
  return result
}

export default translatedMaritialStatus
