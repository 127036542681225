import { Typography } from 'antd'
import { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useInstitutionContext } from './institution/institution-context'

const { Title, Text } = Typography
export const NoInstitutionPresentPage = () => {
  const { institution } = useInstitutionContext()
  const navigate = useNavigate()

  useEffect(() => {
    navigate('/institution-index')
  })

  return (
    <div>
      <Title>Hallo!</Title>
      <div>
        <Text>
          Hier sollte mal auf die Login-Maske weitergeleitet werden. Aktuell haben wir die aber noch nicht. Darum
          verlinken wir hier auf die <Link to={`/${institution?.id}/registration`}>Anmeldeseite einer Institution</Link>
          .
        </Text>
      </div>
    </div>
  )
}
