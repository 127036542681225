import { t } from '@lingui/macro'

const weekdays = [
  { title: t({ message: 'Montag' }), name: 'monday', value: 0 },
  { title: t({ message: 'Dienstag' }), name: 'tuesday', value: 1 },
  { title: t({ message: 'Mittwoch' }), name: 'wednesday', value: 2 },
  { title: t({ message: 'Donnerstag' }), name: 'thursday', value: 3 },
  { title: t({ message: 'Freitag' }), name: 'friday', value: 4 },
  { title: t({ message: 'Samstag' }), name: 'saturday', value: 5 },
  { title: t({ message: 'Sonntag' }), name: 'sunday', value: 6 },
]

export default weekdays
