import FormPage from '../../../../../components/form/form-page'
import { t } from '@lingui/macro'
import ChildImportantInformationForm, {
  NewChildImportantInformation,
} from '../../../../../components/child/child-form/child-important-information-form'

type Props = {
  handleSubmit: (data: any) => void
  handlePrevious: () => void
  defaultValues?: NewChildImportantInformation
}

const ImportantInformationPage = ({ handleSubmit, defaultValues, handlePrevious }: Props) => {
  return (
    <FormPage title={t({ message: 'Wichtige Informationen' })} progress={4}>
      <ChildImportantInformationForm
        handleSubmit={handleSubmit}
        handlePrevious={handlePrevious}
        defaultValues={defaultValues}
      />
    </FormPage>
  )
}

export default ImportantInformationPage
