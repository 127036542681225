import { Outlet, Route, Routes } from 'react-router-dom'
import LoginPage from './login-page'
import SignUpPage from './sign-up-page'
import VerifyEmail from './verify-email'
import ResetPassword from './reset-password'

const AuthRouter = () => {
  return (
    <>
      <Outlet />
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="/reset-password" element={<ResetPassword />} />
      </Routes>
    </>
  )
}

export default AuthRouter
