import { t } from '@lingui/macro'
import { Card, Col, Collapse, Modal, Row } from 'antd'
import { useState } from 'react'
import { Address, Institution } from '../types'
import BillingAddressForm from './billing-address-form'
import EditButton from './edit-button'
import TextField from './text-field'

type Props = {
  billingAddress: Address | undefined
  familyId: string
  guardianAddresses: Address[]
  institutionFeatures?: Institution['features']
  onSubmitBillingAddress: (data: Address) => void
}

const BillingAddressCard = ({
  billingAddress,
  familyId,
  guardianAddresses,
  institutionFeatures,
  onSubmitBillingAddress,
}: Props) => {
  const [editModalOpen, setEditModalOpen] = useState(false)
  const { Panel } = Collapse

  const handleEditModalOk = (data: Address) => {
    onSubmitBillingAddress(data)
    setEditModalOpen(false)
  }

  const billingAddressTitle = billingAddress
    ? billingAddress.organisation
      ? billingAddress.organisation
      : `${billingAddress.firstName} ${billingAddress.lastName}`
    : ''

  const disableEdit =
    !!institutionFeatures?.innosolvInvoiceExport &&
    guardianAddresses.some(({ accountingDebitNumber }) => !!accountingDebitNumber)

  return (
    <>
      <Collapse>
        <Panel
          header={
            <div
              style={{
                whiteSpace: 'pre-wrap',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <>{billingAddressTitle}</>
              {!disableEdit && <EditButton onClick={() => setEditModalOpen(true)} />}
            </div>
          }
          key="1"
        >
          <Card size="small" style={{ marginBottom: 8 }}>
            <Row>
              <Col span={24}>
                <TextField label={t({ message: 'Strasse und Hausnummer' })} value={billingAddress?.street} />
                <TextField label={t({ message: 'PLZ' })} value={billingAddress?.zip} />
                <TextField label={t({ message: 'Ort' })} value={billingAddress?.city} />
                <TextField label={t({ message: 'Email' })} value={billingAddress?.email} />
              </Col>
            </Row>
          </Card>
        </Panel>
      </Collapse>

      <Modal
        title={t({ message: 'Rechnungsadresse Editieren' })}
        open={editModalOpen}
        onCancel={() => setEditModalOpen(false)}
        footer={null}
      >
        <BillingAddressForm
          familyId={familyId}
          guardianAddresses={guardianAddresses}
          submitButtonText={t({ message: 'Speichern' })}
          handleSubmit={handleEditModalOk}
          defaultValues={billingAddress}
        />
      </Modal>
    </>
  )
}

export default BillingAddressCard
