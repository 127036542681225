import { t } from '@lingui/macro'

export const getSupertokensTranslations = (locale: string) => {
  const translations: { [key: string]: { [key: string]: string } } = {}

  translations[locale] = {
    BRANDING_POWERED_BY_START: 'Powered by ',
    BRANDING_POWERED_BY_END: '',
    SOMETHING_WENT_WRONG_ERROR: t({ message: 'Etwas ist schief gegangen. Bitte versuchen Sie es erneut.' }),

    EMAIL_PASSWORD_EMAIL_LABEL: t({ message: 'E-Mail' }),
    EMAIL_PASSWORD_EMAIL_PLACEHOLDER: t({ message: 'E-Mail-Adresse' }),

    EMAIL_PASSWORD_PASSWORD_LABEL: t({ message: 'Passwort' }),
    EMAIL_PASSWORD_PASSWORD_PLACEHOLDER: t({ message: 'Passwort' }),

    EMAIL_PASSWORD_SIGN_IN_HEADER_TITLE: t({ message: 'Anmelden' }),
    EMAIL_PASSWORD_SIGN_IN_HEADER_SUBTITLE_START: t({ message: 'Noch nicht registriert?' }),
    EMAIL_PASSWORD_SIGN_IN_HEADER_SUBTITLE_SIGN_UP_LINK: t({ message: 'Registrieren' }),
    EMAIL_PASSWORD_SIGN_IN_HEADER_SUBTITLE_END: '',
    EMAIL_PASSWORD_SIGN_IN_FOOTER_FORGOT_PW_LINK: t({ message: 'Passwort vergessen?' }),
    EMAIL_PASSWORD_SIGN_IN_SUBMIT_BTN: t({ message: 'Anmelden' }),
    EMAIL_PASSWORD_SIGN_IN_WRONG_CREDENTIALS_ERROR: t({ message: 'E-Mail oder Passwort falsch' }),

    EMAIL_PASSWORD_SIGN_UP_HEADER_TITLE: t({ message: 'Registrieren' }),
    EMAIL_PASSWORD_SIGN_UP_HEADER_SUBTITLE_START: t({ message: 'Haben Sie bereits ein Konto?' }),
    EMAIL_PASSWORD_SIGN_UP_HEADER_SUBTITLE_SIGN_IN_LINK: t({ message: 'Anmelden' }),
    EMAIL_PASSWORD_SIGN_UP_HEADER_SUBTITLE_END: '',
    EMAIL_PASSWORD_SIGN_UP_FOOTER_START: t({
      message: 'Indem Sie fortfahren, erklären Sie sich einverstanden mit unseren ',
    }),
    EMAIL_PASSWORD_SIGN_UP_FOOTER_TOS: t({ message: 'Allgemeinen Geschäftsbedingungen' }),
    EMAIL_PASSWORD_SIGN_UP_FOOTER_AND: t({ message: ' und ' }),
    EMAIL_PASSWORD_SIGN_UP_FOOTER_PP: t({ message: 'Datenschutzbestimmungen' }),
    EMAIL_PASSWORD_SIGN_UP_FOOTER_END: '',
    EMAIL_PASSWORD_SIGN_UP_SUBMIT_BTN: t({ message: 'Registrieren' }),

    EMAIL_PASSWORD_EMAIL_ALREADY_EXISTS: t({
      message: 'Diese E-Mail-Adresse besteht bereits. Bitte melden Sie sich stattdessen an.',
    }),

    EMAIL_PASSWORD_RESET_HEADER_TITLE: t({ message: 'Passwort zurücksetzen' }),
    EMAIL_PASSWORD_RESET_HEADER_SUBTITLE: t({
      message: 'Wir werden Ihnen ein E-Mail zum Zurücksetzen Ihres Passwortes senden.',
    }),
    EMAIL_PASSWORD_RESET_SEND_FALLBACK_EMAIL: t({ message: 'Ihr Konto' }),
    EMAIL_PASSWORD_RESET_SEND_BEFORE_EMAIL: t({
      message: 'Ein E-Mail zum Zurücksenden des Passwortes wurde gesendet an ',
    }),
    EMAIL_PASSWORD_RESET_SEND_AFTER_EMAIL: t({ message: ', falls es in unserem System existiert. ' }),
    EMAIL_PASSWORD_RESET_RESEND_LINK: t({ message: 'Erneut senden oder E-Mail-Adresse ändern' }),
    EMAIL_PASSWORD_RESET_SEND_BTN: t({ message: 'Mir senden' }),
    EMAIL_PASSWORD_RESET_SIGN_IN_LINK: t({ message: 'Anmelden' }),

    EMAIL_PASSWORD_RESET_SUBMIT_PW_SUCCESS_HEADER_TITLE: t({ message: 'Erfolgreich' }),
    EMAIL_PASSWORD_RESET_SUBMIT_PW_SUCCESS_DESC: t({ message: 'Ihr Passwort wurde erfolgreich geändert.' }),
    EMAIL_PASSWORD_RESET_SUBMIT_PW_SUCCESS_SIGN_IN_BTN: t({ message: 'Anmelden' }),

    EMAIL_PASSWORD_NEW_PASSWORD_LABEL: t({ message: 'Neues Passwort' }),
    EMAIL_PASSWORD_NEW_PASSWORD_PLACEHOLDER: t({ message: 'Neues Passwort' }),
    EMAIL_PASSWORD_CONFIRM_PASSWORD_LABEL: t({ message: 'Passwort bestätigen' }),
    EMAIL_PASSWORD_CONFIRM_PASSWORD_PLACEHOLDER: t({ message: 'Bestätigen Sie Ihr Passwort' }),

    EMAIL_PASSWORD_RESET_SUBMIT_PW_HEADER_TITLE: t({ message: 'Ändern Sie Ihr Passwort' }),
    EMAIL_PASSWORD_RESET_SUBMIT_PW_HEADER_SUBTITLE: t({
      message: 'Geben Sie ein neues Passwort unten ein um es zu ändern.',
    }),
    EMAIL_PASSWORD_RESET_SUBMIT_PW_CHANGE_PW_BTN: t({ message: 'Passwort ändern' }),
    EMAIL_PASSWORD_RESET_PASSWORD_INVALID_TOKEN_ERROR: t({ message: 'Ungültiges Passwort-Reset-Token' }),

    ERROR_EMAIL_NON_STRING: t({ message: 'Die E-Mail-Adresse enthält keine Buchstaben.' }),
    ERROR_EMAIL_INVALID: t({ message: 'E-Mail-Adresse ist ungültig' }),

    ERROR_PASSWORD_NON_STRING: t({ message: 'Das Passwort darf nicht leer sein' }),
    ERROR_PASSWORD_TOO_SHORT: t({
      message: 'Das Passwort muss mindestens 8 Zeichen enthalten, darunter eine Zahl.',
    }),
    ERROR_PASSWORD_TOO_LONG: t({ message: 'Die Länge des Passworts muss weniger als 100 Zeichen betragen.' }),
    ERROR_PASSWORD_NO_ALPHA: t({ message: 'Das Passwort muss mindestens einen Buchstaben enthalten.' }),
    ERROR_PASSWORD_NO_NUM: t({ message: 'Das Passwort muss mindestens eine Zahl enthalten.' }),
    ERROR_CONFIRM_PASSWORD_NO_MATCH: t({ message: 'Bestätigungspasswort stimmt nicht überein.' }),

    ERROR_NON_OPTIONAL: t({ message: 'Feld ist nicht optional' }),

    EMAIL_VERIFICATION_RESEND_SUCCESS: t({ message: 'E-Mail gesendet' }),
    EMAIL_VERIFICATION_SEND_TITLE: t({ message: 'E-Mail-Adresse verifizieren' }),
    EMAIL_VERIFICATION_SEND_DESC_START: '',
    EMAIL_VERIFICATION_SEND_DESC_STRONG: t({ message: 'Bitte klicken Sie auf den Link' }),
    EMAIL_VERIFICATION_SEND_DESC_END: t({
      message: ' im E-Mail, das wir Ihnen gerade geschickt haben um Ihnen E-Mail-Adresse zu bestätigen.',
    }),
    EMAIL_VERIFICATION_RESEND_BTN: t({ message: 'E-Mail erneut senden' }),
    EMAIL_VERIFICATION_LOGOUT: t({ message: 'Abmelden' }),
    EMAIL_VERIFICATION_SUCCESS: t({ message: 'E-Mail-Verifizierung erfolgreich' }),
    EMAIL_VERIFICATION_CONTINUE_BTN: t({ message: 'Weiter' }),
    EMAIL_VERIFICATION_CONTINUE_LINK: t({ message: 'Weiter' }),
    EMAIL_VERIFICATION_EXPIRED: t({ message: 'Link zur E-Mail-Verifizierung ist abgelaufen.' }),
    EMAIL_VERIFICATION_ERROR_TITLE: t({ message: 'Etwas ist schief gegangen' }),
    EMAIL_VERIFICATION_ERROR_DESC: t({
      message: 'Wir haben einen Fehler festgestellt. Bitte kontaktieren Sie uns, damit wir weiterhelfen können.',
    }),
    EMAIL_VERIFICATION_LINK_CLICKED_HEADER: t({ message: 'E-Mail-Adresse verifizieren' }),
    EMAIL_VERIFICATION_LINK_CLICKED_DESC: t({
      message: 'Bitte klicken Sie auf den folgenden Link um die E-Mail-Adresse zu verifizieren.',
    }),
    EMAIL_VERIFICATION_LINK_CLICKED_CONTINUE_BUTTON: t({ message: 'Weiter' }),

    'This email already exists. Please sign in instead.': t({
      message: 'Diese E-Mail-Adresse besteht bereits. Bitte melden Sie sich stattdessen an.',
    }),
    'Field is not optional': t({ message: 'Feld ist nicht optional' }),
    'Password must contain at least 8 characters, including a number': t({
      message: 'Das Passwort muss mindestens 8 Zeichen enthalten, darunter eine Zahl.',
    }),
    "Password's length must be lesser than 100 characters": t({
      message: 'Die Länge des Passworts muss weniger als 100 Zeichen betragen.',
    }),
    'Password must contain at least one alphabet': t({
      message: 'Das Passwort muss mindestens einen Buchstaben enthalten.',
    }),
    'Password must contain at least one number': t({
      message: 'Das Passwort muss mindestens eine Zahl enthalten.',
    }),
    'Email is invalid': t({ message: 'E-Mail-Adresse ist ungültig' }),
  }

  return translations
}
