import { filter, map, pipe, sortBy } from 'remeda'
import { Contract, Module, Location, AgeGroup } from '../../../../types'

export const getAgeGroupFromPreviousContract = ({
  contract,
  module,
  location,
}: {
  contract: Contract
  module: Module
  location: Location
}): AgeGroup | undefined => {
  const ageGroupIdsSortedByBookingCount = pipe(
    location.ageGroups,
    map((ageGroup) => ({
      ageGroupId: ageGroup.id,
      count: contract.bookedModules.filter((bookedModule) => bookedModule.ageGroupId === ageGroup.id).length,
    })),
    filter((x) => x.count > 0),
    sortBy([(x) => x.count, 'desc']),
    map((x) => x.ageGroupId)
  )

  const ageGroupId = ageGroupIdsSortedByBookingCount.find((ageGroupId) =>
    module.ageGroups.some((ageGroup) => ageGroup.ageGroupId === ageGroupId)
  )
  return location.ageGroups.find((ageGroup) => ageGroup.id === ageGroupId)
}
