import FormPage from '../../../../components/form/form-page'
import GuardianForm1 from '../../../../components/guardian/guardian-1-form'
import GuardianForm2 from '../../../../components/guardian/guardian-2-form'
import { NewGuardian } from '../../../../components/guardian/guardian-form-fields'
import { RegistrationConfiguration } from '../../../../types'
import { useRegistrationContext } from '../registration-context'

type Props = {
  handleSubmit: (data: NewGuardian) => Promise<void>
  defaultValues?: NewGuardian
  guardianBluePrint?: NewGuardian
  title: string
  handlePrevious?: () => void
  isSecondParent: boolean
  registrationConfiguration: RegistrationConfiguration
}
const GuardianFormPage = ({
  handleSubmit,
  isSecondParent,
  handlePrevious,
  title,
  defaultValues,
  guardianBluePrint,
  registrationConfiguration,
}: Props) => {
  const { familyId } = useRegistrationContext()

  if (isSecondParent) {
    return (
      <FormPage title={title} progress={2}>
        <GuardianForm2
          familyId={familyId}
          handleSubmit={handleSubmit}
          handlePrevious={handlePrevious}
          isSecondParent={isSecondParent}
          defaultValues={defaultValues}
          guardianBluePrint={guardianBluePrint}
          isRegistration={true}
          registrationConfiguration={registrationConfiguration}
        />
      </FormPage>
    )
  } else {
    return (
      <FormPage title={title} progress={1}>
        <GuardianForm1
          familyId={familyId}
          handleSubmit={handleSubmit}
          handlePrevious={handlePrevious}
          isSecondParent={isSecondParent}
          defaultValues={defaultValues}
          isRegistration={true}
          registrationConfiguration={registrationConfiguration}
        />
      </FormPage>
    )
  }
}

export default GuardianFormPage
