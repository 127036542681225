import { t } from '@lingui/macro'
import { Collapse, Modal } from 'antd'
import dayjs from 'dayjs'
import { useState } from 'react'
import translateSex from '../../helper/translate-sex'
import translatedMaritialStatus from '../../helper/translated-maritial-status'
import { Institution, RegistrationConfiguration } from '../../types'
import DateField from '../date-field'
import EditButton from '../edit-button'
import TextField from '../text-field'
import GuardianForm1 from './guardian-1-form'
import { NewGuardian } from './guardian-form-fields'

type Props = {
  guardian: NewGuardian
  onSubmitGuardianInformation: (data: NewGuardian) => void
  isSecondParent: boolean
  isSoleCustodyLockedIn?: boolean
  isRegistration?: boolean
  registrationConfiguration: RegistrationConfiguration
  institutionFeatures?: Institution['features']
}

const GuardianCard = ({
  guardian,
  onSubmitGuardianInformation,
  isSecondParent,
  isSoleCustodyLockedIn,
  isRegistration = false,
  registrationConfiguration,
  institutionFeatures,
}: Props) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const { Panel } = Collapse

  const showEditModal = () => {
    setIsEditModalOpen(true)
  }

  const handleEditModalOk = async (data: NewGuardian) => {
    onSubmitGuardianInformation(data)
    setIsEditModalOpen(false)
  }

  const handleEditModalCancel = () => {
    setIsEditModalOpen(false)
  }

  const handleOnEdit = () => {
    showEditModal()
  }

  return (
    <>
      <Collapse>
        <Panel
          header={
            <div
              style={{
                whiteSpace: 'pre-wrap',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <>
                {guardian.address.firstName} {guardian.address.lastName}
              </>
              <EditButton onClick={handleOnEdit} />
            </div>
          }
          key="1"
        >
          <TextField label={t({ message: 'Strasse und Hausnummer' })} value={guardian.address.street} />
          <TextField label={t({ message: 'PLZ' })} value={guardian.address.zip} />
          <TextField label={t({ message: 'Ort' })} value={guardian.address.city} />
          {registrationConfiguration.askForGuardianDateOfBirth !== 'off' && (
            <DateField label={t({ message: 'Geburtsdatum' })} value={dayjs(guardian.dateOfBirth).toDate()} />
          )}
          <TextField label={t({ message: 'Email' })} value={guardian.address.email} />
          {registrationConfiguration.askForGuardianMaritalStatus !== 'off' && (
            <TextField label={t({ message: 'Zivilstand' })} value={translatedMaritialStatus(guardian.maritalStatus)} />
          )}
          {registrationConfiguration.askForGuardianEmployer !== 'off' && (
            <TextField label={t({ message: 'Arbeitgeber' })} value={guardian.employer} />
          )}
          {registrationConfiguration.askForGuardianOccupation !== 'off' && (
            <TextField label={t({ message: 'Beruf' })} value={guardian.occupation} />
          )}
          <TextField label={t({ message: 'Telefon' })} value={guardian.phone} />
          {registrationConfiguration.askForGuardianWorkPhoneNumber !== 'off' && (
            <TextField label={t({ message: 'Telefon Arbeit' })} value={guardian.phoneWork} />
          )}
          <TextField label={t({ message: 'Geschlecht' })} value={translateSex(guardian.sex)} />
        </Panel>
      </Collapse>

      <Modal
        title={t({ message: 'Person Editieren' })}
        open={isEditModalOpen}
        onCancel={handleEditModalCancel}
        footer={null}
      >
        <GuardianForm1
          defaultValues={guardian}
          handleSubmit={handleEditModalOk}
          submitButtonText={t({ message: 'Speichern' })}
          isSecondParent={isSecondParent}
          familyId={guardian.familyId}
          soleCustodyLockedIn={isSoleCustodyLockedIn}
          isRegistration={isRegistration}
          registrationConfiguration={registrationConfiguration}
          institutionFeatures={institutionFeatures}
        />
      </Modal>
    </>
  )
}

export default GuardianCard
