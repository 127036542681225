import { t } from '@lingui/macro'
import { Sex } from '../types'

const translateSex = (sex?: Sex): string | undefined => {
  let result
  switch (sex) {
    case 'female':
      result = t({ message: 'Weiblich' })
      break
    case 'male':
      result = t({ message: 'Männlich' })
      break
    case 'other':
      result = t({ message: 'Divers' })
      break
  }
  return result
}

export default translateSex
