import { t } from '@lingui/macro'
import { Alert, Button, Divider, Form, Input, Typography } from 'antd'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { signUp } from 'supertokens-web-js/recipe/emailpassword'
import FormPage from '../../components/form/form-page'
import AuthLayoutPage from './auth-layout-page'
import { sendVerificationEmail } from 'supertokens-web-js/recipe/emailverification'

const { Paragraph } = Typography

type LoginFormType = { email: string; password: string }
type FieldError = { validateStatus: '' | 'success' | 'error'; message: string }

const SignUpPage = () => {
  const navigate = useNavigate()
  const [form] = Form.useForm<LoginFormType>()
  const [fieldError, setFieldError] = useState<{ email: FieldError; password: FieldError }>({
    email: { validateStatus: '', message: '' },
    password: { validateStatus: '', message: '' },
  })
  const [generalError, setGeneralError] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const handleOnFormSubmit = async (values: LoginFormType) => {
    const signUpData = {
      formFields: [
        { id: 'email', value: values.email },
        { id: 'password', value: values.password },
      ],
    }
    setSubmitting(true)
    setGeneralError(false)
    try {
      const response = await signUp(signUpData)
      switch (response.status) {
        case 'FIELD_ERROR':
          const emailMessage = response.formFields.find((f) => f.id === 'email')?.error || ''
          const passwordMessage = response.formFields.find((f) => f.id === 'password')?.error || ''
          setFieldError({
            email: { message: emailMessage, validateStatus: emailMessage ? 'error' : 'success' },
            password: { message: passwordMessage, validateStatus: passwordMessage ? 'error' : 'success' },
          })
          break
        case 'OK':
          await sendVerificationEmail()
          navigate('/auth/verify-email')
          break
        default:
      }
    } catch (e: any) {
      setGeneralError(true)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <AuthLayoutPage>
      <FormPage title={t({ message: `Registrieren` })}>
        <Form<LoginFormType> form={form} name="signup" layout="vertical" onFinish={handleOnFormSubmit}>
          <Paragraph>
            {t({ message: `Haben Sie bereits ein Konto?` })} <Link to={'/auth'}>{t({ message: `Anmelden` })}</Link>
          </Paragraph>

          <Divider />

          {generalError && (
            <Alert message={t({ message: `Etwas ist schief gegangen. Bitte versuchen Sie es erneut.` })} type="error" />
          )}
          <Form.Item
            label={t({ message: `E-Mail` })}
            name="email"
            validateStatus={fieldError.email.validateStatus}
            help={fieldError.email.message}
          >
            <Input type="email" placeholder={t({ message: `E-Mail-Adresse` })} />
          </Form.Item>

          <Form.Item
            label={t({ message: `Passwort` })}
            name="password"
            validateStatus={fieldError.password.validateStatus}
            help={fieldError.password.message}
          >
            <Input.Password placeholder={t({ message: `Passwort` })} />
          </Form.Item>

          <Button loading={submitting} type="primary" htmlType="submit">
            {t({ message: `Registrieren` })}
          </Button>
        </Form>
      </FormPage>
    </AuthLayoutPage>
  )
}

export default SignUpPage
