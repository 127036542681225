import { Col, Collapse, Row } from 'antd'
import { useInstitutionContext } from '../../../routes/institution/institution-context'
import { NewChild } from '../../../routes/institution/registration/form-pages/child-form-pages/new-child-context'
import { ModuleBooking } from '../../../routes/institution/registration/registration-context'
import { Family, Guardian } from '../../../types'

import { NewChildBaseInformation } from '../child-form/child-base-information-form'
import { NewChildFurtherInformation } from '../child-form/child-further-information-form'
import { NewHolidayCareBookingType } from '../child-form/child-holiday-care-form'
import { NewChildImportantInformation } from '../child-form/child-important-information-form'
import ChildCardBaseInformationSection from './child-card-base-information-section'
import ChildCardContractSection from './child-card-contract-section'
import ChildCardFurtherInformationSection from './child-card-further-information-section'
import ChildCardHolidayCareSection from './child-card-holiday-care-section'
import ChildCardImportantInformationSection from './child-card-important-information-section'
import ChildCardModulesSection from './child-card-modules-section'

type Props = {
  child: NewChild
  family?: Family
  guardian?: Guardian
  onClick?: () => void
  onSubmitEditedBaseInformation: (information: NewChildBaseInformation, childId: string) => void
  onSubmitEditedFurtherInformation: (information: NewChildFurtherInformation, childId: string) => void
  onSubmitEditedImportantInformation: (information: NewChildImportantInformation, childId: string) => void
  onSubmitEditedModules?: (information: ModuleBooking, childId: string) => void
  onSubmitEditedHolidayCare?: (information: NewHolidayCareBookingType[], childId: string) => void
}

const ChildCard = ({
  onSubmitEditedBaseInformation,
  onSubmitEditedFurtherInformation,
  onSubmitEditedImportantInformation,
  onSubmitEditedModules,
  onSubmitEditedHolidayCare,
  family,
  guardian,
  child,
}: Props) => {
  const { Panel } = Collapse
  const { institution } = useInstitutionContext()

  return (
    <Collapse>
      <Panel
        header={
          <div
            style={{
              whiteSpace: 'pre-wrap',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <>
              {child.baseInformation.firstName} {child.baseInformation.lastName}
            </>
          </div>
        }
        key="1"
      >
        <Row>
          <Col span={24}>
            {family && institution && guardian && (
              <ChildCardContractSection
                child={family?.children.find((c) => c.id === child.id)}
                guardian={guardian}
                institution={institution}
              />
            )}

            <ChildCardBaseInformationSection
              childId={child.id}
              onSubmitEditedBaseInformation={onSubmitEditedBaseInformation}
              newChildBaseInformation={child.baseInformation}
            />
            <ChildCardImportantInformationSection
              childId={child.id}
              onSubmitEditedImportantInformation={onSubmitEditedImportantInformation}
              newChildImportantInformation={child.importantInformation}
            />
            <ChildCardFurtherInformationSection
              childId={child.id}
              onSubmitEditedEditFurtherInformation={onSubmitEditedFurtherInformation}
              newChildFurtherInformation={child.furtherInformation}
            />
            {onSubmitEditedModules && !child.baseInformation.isOutOfTown && child.moduleBooking && (
              <ChildCardModulesSection
                schoolId={child.baseInformation.schoolId}
                childId={child.id}
                onSubmitEditedModules={onSubmitEditedModules}
                moduleBooking={child.moduleBooking}
              />
            )}
            {onSubmitEditedHolidayCare && (
              <ChildCardHolidayCareSection
                childId={child.id}
                defaultValues={child.holidayCareBookings}
                onSubmitEditedHolidayCare={onSubmitEditedHolidayCare}
              />
            )}
          </Col>
        </Row>
      </Panel>
    </Collapse>
  )
}

export default ChildCard
