import { i18n } from '@lingui/core'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import isToday from 'dayjs/plugin/isToday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekday from 'dayjs/plugin/weekday'
import 'dayjs/locale/de-ch'
import 'dayjs/locale/en-gb'
import 'dayjs/locale/fr-ch'
import 'dayjs/locale/it-ch'

export const configureDayJs = () => {
  dayjs.extend(weekOfYear)
  dayjs.extend(weekday)
  dayjs.extend(isToday)
  dayjs.extend(duration)

  switch (i18n.locale) {
    case 'en':
      dayjs.locale('en-gb')
      break
    case 'fr':
      dayjs.locale('fr-ch')
      break
    case 'it':
      dayjs.locale('it-ch')
      break
    default:
      dayjs.locale('de-ch')
      break
  }

  const testWeekDay = dayjs('2023-07-03').weekday()
  if (testWeekDay !== 0) {
    console.error(`Weekday for '2023-07-03' is ${testWeekDay}. This seems wrong! Please react.`)
    console.trace()
  }
}
