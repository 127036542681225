import { ReactMarkdown } from 'react-markdown/lib/react-markdown'

import { Typography } from 'antd'

const { Title, Paragraph } = Typography
const LeobaMarkdown = (props: { markdown: string }) => {
  return (
    <ReactMarkdown
      components={{
        h1: ({ node, ...props }) => <Title level={2}>{props.children}</Title>,
        h2: ({ node, ...props }) => <Title level={3}>{props.children}</Title>,
        h3: ({ node, ...props }) => <Title level={4}>{props.children}</Title>,
        h4: ({ node, ...props }) => <Title level={5}>{props.children}</Title>,
        p: ({ node, ...props }) => <Paragraph>{props.children}</Paragraph>,
        ul: ({ node, ...props }) => (
          <Paragraph>
            <ul>{props.children}</ul>
          </Paragraph>
        ),
        ol: ({ node, ...props }) => (
          <Paragraph>
            <ol>{props.children}</ol>
          </Paragraph>
        ),
        a: ({ node, ...props }) => (
          <a href={props.href} target="_blank" rel="noreferrer">
            {props.children}
          </a>
        ),
      }}
    >
      {props.markdown}
    </ReactMarkdown>
  )
}

export default LeobaMarkdown
