import { useEffect, useState } from 'react'
import { ModulePrice } from '../../../../types'

export const useModulePrices = ({
  institutionId,
  locationId,
  ageGroupId,
  selection,
}: {
  institutionId: string
  locationId: string
  ageGroupId: string
  selection: Array<{ moduleId: string; ageGroupId: string }>
}) => {
  const [prices, setPrices] = useState<Array<{ moduleId: string; price: ModulePrice }>>([])
  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_LEOBA_SERVER}/api/eltern-app/${institutionId}/registration/module/get-module-prices`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ locationId, ageGroupId, selection }),
      }
    )
      .then((response) => response.json())
      .then(setPrices)
  }, [institutionId, locationId, ageGroupId, selection])
  return prices
}

export const useAdditionalBookingPrices = ({
  institutionId,
  contractId,
}: {
  institutionId: string
  contractId: string
}) => {
  const [prices, setPrices] = useState<Array<{ moduleId: string; price: number | null }>>([])
  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_LEOBA_SERVER}/api/eltern-app/${institutionId}/registration/module/get-additional-booking-prices`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ contractId }),
      }
    )
      .then((response) => response.json())
      .then(setPrices)
  }, [institutionId, contractId])
  return prices
}
