import { t } from '@lingui/macro'
import { GeneralHealth } from '../types'

const translateGeneralHealth = (health?: GeneralHealth): string => {
  let result
  switch (health) {
    case 'good':
      result = t({ message: 'Gut' })
      break
    case 'sick':
      result = t({ message: 'Mein Kind leidet unter Krankheiten' })
      break
    case undefined:
      result = ''
      break
  }
  return result
}

export default translateGeneralHealth
