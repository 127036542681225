import { t } from '@lingui/macro'
import FamilyInformationForm, { FamilyInformation } from '../../../../components/family-information-form'
import FormPage from '../../../../components/form/form-page'

type Props = {
  defaultValues?: FamilyInformation
  handleSubmit: (data: FamilyInformation) => Promise<void>
  handlePrevious?: () => void
}

const FamilyInformationFormPage = ({ defaultValues, handleSubmit, handlePrevious }: Props) => {
  return (
    <FormPage title={t({ message: 'Informationen zur Familie' })} progress={2.5}>
      <FamilyInformationForm
        initialValues={defaultValues}
        handleSubmit={handleSubmit}
        handlePrevious={handlePrevious}
      />
    </FormPage>
  )
}

export default FamilyInformationFormPage
