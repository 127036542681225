import { UserOutlined } from '@ant-design/icons'
import { t, Trans } from '@lingui/macro'
import { Avatar, Button, Dropdown, MenuProps } from 'antd'
import md5 from 'md5'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { signOut } from 'supertokens-web-js/recipe/emailpassword'
import { useGuardianContext } from '../routes/institution/guardian/guardian-context'
import { Guardian, Institution, User } from '../types'
import { notEmpty } from '../helper/not-empty'

const LoginButton = () => {
  const { user, guardian } = useGuardianContext()
  const navigate = useNavigate()

  const handleLogin = () => {
    navigate('/auth')
  }

  const handleLogout = async () => {
    await signOut()
    useGuardianContext.setState({ user: undefined, guardians: undefined })
    navigate('/auth')
  }

  const handleClickOnName = () => {
    if (guardian) {
      navigate(`/${guardian.institutionId}/guardian/${guardian.id}/settings`)
    }
  }

  return (
    <>
      {user ? (
        <UserIcon user={user} guardian={guardian} onLogout={handleLogout} onClickOnName={handleClickOnName} />
      ) : (
        <Button onClick={handleLogin}>
          <Trans>Login</Trans>
        </Button>
      )}
    </>
  )
}

const UserIcon = (props: { user: User; guardian?: Guardian; onLogout: () => void; onClickOnName: () => void }) => {
  const [useGravatar, setUseGravatar] = useState(true)
  const navigate = useNavigate()
  const { guardian, guardians, institutions } = useGuardianContext()
  const initials = getInitials(props.guardian)

  const handleChangeGuardianContext = (data: { guardian: Guardian; institution: Institution }) => async () => {
    navigate(`${data.institution.id}/guardian/${data.guardian.id}/home`)
  }

  const items: MenuProps['items'] = [
    {
      key: 'name',
      label: t({ message: 'Einstellungen' }),
      onClick: props.onClickOnName,
    },
  ]
  if (guardians && guardians.length > 0 && institutions.length > 0) {
    const hasDifferentNames =
      guardians.reduce((names, guardian) => {
        const name = guardian.address.firstName + guardian.address.lastName
        if (!(names.indexOf(name) >= 0)) {
          names.push(name)
        }
        return names
      }, [] as string[]).length > 0
    guardians
      .filter((otherGuardian) => otherGuardian.id !== guardian?.id)
      .map((guardian) => {
        const institution = institutions.find((i) => i.id === guardian.institutionId)
        if (institution) {
          return { guardian, institution }
        } else {
          return undefined
        }
      })
      .filter(notEmpty)
      .forEach((data) => {
        items.push({
          key: 'g-' + data.guardian.id,
          label: data.institution.name + (hasDifferentNames ? ` (${data.guardian.address.firstName})` : ''),
          onClick: handleChangeGuardianContext(data),
        })
      })
  }
  items.push({ key: 'logout', label: t({ message: 'Logout' }), onClick: props.onLogout })

  const handleErrorLoadingGravatar = () => {
    setUseGravatar(false)
    return true
  }
  return (
    <Dropdown trigger={['click', 'hover']} menu={{ items }}>
      <div>
        {useGravatar && <Avatar onError={handleErrorLoadingGravatar} src={getGravatarURL(props.user)} />}
        {!useGravatar && initials && <Avatar>{initials}</Avatar>}
        {!useGravatar && !initials && <Avatar icon={<UserOutlined />} />}
      </div>
    </Dropdown>
  )
}

const getInitials = (guardian?: Guardian) => {
  if (guardian) {
    const address = guardian.address
    if (address.firstName.length > 0 && address.lastName.length > 0) {
      return (address.firstName.charAt(0) + address.lastName.charAt(0)).toLocaleUpperCase()
    }
  }
  return undefined
}

const getGravatarURL = (user: User) => {
  const hash = md5(user.email.toLocaleLowerCase())
  return `https://www.gravatar.com/avatar/${hash}?d=404`
}

export default LoginButton
