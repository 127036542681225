import { t } from '@lingui/macro'
import { Col, Input, Row } from 'antd'
import { useState } from 'react'
import { Control, Controller } from 'react-hook-form'
import ErrorMessage from '../error-message'

type Props = {
  control: Control<any, any>
  name: string
  required: boolean
}

const PhoneNumberInput = ({ name, control, required }: Props) => {
  return (
    <Controller
      name={name}
      rules={{ required: required }}
      control={control}
      render={({ field: { value, onChange } }) => <InputComponent value={value} onChange={onChange} />}
    />
  )
}

const InputComponent = ({ value, onChange }: { value: any; onChange: (...event: any[]) => void }) => {
  const [countryCodeError, setCountryCodeError] = useState('')
  const [phoneNumberError, setPhoneNumberError] = useState('')
  const [countryCode, setCountryCode] = useState<string | undefined>(
    value !== undefined ? value.substring(0, value.indexOf(' ')) : undefined
  )
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>(
    value !== undefined ? value.substring(value.indexOf(' ') + 1) : undefined
  )

  const handleChangeCountryCode = (countryCodeValue: string) => {
    setCountryCode(countryCodeValue)

    const countryCodeIsValid = countryCodeValue.match(/0{2}\d+/) || countryCodeValue.match(/\+{1}\d+/)

    if (countryCodeIsValid || !countryCodeValue) {
      setCountryCodeError('')
    } else if (!+countryCodeValue) {
      setCountryCodeError(t({ message: 'Vorwahl muss eine Zahl sein. ' }))
    } else {
      setCountryCodeError(t({ message: 'Vorwahl muss mit "00" oder "+" beginnen. ' }))
    }

    if (!!phoneNumber && !!countryCodeValue && countryCodeIsValid && !phoneNumberError) {
      onChange(countryCodeValue.replace(/\s/g, '') + ' ' + phoneNumber)
    } else {
      onChange(undefined)
    }
  }

  const handleChangePhoneNumber = (phoneNumberValue: string) => {
    setPhoneNumber(phoneNumberValue)

    const phoneNumberIsValid = phoneNumberValue.match(/^[0-9 _]*[0-9]*$/)

    if (phoneNumberIsValid || !phoneNumberValue) {
      setPhoneNumberError('')
    } else {
      setPhoneNumberError(t({ message: 'Mobile Nummer muss eine Zahl sein.' }))
    }

    if (!!phoneNumberValue && !!countryCode && phoneNumberIsValid && !countryCodeError) {
      onChange(countryCode.replace(/\s/g, '') + ' ' + phoneNumberValue)
    } else {
      onChange(undefined)
    }
  }

  return (
    <>
      <Row gutter={10}>
        <Col span={8}>
          <Input
            value={countryCode}
            placeholder="+41"
            onChange={(e) => handleChangeCountryCode(e.target.value)}
            type={'tel'}
            maxLength={5}
            autoComplete="tel-country-code"
          />
        </Col>
        <Col span={16}>
          <Input
            value={phoneNumber}
            placeholder="77 432 12 12"
            onChange={(e) => handleChangePhoneNumber(e.target.value)}
            type={'tel'}
            maxLength={20}
            autoComplete="tel-national"
          />
        </Col>
      </Row>
      {!!countryCodeError && (
        <Row>
          <ErrorMessage message={countryCodeError} />
        </Row>
      )}
      {!!phoneNumberError && (
        <Row>
          <ErrorMessage message={phoneNumberError} />
        </Row>
      )}
    </>
  )
}
export default PhoneNumberInput
