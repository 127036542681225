import { Col, Row, Space, Typography } from 'antd'
import { moduleCompareString } from '../../../../lib/module-compare-string'
import weekdays from '../../../../lib/weekdays'
import { Location, ModulePrice } from '../../../../types'
import { ModuleChoice } from './module-form-with-school'
import SelectableModuleCardWithSchool from './selectable-module-card-with-school'
import { useInstitutionContext } from '../../../../routes/institution/institution-context'
import { SpecialWednesdayHuenenberg } from './special-wednesday-huenenberg'
const { Title } = Typography

type Props = {
  primaryLocation: Location
  moduleChoices: ModuleChoice[]
  onModuleChange: (
    moduleId: string,
    checked: boolean,
    ageGroupeId: string | undefined,
    comingFromGoingTo: { comingFrom?: string; comingFromTime?: string; goingTo?: string; goingToTime?: string }
  ) => void
  prices: Array<{ moduleId: string; price: ModulePrice }>
}

const ModuleBookingViewAllLocations = ({ primaryLocation, moduleChoices, onModuleChange, prices }: Props) => {
  const { institution } = useInstitutionContext()
  const subsidiesHuenenberg = institution?.features.subsidiesHuenenberg ? true : false
  const moduleChoicesByWeekday = (weekday: string, moduleChoice: ModuleChoice[]): ModuleChoice[] =>
    moduleChoice.filter((module) => module.weekday === weekday)
  return (
    <Row gutter={16}>
      {weekdays.filter(hasModules(moduleChoices)).map((weekday) => (
        <Col flex={1} className="weekday" key={weekday.name}>
          <Row
            style={{
              height: '100%',
              flexWrap: 'nowrap',
              paddingBlock: '0.5em',
            }}
          >
            <Space
              direction="vertical"
              key={weekday.name}
              style={{
                flex: 'auto',
                flexWrap: 'nowrap',
                height: '100%',
                paddingBlock: '0.5em',
              }}
            >
              <Title level={3}>{weekday.title}</Title>

              {weekday.name === 'wednesday' && subsidiesHuenenberg ? (
                <SpecialWednesdayHuenenberg
                  moduleChoices={moduleChoicesByWeekday(weekday.name, moduleChoices).sort((a, b) =>
                    moduleCompareString(a).localeCompare(moduleCompareString(b))
                  )}
                  primaryLocation={primaryLocation}
                  onModuleChange={onModuleChange}
                />
              ) : (
                <>
                  {moduleChoicesByWeekday(weekday.name, moduleChoices)
                    .sort((a, b) => moduleCompareString(a).localeCompare(moduleCompareString(b)))
                    .map((module, i) => (
                      <SelectableModuleCardWithSchool
                        primaryLocation={primaryLocation}
                        module={module}
                        key={module.id}
                        onChange={onModuleChange}
                        price={prices.find((modulePrice) => modulePrice.moduleId === module.id)?.price ?? null}
                      />
                    ))}
                </>
              )}
            </Space>
          </Row>
        </Col>
      ))}
    </Row>
  )
}

export default ModuleBookingViewAllLocations

const hasModules =
  (moduleChoices: ModuleChoice[]) =>
  (weekday: { title: string; name: string }): boolean => {
    return moduleChoices.some((mc) => mc.weekday === weekday.name && mc.id)
  }
