import { Trans } from '@lingui/macro'
import { Card } from 'antd'
import { toDateTimeString } from '../../helper/format-date'
import { ChildMessage } from '../../types'

const MessageCard = ({ message, highlight }: { message: ChildMessage; highlight?: boolean }) => {
  return (
    <Card
      size="small"
      key={message.id}
      style={{
        width: '100%',
        marginBottom: '0.5em',
        background: highlight ? '#adedd2' : 'white',
      }}
      title={
        <div
          style={{
            flexWrap: 'wrap',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <div>
            {message.senderRole === 'Guardian' ? (
              <Trans>Gesendete Nachricht</Trans>
            ) : (
              <Trans>Nachricht von Institution</Trans>
            )}
          </div>
          <div>{toDateTimeString(new Date(message.dateTime))}</div>
        </div>
      }
    >
      <span style={{ whiteSpace: 'pre-wrap' }}>{message.message}</span>
    </Card>
  )
}

export default MessageCard
