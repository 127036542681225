import { t } from '@lingui/macro'
import FormPage from '../../../../components/form/form-page'
import TaxInformationForm from '../../../../components/tax-information-form'
import { TaxInformation } from '../../../../types'

type Props = {
  defaultValues?: TaxInformation
  defaultOutOfTown: boolean | undefined
  familyId: string
  handleSubmit: (data: TaxInformation) => void
  handleOutOfTownChild: () => void
  handlePrevious?: () => void
}

const TaxInformationFormPage = ({
  defaultValues,
  defaultOutOfTown,
  familyId,
  handleSubmit,
  handleOutOfTownChild,
  handlePrevious,
}: Props) => {
  return (
    <FormPage title={t({ message: 'Subventionierung' })} progress={2.5}>
      <TaxInformationForm
        initialValues={defaultValues}
        defaultOutOfTown={defaultOutOfTown}
        familyId={familyId}
        handleSubmit={handleSubmit}
        handleOutOfTownChild={handleOutOfTownChild}
        handlePrevious={handlePrevious}
      />
    </FormPage>
  )
}

export default TaxInformationFormPage
