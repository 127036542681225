import { Capacitor } from '@capacitor/core'
import { t } from '@lingui/macro'
import { Typography } from 'antd'

const { Paragraph } = Typography

const AppStores = () => {
  if (Capacitor.getPlatform() === 'web') {
    return (
      <>
        <Paragraph>
          {t({
            message: 'Wussten Sie, dass es die Eltern-App auch für Android und iOS gibt?',
          })}
        </Paragraph>
        <Paragraph>
          <img
            style={{ display: 'block' }}
            alt="Get it on Google Play"
            src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
            height={97}
            width={250}
          />
          <img
            src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1682553600"
            alt="Download on the App Store"
            style={{ borderRadius: '13px', width: '250px', height: '83px', padding: '5px' }}
          />
        </Paragraph>
      </>
    )
  } else {
    return null
  }
}

export default AppStores
