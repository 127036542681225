import { t } from '@lingui/macro'
import { useEffect, useState } from 'react'
import FormPage from '../../../components/form/form-page'
import BookingMutationCard from '../../../components/guardian-page/booking-mutation-card'
import ExpandableInfoList from '../../../components/guardian-page/expandable-info-list'
import { ChildSwitcher } from '../../../components/guardian/child-switcher'
import { getActiveContract, getActiveContractOrUndefined } from '../../../helper/get-active-contract'
import moduleMutationToBookingMutation from '../../../helper/module-mutation-to-booking-mutation'
import { Child, Institution, ModuleMutation } from '../../../types'
import { useInstitutionContext } from '../institution-context'
import { useGuardianContext } from './guardian-context'

const GuardianCancellationsPage = () => {
  const { currentChild } = useGuardianContext()
  const { institution } = useInstitutionContext()

  const [moduleMutations, setModuleMutations] = useState<ModuleMutation[] | undefined>(undefined)

  useEffect(() => {
    if (currentChild && institution) {
      getModuleCancellations(currentChild, institution).then((cancellations) => {
        setModuleMutations(cancellations)
      })
    }
  }, [institution, currentChild])

  const handleOnMutationDeleted = async () => {
    if (currentChild && institution) {
      const cancellations = await getModuleCancellations(currentChild, institution)
      setModuleMutations(cancellations)
    }
  }

  return (
    <FormPage title={''}>
      <>
        <ChildSwitcher />
        {moduleMutations === undefined && <div>{t({ message: 'Abmeldungen werden geladen...' })}</div>}
        {institution && moduleMutations && (
          <ExpandableInfoList
            data={moduleMutations.map((moduleMutation) => moduleMutationToBookingMutation(moduleMutation, institution))}
            dateTimeKey={'effectiveAt'}
            mapFunction={(mutation) => (
              <BookingMutationCard
                institution={institution}
                key={mutation.id}
                bookingMutation={mutation}
                onDelete={handleOnMutationDeleted}
              />
            )}
            noDataText={t({ message: 'Es gibt noch keine Abmeldungen.' })}
          />
        )}
      </>
    </FormPage>
  )
}

export default GuardianCancellationsPage

const getModuleCancellations = async (child: Child, institution: Institution) => {
  if (getActiveContractOrUndefined(child.contracts) !== undefined) {
    const response = await fetch(
      `${process.env.REACT_APP_LEOBA_SERVER}/api/eltern-app/${institution.id}/guardian/module-mutation?contractId=${
        getActiveContract(child.contracts).id
      }`,
      {
        headers: { accept: 'application/json' },
      }
    )
    if (response.ok) {
      const result = (await response.json()) as ModuleMutation[]
      const moduleCancellations = result.filter((mut) => mut.mutationType === 'cancellation')
      return moduleCancellations
    }
  }

  return []
}
