import { t } from '@lingui/macro'
import { ModuleMutationCancellationReason } from '../types'

const translateModuleMutationCancellationReason = (
  moduleMutationCancellationReason: ModuleMutationCancellationReason
): string => {
  let result
  switch (moduleMutationCancellationReason) {
    case 'holiday':
      result = t({ message: 'Ferien' })
      break
    case 'sickness':
      result = t({ message: 'Krankheit' })
      break
    case 'other':
      result = t({ message: 'Divers' })
      break
  }
  return result
}

export default translateModuleMutationCancellationReason
