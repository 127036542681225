import { t } from '@lingui/macro'
import { Layout, List, Spin, Typography } from 'antd'
import { Link } from 'react-router-dom'
import AppStores from '../../components/app-stores'
import { useGuardianContext } from './guardian/guardian-context'
const { Title, Paragraph } = Typography
const { Content } = Layout

export const InstitutionIndexPage = () => {
  const { guardians, institutions } = useGuardianContext()

  return (
    <Content style={{ width: 'fit-content', marginInline: 'auto', padding: '0 1em' }}>
      {(guardians === undefined || guardians.length === 1) && (
        <div>
          <Title level={1}>{t({ message: 'Daten werden geladen...' })}</Title>
          <Spin />
        </div>
      )}
      {guardians && institutions.length > 0 && guardians.length > 1 && (
        <>
          <Title level={1}>{t({ message: 'Bitte wählen Sie Ihren gewünschten Zugang aus:' })}</Title>
          <List size="small" split={false} style={{ marginBlock: '1em' }}>
            {guardians.map((guardian) => {
              return (
                <List.Item key={guardian.id}>
                  <Link key={guardian.institutionId} to={`/${guardian.institutionId}/guardian/${guardian.id}/home`}>
                    {institutions.length > 0
                      ? institutions.find((insti) => insti.id === guardian.institutionId)?.name
                      : guardian.institutionId}
                    {' / '}
                    {guardian.address.firstName} {guardian.address.lastName}
                  </Link>
                </List.Item>
              )
            })}
          </List>
        </>
      )}
      {guardians && guardians.length === 0 && (
        <>
          <Title level={1}>{t({ message: 'Keine Eltern-Daten gefunden' })}</Title>
          <Paragraph>
            {t({
              message:
                'Wir haben zu Ihren Login-Daten leider keine Eltern-Informationen gefunden. Vielleicht haben Sie Ihr Kind mit einer anderen E-Mail-Adresse angemeldet oder Ihr Vertrag wurde noch nicht durch die Institution bestätigt.',
            })}
          </Paragraph>
          <AppStores />
        </>
      )}
    </Content>
  )
}
