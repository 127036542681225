import { t } from '@lingui/macro'
import { Alert, Button, Spin, Typography } from 'antd'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { isEmailVerified, sendVerificationEmail, verifyEmail } from 'supertokens-web-js/recipe/emailverification'
import FormPage from '../../components/form/form-page'
import AuthLayoutPage from './auth-layout-page'

const { Paragraph } = Typography

const VerifyEmail = () => {
  const [searchParams] = useSearchParams()
  const [resending, setResending] = useState(false)
  const [status, setStatus] = useState<
    'OK' | 'EMAIL_ALREADY_VERIFIED_ERROR' | 'EMAIL_VERIFICATION_INVALID_TOKEN_ERROR' | undefined
  >()
  const [verificationRunning, setVerificationRunning] = useState(false)
  const navigate = useNavigate()
  const handleResend = async () => {
    setResending(true)
    try {
      const response = await sendVerificationEmail()
      setStatus(response.status)
    } finally {
      setResending(false)
    }
  }

  useEffect(() => {
    const token = searchParams.get('token')
    if (token) {
      setVerificationRunning(true)
      verifyEmail()
        .then((response) => {
          if (response.status === 'OK') {
            navigate('/')
          } else {
            setStatus(response.status)
          }
        })
        .finally(() => {
          setVerificationRunning(false)
        })
    }
  }, [searchParams, navigate])

  useEffect(() => {
    const visibilitychangeListener = async () => {
      if (document.visibilityState === 'visible') {
        const verifiedState = await isEmailVerified()
        if (verifiedState.isVerified) {
          navigate('/')
        }
      }
    }
    window.addEventListener('visibilitychange', visibilitychangeListener)
    return () => {
      window.removeEventListener('visibilitychange', visibilitychangeListener)
    }
  }, [navigate])

  return (
    <AuthLayoutPage>
      <FormPage title={t({ message: `E-Mail-Adresse verifizieren` })}>
        <>
          {verificationRunning ? (
            <Spin />
          ) : (
            <>
              {status === 'OK' && (
                <Alert message={t({ message: `E-Mail gesendet` })} type="info" style={{ marginBlock: '1em' }} />
              )}
              {status === 'EMAIL_ALREADY_VERIFIED_ERROR' && (
                <Alert
                  message={t({ message: `E-Mail bereits verifiziert` })}
                  type="warning"
                  style={{ marginBlock: '1em' }}
                />
              )}
              {status === 'EMAIL_VERIFICATION_INVALID_TOKEN_ERROR' && (
                <Alert
                  message={t({ message: `Ungültiges Verifizierungs-Token oder bereits verifiziert.` })}
                  type="info"
                />
              )}
              <Paragraph>
                {t({
                  message: `Bitte klicken Sie auf den Link im E-Mail, das wir Ihnen gerade geschickt haben um Ihnen E-Mail-Adresse zu bestätigen.`,
                })}
              </Paragraph>

              <Button type="default" loading={resending} onClick={handleResend}>
                {t({ message: `E-Mail erneut senden` })}
              </Button>
            </>
          )}
        </>
      </FormPage>
    </AuthLayoutPage>
  )
}

export default VerifyEmail
