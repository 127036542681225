import FormPage from '../../../../components/form/form-page'

import { Typography } from 'antd'
import { t } from '@lingui/macro'

const { Title, Paragraph } = Typography
export const HolidayCareBookingConfirmation = (props: {}) => {
  return (
    <FormPage title={t({ message: `Ferienbetreuung etc.` })}>
      <>
        <Title level={3}>{t({ message: 'Buchungsanfrage erfolgreich versendet' })}</Title>
        <Paragraph>
          {t({
            message:
              'Bitte beachten Sie, dass die Buchung zuerst durch uns bestätigt werden muss. Wir halten Sie per E-Mail auf dem Laufenden. Den Status der Buchung sehen Sie auch über das Menü Zusatzbuchungen in dieser App.',
          })}
        </Paragraph>
      </>
    </FormPage>
  )
}
