import { RegistrationConfiguration } from '../../types'
import GuardianFormFields, { NewGuardian } from './guardian-form-fields'

type Props = {
  handleSubmit: (data: any) => Promise<void>
  defaultValues?: NewGuardian
  handlePrevious?: () => void
  isSecondParent: boolean
  submitButtonText?: string
  familyId: string
  guardianBluePrint?: NewGuardian
  isRegistration?: boolean
  registrationConfiguration: RegistrationConfiguration
}

const GuardianForm2 = ({
  handleSubmit,
  familyId,
  isSecondParent,
  handlePrevious,
  submitButtonText,
  defaultValues,
  guardianBluePrint,
  isRegistration = false,
  registrationConfiguration,
}: Props) => (
  <GuardianFormFields
    familyId={familyId}
    handleSubmit={handleSubmit}
    isSecondParent={isSecondParent}
    handlePrevious={handlePrevious}
    submitButtonText={submitButtonText}
    defaultValues={defaultValues}
    guardianBluePrint={guardianBluePrint}
    isRegistration={isRegistration}
    registrationConfiguration={registrationConfiguration}
  />
)

export default GuardianForm2
