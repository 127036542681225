import { Capacitor } from '@capacitor/core'
import { Contract, Institution, SignatureData } from '../types'
import { App } from '@capacitor/app'

export const addNewContract = async (
  institution: Institution,
  familyId: string,
  contractData: Omit<Contract, 'createdAt' | 'state'>,
  signatureData: SignatureData
) => {
  const body = JSON.stringify({ familyId: familyId, contract: contractData, signatureData })
  const platform = Capacitor.getPlatform()
  const build = platform === 'web' ? 'web' : (await App.getInfo()).build
  await fetch(`${process.env.REACT_APP_LEOBA_SERVER}/api/eltern-app/${institution.id}/guardian/new-contract`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'X-Platform': platform,
      'X-Build': build,
    },
    body,
  })
}
