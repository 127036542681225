import { Content } from 'antd/es/layout/layout'
import { Institution } from '../../types'
import { Card } from 'antd'

type Props = {
  children: JSX.Element | JSX.Element[]
  institution?: Institution
}
const AuthLayoutPage = ({ children }: Props) => {
  return (
    <Content style={{ padding: '1em', maxWidth: '35em', marginInline: 'auto' }}>
      <Card>
        <div className="site-layout-content">
          <>{children}</>
        </div>
      </Card>
    </Content>
  )
}

export default AuthLayoutPage
