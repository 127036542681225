import { t } from '@lingui/macro'
import { MenuSelection } from '../types'

const translateMenuSelection = (menuSelection: MenuSelection) => {
  let result
  switch (menuSelection) {
    case 'noPreference':
      result = t({ message: 'Normales Menü' })
      break
    case 'withoutPork':
      result = t({ message: 'Kein Schweinefleisch' })
      break
    case 'vegetarian':
      result = t({ message: 'Vegetarisch' })
      break
    case 'porridge':
      result = t({ message: 'Breinahrung' })
      break
  }
  return result
}

export default translateMenuSelection
