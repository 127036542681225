import { Card, Typography } from 'antd'
import formatChf from '../../../helper/format-chf'
import weekdays from '../../../lib/weekdays'
import { AgeGroup, Module, Location } from '../../../types'
import { t } from '@lingui/macro'
const { Paragraph, Text } = Typography

type Props = {
  module: Module
  location?: Location
  ageGroup?: AgeGroup
  displayPrice: boolean
}

const ModuleCard = ({ module, location, ageGroup, displayPrice }: Props) => (
  <label htmlFor={module.id}>
    <Card
      size="small"
      title={
        <div
          style={{
            whiteSpace: 'pre-wrap',
          }}
        >
          {module.name}
        </div>
      }
      style={{ marginBottom: 8 }}
    >
      <Paragraph>
        {weekdays.find((weekday) => weekday.name === module.weekday)
          ? `${weekdays.find((weekday) => weekday.name === module.weekday)?.title || ' - '}, `
          : ''}
        {module.startTime} - {module.endTime}
      </Paragraph>
      {!!module.description && <Paragraph>{module.description}</Paragraph>}
      {location && <Paragraph>{location.name}</Paragraph>}
      {ageGroup && <Paragraph>{ageGroup.name}</Paragraph>}
      {displayPrice && (
        <>
          {!module.priceInclVat.isZero() && module.priceMonthlyInclVat.isZero() && (
            <Text>
              {t({ message: `Preis pro Besuch: ` })}
              {formatChf(module.priceInclVat.toNumber())}
            </Text>
          )}
          {!module.priceMonthlyInclVat.isZero() && (
            <Text>
              {t({ message: `Preis pro Monat: ` })}
              {formatChf(module.priceMonthlyInclVat.toNumber())}
            </Text>
          )}
        </>
      )}
    </Card>
  </label>
)

export default ModuleCard
