import create from 'zustand'
import { Child, Family, Guardian, Institution, TaxInformation, User } from '../../../types'
import { loadFamily } from './load-family'
import { registerForPushNotifications } from './register-notifications'
import { loadTaxInformation } from './tax-information'

export interface GuardianContext {
  user: User | undefined
  guardians: (Guardian & { institutionId: string })[] | undefined
  institutions: Institution[]
  guardian: (Guardian & { institutionId: string }) | undefined
  family: Family | undefined
  currentChild: Child | undefined
  newMessageId: string | undefined
  taxInformation: TaxInformation | undefined
  setGuardian: (guardian: Guardian & { institutionId: string }) => void
  fetchFamily: () => Promise<void>
  setCurrentChild: (child: Child) => void
  setUser: (user: User) => void
  setNewMessageId: (messageId: string) => void
  fetchTaxInformation: () => Promise<void>
}

export const useGuardianContext = create<GuardianContext>()((set, get) => ({
  user: undefined,
  guardians: undefined,
  institutions: [],
  guardian: undefined,
  family: undefined,
  currentChild: undefined,
  newMessageId: undefined,
  taxInformation: undefined,
  setGuardian: (guardian: Guardian & { institutionId: string }) => {
    set({ guardian, family: undefined, currentChild: undefined })
  },
  fetchFamily: async () => {
    const oldCurrentChildId = get().currentChild?.id
    const guardian = get().guardian
    if (guardian) {
      const fetchedFamily = await loadFamily(guardian)
      const newCurrentChild =
        fetchedFamily.children.find((child) => child.id === oldCurrentChildId) ||
        (fetchedFamily.children.length > 0 ? fetchedFamily.children[0] : undefined)
      set({ family: fetchedFamily, currentChild: newCurrentChild })
    }
  },
  setCurrentChild: (child: Child) => {
    set({ currentChild: child })
  },
  setNewMessageId: (messageId: string) => {
    set({ newMessageId: messageId })
  },
  setUser: (user: User) => {
    set({ user })
  },
  fetchTaxInformation: async () => {
    const guardian = get().guardian
    if (guardian) {
      const taxInformation = await loadTaxInformation(guardian)
      set({ taxInformation })
    }
  },
}))

useGuardianContext.subscribe((state, prevState) => {
  if (
    state.guardian !== undefined &&
    state.family !== undefined &&
    (state.guardian.id !== prevState.guardian?.id || state.family.id !== prevState.family?.id)
  ) {
    registerForPushNotifications({ ...state, guardian: state.guardian, family: state.family })
  }
})
