import { FilePdfFilled, LoadingOutlined } from '@ant-design/icons'
import { FileOpener } from '@capacitor-community/file-opener'
import { Capacitor } from '@capacitor/core'
import { Directory, Filesystem } from '@capacitor/filesystem'
import { t } from '@lingui/macro'
import { Button } from 'antd'
import React, { useState } from 'react'

const TaxCertificateDownloadLink = ({
  familyId,
  institutionId,
  year,
}: {
  familyId: string
  year: number
  institutionId: string
}) => {
  const [downloading, setDownloading] = useState(false)
  const filename = t({ message: 'Steuerbescheinigung' }) + `_${year}.pdf`
  const downloadTaxCertificate = async () => {
    setDownloading(true)
    try {
      const response = await fetch(
        `${process.env.REACT_APP_LEOBA_SERVER}/api/common/${institutionId}/tax-certificate-download?familyId=${familyId}&year=${year}`,
        {
          headers: { 'Content-Type': 'application/pdf' },
        }
      )
      const taxCertification = await response.blob()
      return taxCertification
    } finally {
      setDownloading(false)
    }
  }

  const handleClickOnDownload = async () => {
    const blob = await downloadTaxCertificate()
    if (Capacitor.isNativePlatform()) {
      openPdfOnNativePlatform(blob, filename)
    } else {
      const certificateUrl = URL.createObjectURL(blob)
      window.open(certificateUrl, '_blank')
    }
  }

  return (
    <React.Fragment>
      <Button type="link" onClick={handleClickOnDownload}>
        {downloading ? <LoadingOutlined /> : <FilePdfFilled />} <u>{filename}</u>
      </Button>
    </React.Fragment>
  )
}

export default TaxCertificateDownloadLink

const openPdfOnNativePlatform = (contractBlob: Blob, filename: string) => {
  const reader = new FileReader()
  reader.onloadend = async function () {
    const base64data = reader.result as string
    await Filesystem.writeFile({
      path: filename,
      data: base64data,
      directory: Directory.Cache,
    })
    const getUriResult = await Filesystem.getUri({
      directory: Directory.Cache,
      path: filename,
    })
    const path = getUriResult.uri
    FileOpener.open({ filePath: path, contentType: 'application/pdf' })
  }
  reader.readAsDataURL(contractBlob)
}
