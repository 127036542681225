import create from 'zustand'
import { HolidayCare, Institution, RegistrationConfiguration, School } from '../../types'

interface InstitutionContext {
  institution?: Institution & {
    registrationConfig: RegistrationConfiguration
    schools: School[]
    holidayCare: HolidayCare[]
  }
  setInstitution: (
    institution: Institution & {
      registrationConfig: RegistrationConfiguration
      schools: School[]
      holidayCare: HolidayCare[]
    }
  ) => void
}

export const useInstitutionContext = create<InstitutionContext>()((set) => ({
  institution: undefined,
  setInstitution: (
    institution: Institution & {
      registrationConfig: RegistrationConfiguration
      schools: School[]
      holidayCare: HolidayCare[]
    }
  ) => set((state) => ({ institution })),
}))
