import { useNavigate } from 'react-router-dom'
import { NewContractRouter } from '../next-school-year/new-contract-router'
import { useInstitutionContext } from '../../institution-context'

export const ChangeContract = () => {
  const navigate = useNavigate()
  const { institution } = useInstitutionContext()
  return (
    <>
      <NewContractRouter
        endDate={institution?.registrationConfig.schoolYearEndDate}
        onFinish={() => {
          navigate('../family')
        }}
      />
    </>
  )
}
