import { Card, Col, Divider, Row, Space, Typography } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import weekdays from '../../../../lib/weekdays'
import { ModuleChoice } from './module-form-no-school'
import SelectableModuleCard from './selectable-module-card'
import { Institution, ModulePrice } from '../../../../types'
const { Title } = Typography

type Props = {
  ageGroupId?: string
  moduleChoices: ModuleChoice[]
  onModuleChange: (e: CheckboxChangeEvent) => void
  institution: Institution
  prices: Array<{ moduleId: string; price: ModulePrice }>
}

const ModuleAgeGroupWeekForm = ({ moduleChoices, onModuleChange, ageGroupId, institution, prices }: Props) => {
  const availableModules = moduleChoices.filter(
    (mc) => ageGroupId !== undefined && mc.ageGroups.some((ag) => ag.ageGroupId === ageGroupId)
  )
  const moduleChoicesByWeekday = (weekday: string, moduleChoice: ModuleChoice[]): ModuleChoice[] =>
    moduleChoice.filter((module) => module.weekday === weekday)
  return (
    <Card style={{ minWidth: 'fit-content', maxWidth: '100%' }}>
      <Row gutter={16}>
        {weekdays.filter(hasModules(availableModules)).map((weekday) => (
          <Col flex={1} className="weekday" key={weekday.name}>
            <Row
              style={{
                height: '100%',
                flexWrap: 'nowrap',
                paddingBlock: '0.5em',
              }}
            >
              <Divider type="vertical" className="divider" />
              <Space
                direction="vertical"
                key={weekday.name}
                style={{
                  flex: 'auto',
                  flexWrap: 'nowrap',
                  height: '100%',
                  paddingBlock: '0.5em',
                }}
              >
                <Title level={3}>{weekday.title}</Title>
                {moduleChoicesByWeekday(weekday.name, availableModules)
                  .sort(
                    (a, b) =>
                      +a.startTime.split(':')[0] - +b.startTime.split(':')[0] ||
                      +a.startTime.split(':')[1] - +b.startTime.split(':')[1] ||
                      a.name.localeCompare(b.name)
                  )
                  .map((module, i) => (
                    <SelectableModuleCard
                      module={module}
                      key={module.id}
                      onChange={onModuleChange}
                      institution={institution}
                      price={prices.find((modulePrice) => modulePrice.moduleId === module.id)?.price ?? null}
                    />
                  ))}
              </Space>
            </Row>
          </Col>
        ))}
      </Row>
    </Card>
  )
}

export default ModuleAgeGroupWeekForm

const hasModules =
  (moduleChoices: ModuleChoice[]) =>
  (weekday: { title: string; name: string }): boolean => {
    return moduleChoices.some((mc) => mc.weekday === weekday.name && mc.id)
  }
