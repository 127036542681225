import { DeleteOutlined } from '@ant-design/icons'
import { t } from '@lingui/macro'
import { Button, Input, Select, Typography } from 'antd'
import { nanoid } from 'nanoid'
import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useInstitutionContext } from '../../routes/institution/institution-context'
import { Guardian, Institution, RegistrationConfiguration } from '../../types'
import ButtonSection from '../form/button-section'
import { Field } from '../form/field'
import PhoneNumberInput from '../form/phone-number-input'
import SingleLineTextInput from '../form/single-line-text-input'
import YesNoRadioButtons from '../form/yes-no-radio-buttons'
import { LocalizedDatePicker } from '../localized-date-picker'
const { Text } = Typography

type Props = {
  handleSubmit: (data: NewGuardian) => Promise<void>
  handlePrevious?: () => void
  isSecondParent: boolean
  submitButtonText?: string
  familyId: string
  defaultValues?: NewGuardian
  soleCustodyLockedIn?: boolean
  isRegistration?: boolean
  guardianBluePrint?: NewGuardian
  registrationConfiguration: RegistrationConfiguration
  institutionFeatures?: Institution['features']
}

export type NewGuardian = Guardian & {
  soleCustodyPdf: string | null
  soleCustodyPdfName: string | null
}

const GuardianFormFields = ({
  familyId,
  handleSubmit,
  isSecondParent,
  handlePrevious,
  submitButtonText,
  defaultValues,
  soleCustodyLockedIn,
  isRegistration = false,
  guardianBluePrint,
  registrationConfiguration,
  institutionFeatures,
}: Props) => {
  const emptyDefaultValues = {
    familyId: undefined,
    sex: undefined,
    dateOfBirth: undefined,
    address: {
      email: '',
      organisation: '',
      lastName: guardianBluePrint?.address.lastName || undefined,
      street: guardianBluePrint?.address.street || undefined,
      zip: guardianBluePrint?.address.zip || undefined,
      city: guardianBluePrint?.address.city || undefined,
    },
    phone: undefined,
    maritalStatus: undefined,
    soleCustody: undefined,
    occupation: undefined,
    employer: undefined,
  }

  const {
    handleSubmit: formHandleSubmit,
    setValue,
    control,
    formState: { errors },
    register,
    watch,
    trigger,
  } = useForm<Omit<NewGuardian, 'id' | 'address.id' | 'familyId' | 'address.familyId'>>({
    defaultValues: defaultValues || emptyDefaultValues,
  })
  const { institution } = useInstitutionContext()

  const soleCustody = watch('soleCustody')
  const soleCustodyPdfName = watch('soleCustodyPdfName')

  const handleOnChangeSoleCustodyPdf = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target?.files) {
      const files = event.target.files
      if (files.length === 0) {
        setValue('soleCustodyPdfName', null)
        setValue('soleCustodyPdf', null)
      } else {
        const file = files[0]
        setValue('soleCustodyPdfName', file.name)
        const arrayBuffer = await file.arrayBuffer()
        var blob = new Blob([arrayBuffer])
        const reader = new FileReader()
        reader.onload = function (event) {
          var base64 = event.target?.result
          if (typeof base64 === 'string') {
            setValue('soleCustodyPdf', base64.split(',')[1])
          }
        }
        reader.readAsDataURL(blob)
      }
    }
    trigger('soleCustody')
    trigger('soleCustodyPdf')
  }

  const handleDeleteSoleCustodyPdf = () => {
    setValue('soleCustodyPdf', null)
    setValue('soleCustodyPdfName', null)
    trigger('soleCustody')
    trigger('soleCustodyPdf')
  }

  const validateGuardianEmail = async (value: string): Promise<true | string> => {
    const errorMessage = t({
      message:
        'Die E-Mail-Adresse existiert bereits. Bitte verwenden Sie eine andere E-Mail-Adresse oder nutzen Sie die Eltern-App um Ihr Kind anzumelden.',
    })
    if (value && value.length > 0) {
      if (institution) {
        const isUnique = await isEmailUniqueInInstitution(value, institution)
        if (!isUnique) {
          return errorMessage
        }
      } else {
        return 'No institution available'
      }
    }
    return true
  }

  const onSubmitHandler = formHandleSubmit(async (values) => {
    const submitValues = {
      ...values,
      id: defaultValues?.id || nanoid(),
      familyId,
      address: { ...values.address, id: defaultValues?.address.id || nanoid(), familyId },
    }
    await handleSubmit(submitValues)
  })

  useEffect(() => {
    if (isSecondParent) {
      setValue('soleCustody', false)
    }
  }, [isSecondParent, setValue])

  const disableAddress = !!institutionFeatures?.innosolvInvoiceExport && !!defaultValues?.address?.accountingDebitNumber

  return (
    <>
      <Field label={t({ message: 'Vorname' }) + ' *'} error={errors?.address?.firstName}>
        <SingleLineTextInput
          name="address.firstName"
          required={true}
          control={control}
          maxLength={200}
          autocomplete="given-name"
        />
      </Field>

      <Field label={t({ message: 'Nachname' }) + ' *'} error={errors?.address?.lastName}>
        <SingleLineTextInput
          name="address.lastName"
          required={true}
          control={control}
          maxLength={200}
          autocomplete="family-name"
        />
      </Field>

      <Field label={t({ message: 'Geschlecht' })} error={errors?.sex}>
        <Controller
          control={control}
          {...register('sex')}
          render={({ field }) => (
            <Select id="sex" style={{ width: '100%' }} {...field}>
              <Select.Option key={'female'} value={'female'}>
                {t({ message: 'Weiblich' })}
              </Select.Option>
              <Select.Option key={'male'} value={'male'}>
                {t({ message: 'Männlich' })}
              </Select.Option>
              <Select.Option key={'other'} value={'other'}>
                {t({ message: 'Divers' })}
              </Select.Option>
            </Select>
          )}
        />
      </Field>

      {registrationConfiguration.askForGuardianDateOfBirth !== 'off' && (
        <Field
          label={
            t({ message: 'Geburtsdatum' }) +
            (registrationConfiguration.askForGuardianDateOfBirth === 'mandatory' ? ' *' : '')
          }
          error={errors?.dateOfBirth}
        >
          <Controller
            control={control}
            {...register('dateOfBirth')}
            rules={{ required: registrationConfiguration.askForGuardianDateOfBirth === 'mandatory' }}
            render={(props) => (
              <LocalizedDatePicker value={props.field.value} onChange={props.field.onChange} onlyAllow="past" />
            )}
          />
        </Field>
      )}

      <Field label={t({ message: 'Strasse und Hausnummer' }) + ' *'} error={errors?.address?.street}>
        <SingleLineTextInput
          name="address.street"
          required={true}
          control={control}
          maxLength={200}
          autocomplete="street-address"
          disabled={disableAddress}
        />
      </Field>

      <Field label={t({ message: 'PLZ' }) + ' *'} error={errors?.address?.zip}>
        <SingleLineTextInput
          name="address.zip"
          required={true}
          control={control}
          maxLength={32}
          autocomplete="postal-code"
          disabled={disableAddress}
        />
      </Field>

      <Field label={t({ message: 'Ort' }) + ' *'} error={errors?.address?.city}>
        <SingleLineTextInput
          name="address.city"
          required={true}
          control={control}
          maxLength={200}
          autocomplete="address-level2"
          disabled={disableAddress}
        />
      </Field>

      {disableAddress && (
        <Field label="">
          <i>
            {t({
              message:
                'Die Adresse wird von der Gemeinde übernommen. Adressänderungen müssen bei der Gemeinde angemeldet werden.',
            })}
          </i>
        </Field>
      )}

      <Field label={t({ message: 'Mobile Nummer' }) + ' *'} error={errors?.phone}>
        <PhoneNumberInput name="phone" required={true} control={control} />
      </Field>

      {registrationConfiguration.askForGuardianWorkPhoneNumber !== 'off' && (
        <Field
          label={
            t({ message: 'Telefon Arbeit' }) +
            (registrationConfiguration.askForGuardianWorkPhoneNumber === 'mandatory' ? ' *' : '')
          }
          error={errors?.phoneWork}
        >
          <PhoneNumberInput
            name="phoneWork"
            required={registrationConfiguration.askForGuardianWorkPhoneNumber === 'mandatory'}
            control={control}
          />
        </Field>
      )}

      <Field label={t({ message: 'Email' }) + ' *'} error={errors?.address?.email}>
        <SingleLineTextInput
          disabled={!isRegistration}
          name="address.email"
          required={isRegistration}
          control={control}
          type="email"
          autocomplete="email"
          validate={validateGuardianEmail}
          maxLength={200}
        />
      </Field>

      {registrationConfiguration.askForGuardianMaritalStatus !== 'off' && (
        <Field
          label={
            t({ message: 'Zivilstand' }) +
            (registrationConfiguration.askForGuardianMaritalStatus === 'mandatory' ? ' *' : '')
          }
          error={errors?.sex}
        >
          <Controller
            control={control}
            {...register('maritalStatus')}
            rules={{ required: registrationConfiguration.askForGuardianMaritalStatus === 'mandatory' }}
            render={({ field }) => (
              <Select id="maritalStatus" style={{ width: '100%' }} {...field}>
                <Select.Option key={'married'} value={'married'}>
                  {t({ message: 'Verheiratet' })}
                </Select.Option>
                <Select.Option key={'divorced'} value={'divorced'}>
                  {t({ message: 'Geschieden' })}
                </Select.Option>
                <Select.Option key={'widowed'} value={'widowed'}>
                  {t({ message: 'Verwitwet' })}
                </Select.Option>
                <Select.Option key={'single'} value={'single'}>
                  {t({ message: 'Ledig' })}
                </Select.Option>
                <Select.Option key={'concubinage'} value={'concubinage'}>
                  {t({ message: 'Konkubinat' })}
                </Select.Option>
                <Select.Option key={'other'} value={'other'}>
                  {t({ message: 'Sonstiges' })}
                </Select.Option>
              </Select>
            )}
          />
        </Field>
      )}
      {!isSecondParent && (
        <>
          <Field label={t({ message: 'Alleiniges Sorgerecht' }) + ' *'} error={errors?.soleCustody}>
            <YesNoRadioButtons
              control={control}
              register={register}
              name="soleCustody"
              disabled={soleCustodyLockedIn}
              required
            />
          </Field>

          {soleCustody && (
            <Field
              label={t({ message: 'Bescheid über alleiniges Sorgerecht als PDF' }) + ' *'}
              error={errors?.soleCustodyPdf}
            >
              {soleCustodyPdfName ? (
                <Text>
                  <Text>{soleCustodyPdfName} </Text>
                  <Button onClick={handleDeleteSoleCustodyPdf} disabled={soleCustodyLockedIn}>
                    <DeleteOutlined alt={t({ message: 'Löschen' })} />
                  </Button>
                </Text>
              ) : (
                <Controller
                  {...register('soleCustodyPdf')}
                  control={control}
                  rules={{ required: true }}
                  render={() => (
                    <Input
                      id="soleCustodyPdf"
                      disabled={soleCustodyLockedIn}
                      type="file"
                      accept=".pdf"
                      required={true}
                      onChange={handleOnChangeSoleCustodyPdf}
                    />
                  )}
                />
              )}
            </Field>
          )}
        </>
      )}

      {registrationConfiguration.askForGuardianOccupation !== 'off' && (
        <Field
          label={
            t({ message: 'Beruf' }) + (registrationConfiguration.askForGuardianOccupation === 'mandatory' ? ' *' : '')
          }
          error={errors?.occupation}
        >
          <SingleLineTextInput
            name="occupation"
            control={control}
            maxLength={100}
            required={registrationConfiguration.askForGuardianOccupation === 'mandatory'}
          />
        </Field>
      )}

      {registrationConfiguration.askForGuardianEmployer !== 'off' && (
        <Field
          label={
            t({ message: 'Arbeitgeber' }) +
            (registrationConfiguration.askForGuardianEmployer === 'mandatory' ? ' *' : '')
          }
          error={errors?.employer}
        >
          <SingleLineTextInput
            name="employer"
            control={control}
            maxLength={100}
            required={registrationConfiguration.askForGuardianEmployer === 'mandatory'}
          />
        </Field>
      )}
      <ButtonSection
        submitButtonText={submitButtonText}
        onNext={onSubmitHandler}
        onPrevious={handlePrevious && handlePrevious}
      />
    </>
  )
}

export default GuardianFormFields

const isEmailUniqueInInstitution = async (value: string, institution: Institution) => {
  if (institution) {
    const response = await fetch(
      `${process.env.REACT_APP_LEOBA_SERVER}/api/eltern-app/${
        institution.id
      }/validation/email?email=${encodeURIComponent(value)}&institutionId=${institution.id}`,
      {
        method: 'GET',
        headers: { accept: 'application/json' },
      }
    )
    const alreadyExists = await response.json()
    if (response.ok) {
      if (alreadyExists === true) {
        return false
      } else {
        return true
      }
    } else {
      return false
    }
  } else {
    return false
  }
}
