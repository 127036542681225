import { t } from '@lingui/macro'
import { Checkbox, Row, Select } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox/Checkbox'
import dayjs from 'dayjs'
import { useEffect, useMemo, useState } from 'react'
import { moduleCompareString } from '../../../../lib/module-compare-string'
import { useInstitutionContext } from '../../../../routes/institution/institution-context'
import { ModuleBooking } from '../../../../routes/institution/registration/registration-context'
import { AgeGroup, Location, Module, ModuleBooking as DomainModuleBooking } from '../../../../types'
import ErrorMessage from '../../../error-message'
import ButtonSection from '../../../form/button-section'
import { Field } from '../../../form/field'
import { LocalizedDatePicker } from '../../../localized-date-picker'
import ModuleAgeGroupWeekForm from './module-age-group-week-form'
import NewModulesTotalPrice from './new-modules-total-price'
import { guessAgeGroup } from './guess-age-gruop'
import { useModulePrices } from './use-module-prices'

type Props = {
  handleSubmit: (data: ModuleBooking) => Promise<void>
  handlePrevious?: (data: ModuleBooking) => void
  defaultValues?: ModuleBooking
  submitButtonText?: string
  modulesToExclude?: DomainModuleBooking[]
}

export type ModuleChoice = Module & { chosen: boolean; ageGroupId?: string; location: Location }

const ModuleFormNoSchool = ({
  handleSubmit,
  handlePrevious,
  defaultValues,
  submitButtonText,
  modulesToExclude,
}: Props) => {
  const { institution } = useInstitutionContext()
  const locations = institution ? institution.locations : []
  const [location, setLocation] = useState<Location | undefined>(
    locations.find((l) => l.id === defaultValues?.locationId)
  )
  const [ageGroup, setAgeGroup] = useState<AgeGroup | undefined>(defaultValues?.ageGroup)
  const [moduleChoices, setModuleChoices] = useState<ModuleChoice[]>(
    createModuleChoices(location, ageGroup, defaultValues?.bookings || [], modulesToExclude)
  )
  const [startDate, setStartDate] = useState<Date | undefined>(
    defaultValues?.startDate ? defaultValues?.startDate : undefined
  )

  const [adjustmentPeriodStartDate, setAdjustmentPeriodStartDate] = useState<Date | undefined>(
    defaultValues?.adjustmentPeriodStartDate ? defaultValues?.adjustmentPeriodStartDate : undefined
  )
  useEffect(() => {
    if (!location) {
      setAgeGroup(undefined)
      setStartDate(undefined)
    } else {
      if (startDate === undefined) {
        if (location.earliestSignUpDate && dayjs(location.earliestSignUpDate).isAfter(Date.now())) {
          setStartDate(location.earliestSignUpDate)
        }
      } else if (location.earliestSignUpDate && dayjs(location.earliestSignUpDate).isAfter(startDate)) {
        setStartDate(location.earliestSignUpDate)
      }
    }
  }, [location, setAgeGroup, startDate])

  useEffect(() => {
    setModuleChoices(createModuleChoices(location, ageGroup, defaultValues?.bookings || [], modulesToExclude))
  }, [ageGroup, location, defaultValues?.bookings, modulesToExclude])

  const prices = useModulePrices({
    institutionId: institution!.id,
    locationId: location?.id ?? '',
    ageGroupId: ageGroup?.id ?? '',
    selection: useMemo(
      () =>
        moduleChoices.map((choice) => ({
          moduleId: choice.id,
          ageGroupId: choice.ageGroupId ?? ageGroup?.id ?? '',
        })),
      [moduleChoices, ageGroup?.id]
    ),
  })

  const [flexibleBooking, setFlexibleBooking] = useState<boolean>(!!defaultValues?.flexibleBooking)
  const [validationError, setValidationError] = useState<string | undefined>(undefined)

  const handleOnChangeLocation = (locationId: string) => {
    const newLocation = locations.find((loc) => loc.id === locationId)
    setLocation(newLocation)
  }

  const handleOnChangeStartDate = (date: string) => {
    setStartDate(dayjs(date).toDate())
  }

  const handleOnChangeAdjustmentPeriodStartDate = (date: string) => {
    setAdjustmentPeriodStartDate(dayjs(date).toDate())
  }

  const handleOnChangeAgeGroup = (ageGroupId: string) => {
    const ageGroups = location?.ageGroups || []
    const selectedAgeGroup = ageGroups.find((ageGroup) => ageGroup.id === ageGroupId)
    setAgeGroup(selectedAgeGroup)
  }

  const handleModuleChange = (e: CheckboxChangeEvent) => {
    if (validationError) {
      setValidationError(undefined)
    }
    const currentChoices = [...moduleChoices]
    const currentModule = currentChoices.find((choice) => choice.id === e.target.id)
    if (currentModule) {
      currentModule.chosen = e.target.checked
      setModuleChoices(currentChoices)
    }
  }
  const handleFlexibleBookingChange = () => {
    if (validationError) {
      setValidationError(undefined)
    }
    setFlexibleBooking(!flexibleBooking)
  }

  const handleModuleSubmit = async () => {
    const chosenModules = moduleChoices.filter((choice) => choice.chosen && choice.ageGroupId !== undefined)
    if (!startDate) {
      setValidationError(t({ message: 'Bitte Startdatum wählen.' }))
    } else if (!location) {
      setValidationError(t({ message: 'Bitte Standort wählen.' }))
    } else if (
      !location &&
      institution?.registrationConfig.askForAdjustmentPeriodStartDate === 'mandatory' &&
      !adjustmentPeriodStartDate
    ) {
      setValidationError(t({ message: 'Bitte Startdatum der Eingewöhnung wählen.' }))
    } else if (!ageGroup) {
      setValidationError(t({ message: 'Bitte Altersgruppe wählen.' }))
    } else if (chosenModules.length && flexibleBooking) {
      setValidationError(t({ message: 'Bei einer flexiblen Buchung dürfen keine Module ausgewählt sein.' }))
    } else if (!chosenModules.length && !flexibleBooking) {
      setValidationError(t({ message: 'Bitte mindestens ein Modul oder flexible Buchung wählen.' }))
    } else {
      const moduleBooking: ModuleBooking = {
        startDate,
        adjustmentPeriodStartDate,
        locationId: location.id || guessLocation(locations, chosenModules).id,
        ageGroup: ageGroup || guessAgeGroup(locations, chosenModules),
        flexibleBooking,
        bookings: chosenModules.map((mod) => ({ moduleId: mod.id, ageGroupId: mod.ageGroupId! })),
      }
      await handleSubmit(moduleBooking)
    }
  }
  const moveBack = () => {
    if (handlePrevious) {
      const chosenModules = moduleChoices.filter((choice) => choice.chosen && choice.ageGroupId !== undefined)
      const moduleBooking: ModuleBooking = {
        locationId: guessLocation(locations, chosenModules).id,
        ageGroup,
        flexibleBooking,
        bookings: chosenModules.map((mod) => ({ moduleId: mod.id, ageGroupId: mod.ageGroupId! })),
      }
      handlePrevious(moduleBooking)
    }
  }

  return (
    <>
      {
        <Field label={t({ message: 'Standort' })}>
          <Select
            id="location"
            style={{ width: '100%' }}
            onChange={handleOnChangeLocation}
            defaultValue={location?.id}
            options={locations.map((location) => ({ value: location.id, label: location.name }))}
          />
        </Field>
      }

      {location && (
        <Field label={t({ message: 'Startdatum der Betreuung' })}>
          <LocalizedDatePicker
            futureStartDate={location?.earliestSignUpDate && dayjs(location?.earliestSignUpDate)}
            value={startDate}
            onChange={handleOnChangeStartDate}
            onlyAllow="future"
          />
        </Field>
      )}

      {location && startDate && institution?.registrationConfig.askForAdjustmentPeriodStartDate !== 'off' && (
        <Field label={t({ message: 'Startdatum der Eingewöhnung' })}>
          <LocalizedDatePicker
            futureStartDate={location?.earliestSignUpDate && dayjs(location?.earliestSignUpDate)}
            value={adjustmentPeriodStartDate}
            max={startDate}
            onChange={handleOnChangeAdjustmentPeriodStartDate}
          />
        </Field>
      )}

      {startDate && location && (
        <Field label={t({ message: 'Altersgruppe' })}>
          <Select
            id="ageGroup"
            style={{ width: '100%' }}
            onChange={handleOnChangeAgeGroup}
            defaultValue={ageGroup?.id}
            options={location?.ageGroups
              .filter((ageGroup) => ageGroup.bookableFromGuardians)
              .map((ageGroup) => ({ value: ageGroup.id, label: ageGroup.name }))}
          />
        </Field>
      )}

      <>
        {location && moduleChoices.length > 0 && startDate && (
          <ModuleAgeGroupWeekForm
            ageGroupId={ageGroup?.id}
            moduleChoices={moduleChoices}
            onModuleChange={handleModuleChange}
            institution={institution!}
            prices={prices}
          />
        )}
      </>

      {location?.enableFlexBooking && (
        <Row style={{ marginBlock: '1em' }}>
          <Checkbox checked={flexibleBooking} onChange={handleFlexibleBookingChange}>
            {t({ message: 'Flexible Buchung' })}
            <br />
            <small>
              (
              {t({
                message: 'Ich buche die Betreuungsmodule nach individuellem Bedarf und Kapazität der Einrichtung.',
              })}
              )
            </small>
          </Checkbox>
        </Row>
      )}

      <NewModulesTotalPrice ageGroupId={ageGroup?.id} taxInformation={undefined} moduleChoices={moduleChoices} />

      <Row style={{ flexDirection: 'row-reverse' }}>
        {!!validationError && <ErrorMessage message={validationError} />}
      </Row>

      <ButtonSection
        submitButtonText={submitButtonText}
        onNext={handleModuleSubmit}
        onPrevious={handlePrevious && moveBack}
      />
    </>
  )
}

const guessLocation = (locations: Location[], chosenModules: Module[]) => {
  const chosenModuleIds = chosenModules.map((mod) => mod.id)
  return locations
    .map((loc) => ({
      location: loc,
      moduleCount: loc.modules.filter((mod) => chosenModuleIds.indexOf(mod.id) >= 0).length,
    }))
    .reduce((acc, curr) => (acc.moduleCount > curr.moduleCount ? acc : curr)).location
}

const createModuleChoices = (
  location: Location | undefined,
  ageGroup: AgeGroup | undefined,
  bookings: { moduleId: string; ageGroupId: string }[],
  modulesToExclude?: DomainModuleBooking[]
): ModuleChoice[] => {
  if (location === undefined) return []
  return location.modules
    .sort((a: Module, b: Module) => moduleCompareString(a).localeCompare(moduleCompareString(b)))
    .filter((module) => module.ageGroups.some((ag) => ag.ageGroupId === ageGroup?.id))
    .map((module) => {
      return {
        ...module,
        location,
        chosen: bookings.some((booking) => booking.moduleId === module.id),
        ageGroupId: ageGroup?.id,
      }
    })
    .filter((module) => {
      if (modulesToExclude) {
        return !modulesToExclude.some((booking) => module.id === booking.moduleId)
      } else {
        return true
      }
    })
}

export default ModuleFormNoSchool
