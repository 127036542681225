import { t } from '@lingui/macro'
import { Select } from 'antd'
import { Controller, useForm } from 'react-hook-form'
import { useRegistrationContext } from '../../../routes/institution/registration/registration-context'
import ButtonSection from '../../form/button-section'
import { Field } from '../../form/field'
import PhoneNumberInput from '../../form/phone-number-input'
import SingleLineTextInput from '../../form/single-line-text-input'

export type NewChildFurtherInformation = {
  contactPreference?: string
  emergencyContactFirstName: string
  emergencyContactLastName: string
  emergencyContactPhone: string
  emergencyContactRemarks?: string
}

type Props = {
  defaultValues?: NewChildFurtherInformation
  submitButtonText?: string
  handleSubmit: (data: NewChildFurtherInformation) => void
  handlePrevious?: () => void
}

const ChildFurtherInformationForm = ({ handleSubmit, submitButtonText, defaultValues, handlePrevious }: Props) => {
  const {
    control,
    register,
    formState: { errors },
    handleSubmit: formHandleSubmit,
  } = useForm<NewChildFurtherInformation>({
    defaultValues,
  })

  const { getGuardian1Data, getGuardian2Data } = useRegistrationContext()
  const guardians = [getGuardian1Data(), getGuardian2Data()].filter((guardian) => !!guardian)

  const onSubmitHandler = formHandleSubmit((values) => {
    handleSubmit(values)
  })

  return (
    <>
      {guardians.length > 1 && (
        <Field label={t({ message: 'Korrespondenz über' }) + ' *'} error={errors.contactPreference}>
          <Controller
            control={control}
            {...register('contactPreference')}
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                id="contactPreference"
                style={{ width: '100%' }}
                {...field}
                options={[
                  ...guardians.map((guardian) => ({
                    value: guardian?.id,
                    label: `${guardian?.address.firstName} ${guardian?.address.lastName}`,
                  })),
                  { value: 'both', label: t({ message: 'Beide' }) },
                ]}
              />
            )}
          />
        </Field>
      )}

      <Field
        label={t({ message: 'Notfallkontakt Vorname' }) + ' *'}
        description={t({
          message:
            'Notfallkontakt soll eine Drittperson sein (weder Mutter noch Vater). Diese wird ausschliesslich notfalls, sofern die Eltern nicht erreichbar sind, kontaktiert.',
        })}
        error={errors?.emergencyContactFirstName}
      >
        <SingleLineTextInput name="emergencyContactFirstName" required={true} control={control} maxLength={50} />
      </Field>

      <Field label={t({ message: 'Notfallkontakt Nachname' }) + ' *'} error={errors?.emergencyContactLastName}>
        <SingleLineTextInput name="emergencyContactLastName" required={true} control={control} maxLength={50} />
      </Field>

      <Field label={t({ message: 'Notfallkontakt Telefon' }) + ' *'} error={errors?.emergencyContactPhone}>
        <PhoneNumberInput name="emergencyContactPhone" required={true} control={control} />
      </Field>

      <Field label={t({ message: 'Notfallkontakt Beziehung zu Kind' })} error={errors?.emergencyContactRemarks}>
        <SingleLineTextInput name="emergencyContactRemarks" required={false} control={control} maxLength={1000} />
      </Field>

      <ButtonSection
        submitButtonText={submitButtonText}
        onNext={onSubmitHandler}
        onPrevious={handlePrevious && handlePrevious}
      />
    </>
  )
}

export default ChildFurtherInformationForm
