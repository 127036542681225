import { t, Trans } from '@lingui/macro'
import { Modal } from 'antd'
import dayjs from 'dayjs'
import { useState } from 'react'
import weekdays from '../../../lib/weekdays'
import { useInstitutionContext } from '../../../routes/institution/institution-context'
import { ModuleBooking } from '../../../routes/institution/registration/registration-context'
import DateField from '../../date-field'
import ModuleCard from './module-card'
import ModuleFormWithSchool from '../child-form/module-form/module-form-with-school'
import TextField from '../../text-field'
import ChildCardSection from './child-card-section'
import ModuleFormNoSchool from '../child-form/module-form/module-form-no-school'

type Props = {
  childId: string
  moduleBooking: ModuleBooking
  onSubmitEditedModules: (information: ModuleBooking, childId: string) => void
  schoolId?: string
}

const ChildCardModulesSection = ({ moduleBooking, onSubmitEditedModules, childId, schoolId }: Props) => {
  const { institution } = useInstitutionContext()
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)

  const showEditModal = () => {
    setIsEditModalOpen(true)
  }

  const handleEditModalOk = async (data: ModuleBooking) => {
    onSubmitEditedModules(data, childId)
    setIsEditModalOpen(false)
  }

  const handleEditModalCancel = () => {
    setIsEditModalOpen(false)
  }

  const handleOnEdit = () => {
    showEditModal()
  }

  const allModules =
    institution?.locations.flatMap((location) => location.modules.map((module) => ({ location, module }))) || []
  const allAgeGroups = institution?.locations.flatMap((location) => location.ageGroups) || []
  const bookedModules = allModules
    .filter((module) => moduleBooking.bookings.some((booking) => booking.moduleId === module.module.id))
    .map((module) => {
      const booking = moduleBooking.bookings.find((booking) => booking.moduleId === module.module.id)!
      return { ...module, ageGroup: allAgeGroups.find((ag) => ag.id === booking.ageGroupId) }
    })
  const bookingLocation = institution?.locations?.find((loc) => loc.id === moduleBooking.locationId)

  return (
    <ChildCardSection onEdit={handleOnEdit} title={t({ message: 'Gewählte Module' })}>
      <>
        <DateField label={t({ message: 'Startdatum der Betreuung' })} value={dayjs(moduleBooking.startDate).toDate()} />
        {bookingLocation ? <TextField label={t({ message: 'Standort' })} value={bookingLocation.name} /> : <></>}
        {schoolId ? <></> : <TextField label={t({ message: 'Gruppe' })} value={moduleBooking.ageGroup?.name} />}
        {moduleBooking.flexibleBooking ? (
          <Trans>Flexible Buchung</Trans>
        ) : (
          bookedModules
            .sort(
              (a, b) =>
                weekdays.findIndex((weekday) => weekday.name === a.module.weekday) -
                  weekdays.findIndex((weekday) => weekday.name === b.module.weekday) ||
                +a.module.startTime.split(':')[0] - +b.module.startTime.split(':')[0] ||
                +a.module.startTime.split(':')[1] - +b.module.startTime.split(':')[1] ||
                a.module.name.localeCompare(b.module.name)
            )
            .map((module) => (
              <ModuleCard
                key={module.module.id}
                module={module.module}
                location={module.location}
                ageGroup={module.ageGroup}
                displayPrice={institution?.features.subsidiesHuenenberg !== true}
              />
            ))
        )}
        {isEditModalOpen && (
          <Modal
            title={t({ message: 'Module bearbeiten' })}
            open={isEditModalOpen}
            onCancel={handleEditModalCancel}
            footer={null}
            width="fit-content"
          >
            {schoolId ? (
              <ModuleFormWithSchool
                schoolId={schoolId}
                defaultValues={moduleBooking}
                handleSubmit={handleEditModalOk}
                submitButtonText={t({ message: 'Speichern' })}
                taxInformation={undefined}
              />
            ) : (
              <ModuleFormNoSchool
                defaultValues={moduleBooking}
                handleSubmit={handleEditModalOk}
                submitButtonText={t({ message: 'Speichern' })}
              />
            )}
          </Modal>
        )}
      </>
    </ChildCardSection>
  )
}

export default ChildCardModulesSection
