import { Route } from 'react-router-dom'
import AuthRouter from './auth/auth-router'
import { DeleteAccount } from './delete-account'
import { GeneralErrorElement } from './general-error-element'
import { GuardianErrorElement } from './institution/guardian/guardian-error-element'
import GuardianRouter from './institution/guardian/guardian-router'
import loadGuardian from './institution/guardian/load-guardian'
import { InstitutionIndexPage } from './institution/institution-index-page'
import { InstitutionPage } from './institution/institution-page'
import loadInstitution from './institution/load-institution'
import { RegistrationErrorPage } from './institution/registration/registration-error-page'
import RegistrationPage from './institution/registration/registration-page'
import WelcomePage from './institution/registration/welcome-page'
import { NoInstitutionPresentPage } from './no-institution-present-page'
import Root from './root'

const Router = () => {
  return (
    <Route path="/" element={<Root />} errorElement={<GeneralErrorElement />}>
      <Route index={true} element={<NoInstitutionPresentPage />} />
      <Route path="/institution-index" element={<InstitutionIndexPage />} />
      <Route path="/:institutionId" loader={loadInstitution} element={<InstitutionPage />}>
        <Route path="registration" element={<WelcomePage />} errorElement={<RegistrationErrorPage />} />
        <Route path="registration/*" element={<RegistrationPage />} errorElement={<RegistrationErrorPage />} />
        <Route path="*" element={<>404</>} />
        <Route
          path="guardian/:guardianId/*"
          loader={loadGuardian}
          element={<GuardianRouter />}
          errorElement={<GuardianErrorElement />}
        />
      </Route>
      <Route path="/delete-account" element={<DeleteAccount />} />
      <Route path="/auth/*" element={<AuthRouter />} />
    </Route>
  )
}

export default Router
