import { Trans } from '@lingui/macro'
import { Typography } from 'antd'
import Decimal from 'decimal.js'
import { useEffect, useState } from 'react'
import { z } from 'zod'
import formatChf from '../../../../helper/format-chf'
import { useInstitutionContext } from '../../../../routes/institution/institution-context'
import { TaxInformation } from '../../../../types'
import style from './new-modules-total-price.module.css'

const { Title, Text } = Typography

type Props = {
  taxInformation: TaxInformation | undefined
  moduleChoices: { id: string; name: string; priceMonthlyInclVat: Decimal; chosen: boolean }[]
  ageGroupId?: string
}

const NewModulesTotalPrice = ({ taxInformation, moduleChoices, ageGroupId }: Props) => {
  const { institution } = useInstitutionContext()
  const [modulesTotalPrice, setModulesTotalPrice] = useState<
    | {
        total: Decimal
        familyCosts: Decimal
        subsidies: Decimal
      }
    | undefined
  >(undefined)

  useEffect(() => {
    const getModulesTotalPriceForHuenenberg = async () => {
      const DecimalSchema = z.preprocess((value) => {
        return new Decimal(value ? (value as string) : '0')
      }, z.instanceof(Decimal))

      const SelectedModulesSchema = z.object({
        totalPrice: z.object({
          total: DecimalSchema,
          familyCosts: DecimalSchema,
          subsidies: DecimalSchema,
        }),
      })

      const body = {
        taxInformation: taxInformation,
        selectedModules: moduleChoices.filter((mod) => mod.chosen).map((mod) => ({ ...mod, moduleId: mod.id })),
        ageGroupId,
      }
      const response = await fetch(
        `${process.env.REACT_APP_LEOBA_SERVER}/api/eltern-app/${institution?.id}/registration/module/get-module-total-price`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
          },
          body: JSON.stringify(body),
        }
      )

      if (response.ok) {
        const parsedResponse = SelectedModulesSchema.parse(await response.json())
        if (parsedResponse.totalPrice.total.toNumber() === 0) {
          setModulesTotalPrice(undefined)
        } else {
          setModulesTotalPrice(parsedResponse.totalPrice)
        }
      } else {
        setModulesTotalPrice(undefined)
      }
    }
    getModulesTotalPriceForHuenenberg()
  }, [ageGroupId, institution?.id, moduleChoices, taxInformation])

  if (!modulesTotalPrice || moduleChoices.filter((choice) => choice.chosen).length === 0) return <></>
  return (
    <div className={style.wrapper}>
      <Title level={3}>
        <Trans>Preis pro Monat</Trans>
      </Title>
      <div className={style.row}>
        <Text>
          <Trans>Kosten</Trans>
        </Text>
        <Text>{formatChf(modulesTotalPrice.total.toNumber())}</Text>
      </div>
      {!modulesTotalPrice.subsidies.isZero() && (
        <div className={style.row}>
          <Text>
            <Trans>Gemeindebeitrag</Trans>
          </Text>
          <Text>{formatChf(modulesTotalPrice.subsidies.toNumber())}</Text>
        </div>
      )}
      {!modulesTotalPrice.familyCosts.isZero() && (
        <div className={style.row}>
          <Text strong>
            <Trans>Kosten Erziehungsberechtigte</Trans>
          </Text>
          <Text>
            <b>{formatChf(modulesTotalPrice.familyCosts.toNumber())}</b>
          </Text>
        </div>
      )}
    </div>
  )
}

export default NewModulesTotalPrice
