import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Modal } from 'antd'
import dayjs from 'dayjs'
import { useState } from 'react'
import translateGeneralHealth from '../../../helper/translate-general-health'
import translateSchoolLevel from '../../../helper/translate-schoolLevel'
import translateSex from '../../../helper/translate-sex'
import localizedCountryList from '../../../lib/localized-country-list'
import { useInstitutionContext } from '../../../routes/institution/institution-context'
import DateField from '../../date-field'
import TextField from '../../text-field'
import YesNoTranslatedField from '../../yes-no-translated-field'
import ChildBaseInformationForm, { NewChildBaseInformation } from '../child-form/child-base-information-form'
import ChildCardSection from './child-card-section'
import translateKindergartenLevel from '../../../helper/translate-kindergarten'
import { RELIGION_NAMES } from '../../../domain/child/religion'
import { LIVES_WITH_NAMES } from '../../../domain/child/lives-with'

type Props = {
  childId: string
  onSubmitEditedBaseInformation: (information: NewChildBaseInformation, childId: string) => void
  newChildBaseInformation: NewChildBaseInformation
}

const ChildCardBaseInformationSection = ({
  newChildBaseInformation,
  onSubmitEditedBaseInformation,
  childId,
}: Props) => {
  const {
    i18n: { locale },
  } = useLingui()

  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const { institution } = useInstitutionContext()
  const countries = localizedCountryList(locale)

  const showEditModal = () => {
    setIsEditModalOpen(true)
  }

  const handleEditModalOk = (data: NewChildBaseInformation) => {
    onSubmitEditedBaseInformation(data, childId)
    setIsEditModalOpen(false)
  }

  const handleEditModalCancel = () => {
    setIsEditModalOpen(false)
  }

  const handleOnEdit = () => {
    showEditModal()
  }

  return (
    <ChildCardSection onEdit={handleOnEdit} title={t({ message: 'Basis Informationen' })}>
      <>
        <TextField label={t({ message: 'Geschlecht' })} value={translateSex(newChildBaseInformation.sex)} />
        <DateField label={t({ message: 'Geburtsdatum' })} value={dayjs(newChildBaseInformation.dateOfBirth).toDate()} />
        {institution?.registrationConfig.askForChildNationality !== 'off' && (
          <TextField
            label={t({ message: 'Nationalität' })}
            value={countries.find(({ value }) => value === newChildBaseInformation.nationality)?.label}
          />
        )}
        {(institution?.registrationConfig.askForFamilyLanguage === 'mandatory' ||
          institution?.registrationConfig.askForFamilyLanguage === 'optional') && (
          <TextField label={t({ message: 'Familiensprache' })} value={newChildBaseInformation.familyLanguage} />
        )}
        {institution?.registrationConfig.askForChildReligion !== 'off' && (
          <TextField
            label={t({ message: 'Konfession' })}
            value={newChildBaseInformation.religion ? RELIGION_NAMES[newChildBaseInformation.religion] : ''}
          />
        )}
        {institution?.registrationConfig.askForChildLivesWith !== 'off' && (
          <TextField
            label={t({ message: 'Wohnhaft bei' })}
            value={newChildBaseInformation.livesWith ? LIVES_WITH_NAMES[newChildBaseInformation.livesWith] : ''}
          />
        )}
        <TextField
          label={t({ message: 'Alter / Schulische Stufe' })}
          value={translateSchoolLevel(newChildBaseInformation.schoolLevel)}
        />

        {newChildBaseInformation.schoolLevel === 'kindergarten' && (
          <>
            <TextField
              label={t({ message: 'Kindergarten' })}
              value={translateKindergartenLevel(newChildBaseInformation.kinderGarten)}
            />
            {newChildBaseInformation.class && (
              <TextField label={t({ message: 'Kindergartengruppe' })} value={newChildBaseInformation.class} />
            )}
          </>
        )}

        {newChildBaseInformation.schoolLevel === 'school' && (
          <>
            {newChildBaseInformation.class && (
              <TextField label={t({ message: 'Schulklasse' })} value={newChildBaseInformation.class} />
            )}
          </>
        )}

        {(newChildBaseInformation.schoolLevel === 'school' ||
          newChildBaseInformation.schoolLevel === 'kindergarten') && (
          <>
            {(institution?.registrationConfig.askForSchoolName === 'mandatory' ||
              institution?.registrationConfig.askForSchoolName === 'optional') && (
              <TextField
                label={t({ message: 'Schulhaus' })}
                value={institution.schools.find((school) => newChildBaseInformation.schoolId === school.id)?.name}
              />
            )}
            {(institution?.registrationConfig.askForPickup === 'mandatory' ||
              institution?.registrationConfig.askForPickup === 'optional') && (
              <YesNoTranslatedField
                label={t({ message: 'Abholdienst erwünscht' })}
                value={newChildBaseInformation.pickUp}
              />
            )}
            {(institution?.registrationConfig.askForTeacherName === 'mandatory' ||
              institution?.registrationConfig.askForTeacherName === 'optional') && (
              <TextField label={t({ message: 'Name Lehrperson' })} value={newChildBaseInformation.teacherName} />
            )}
            {(institution?.registrationConfig.askForTeacherContact === 'mandatory' ||
              institution?.registrationConfig.askForTeacherContact === 'optional') && (
              <TextField label={t({ message: 'Kontakt Lehrperson' })} value={newChildBaseInformation.teacherContact} />
            )}
          </>
        )}

        {(institution?.registrationConfig.askForHealthInsuranceName === 'mandatory' ||
          institution?.registrationConfig.askForHealthInsuranceName === 'optional') && (
          <TextField
            label={t({ message: 'Name Kranken-/Unfallversicherung' })}
            value={newChildBaseInformation.healthInsuranceName}
          />
        )}
        {(institution?.registrationConfig.askForHealthInsuranceNumber === 'mandatory' ||
          institution?.registrationConfig.askForHealthInsuranceNumber === 'optional') && (
          <TextField
            label={t({ message: 'Versichertennummer Kranken-/Unfallversicherung' })}
            value={newChildBaseInformation.healthInsuranceNumber}
          />
        )}
        {(institution?.registrationConfig.askForLiablityInsuranceName === 'mandatory' ||
          institution?.registrationConfig.askForLiablityInsuranceName === 'optional') && (
          <TextField
            label={t({ message: 'Name Haftpflichtversicherung' })}
            value={newChildBaseInformation.liablityInsuranceName}
          />
        )}
        {(institution?.registrationConfig.askForLiablityInsuranceNumber === 'mandatory' ||
          institution?.registrationConfig.askForLiablityInsuranceNumber === 'optional') && (
          <TextField
            label={t({ message: 'Versichertennummer Haftpflichtversicherung' })}
            value={newChildBaseInformation.liablityInsuranceNumber}
          />
        )}
        {institution?.registrationConfig.askForFamilyDoctor !== 'off' && (
          <>
            <TextField
              label={t({ message: 'Name Hausarzt (oder Praxis)' })}
              value={newChildBaseInformation.familyDoctorName}
            />

            {institution?.registrationConfig.askForFamilyDoctorAddress !== 'off' && (
              <TextField
                label={t({ message: 'Adresse Hausarzt' })}
                value={newChildBaseInformation.familyDoctorAddress}
              />
            )}
            <TextField
              label={t({ message: 'Telefonnummer Hausarzt' })}
              value={newChildBaseInformation.familyDoctorPhone}
            />
          </>
        )}
        {institution?.registrationConfig.askForDentist !== 'off' && (
          <>
            <TextField
              label={t({ message: 'Name Zahnarzt (oder Zahnarztpraxis)' })}
              value={newChildBaseInformation.dentistName}
            />

            {institution?.registrationConfig.askForDentistAddress !== 'off' && (
              <TextField
                label={t({ message: 'Adresse Zahnarzt' })}
                value={newChildBaseInformation.dentistAddress}
              />
            )}
          </>
        )}
        {institution?.registrationConfig.askForChildGeneralHealth !== 'off' && (
          <TextField
            label={t({ message: 'Allgemeiner Gesundheitszustand' })}
            value={translateGeneralHealth(newChildBaseInformation.generalHealth)}
          />
        )}
        <TextField label={t({ message: 'Krankheiten' })} value={newChildBaseInformation.diseases} />
        <YesNoTranslatedField
          value={newChildBaseInformation.hasAllergies}
          label={t({ message: 'Allergien / Unverträglichkeiten beim Essen' })}
        />
        <TextField label={t({ message: 'Welche?' })} value={newChildBaseInformation.allergies} />
        <YesNoTranslatedField
          value={newChildBaseInformation.needsMedicaments}
          label={t({ message: 'Braucht das Kind regelmässig Medikamente?' })}
        />
        <TextField label={t({ message: 'Welche?' })} value={newChildBaseInformation.neededMedicaments} />
        <TextField label={t({ message: 'Weitere Bemerkungen' })} value={newChildBaseInformation.remarks} />
        <Modal
          title={t({ message: 'Basis Informationen bearbeiten' })}
          open={isEditModalOpen}
          onCancel={handleEditModalCancel}
          footer={null}
        >
          <ChildBaseInformationForm
            isOutOfTown={newChildBaseInformation.isOutOfTown}
            defaultValues={newChildBaseInformation}
            handleSubmit={handleEditModalOk}
            submitButtonText={t({ message: 'Speichern' })}
          />
        </Modal>
      </>
    </ChildCardSection>
  )
}

export default ChildCardBaseInformationSection
