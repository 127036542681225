import { t } from '@lingui/macro'
import { Button, Layout, List, Typography } from 'antd'

import Title from 'antd/es/typography/Title'
import { useNavigate } from 'react-router-dom'
import LeobaMarkdown from '../../../components/LeobaMarkdown'
import { useInstitutionContext } from '../institution-context'

const { Content } = Layout
const { Paragraph } = Typography

const WelcomePage = () => {
  const { institution } = useInstitutionContext()
  const navigate = useNavigate()

  return (
    <Content style={{ width: 'fit-content', marginInline: 'auto', padding: '0 50px' }}>
      <div className="site-layout-content">
        {institution?.registrationConfig.welcomeText ? (
          <LeobaMarkdown markdown={institution?.registrationConfig.welcomeText} />
        ) : (
          <>
            <Title level={2}>{t({ message: 'Anmeldung' })}</Title>
            <Paragraph>{t({ message: 'Sehr geehrte Damen und Herren' })}</Paragraph>
            <Paragraph>
              {t({
                message:
                  'Bitte füllen Sie das nachfolgende Anmeldeformular aus. Damit die Registrierung reibungslos abläuft, bitten wir Sie, folgende Daten bereit zu halten:',
              })}
            </Paragraph>
            <List size="small" split={false} style={{ marginBlock: '1em' }}>
              <List.Item style={{ paddingBlock: 0 }}>
                {t({ message: 'Bei alleinigem Sorgerecht: Gerichtsentscheid' })}
              </List.Item>
              <List.Item style={{ paddingBlock: 0 }}>{t({ message: 'Kontaktdaten Hausarzt/Hausärztin' })}</List.Item>
              <List.Item style={{ paddingBlock: 0 }}>{t({ message: 'Kontaktdaten Notfallkontakt' })}</List.Item>
            </List>
            <Paragraph>
              {t({
                message:
                  'Bitte beachten Sie, dass der Betreuungsvertrag erst abgesendet werden kann, wenn alle erziehungsberechtigten Personen den Vertrag unterzeichnet haben.',
              })}
            </Paragraph>
            <Paragraph>{t({ message: 'Vielen Dank.' })}</Paragraph>
            <Paragraph>{institution?.name || t({ message: 'Institution' })}</Paragraph>
          </>
        )}

        <Paragraph style={{ textAlign: 'center' }}>
          <Button type="primary" onClick={() => navigate('person1')}>
            {t({ message: 'Anmeldung starten' })}
          </Button>
        </Paragraph>
      </div>
    </Content>
  )
}

export default WelcomePage
