import { useState } from 'react'
import { Location } from '../../../../types'
import { ModuleChoice } from './module-form-with-school'
import SelectableModuleCardWithSchool from './selectable-module-card-with-school'
import { Radio, RadioChangeEvent, Typography } from 'antd'

const { Paragraph } = Typography

type Props = {
  primaryLocation: Location
  moduleChoices: ModuleChoice[]
  onModuleChange: (
    moduleId: string,
    checked: boolean,
    ageGroupeId: string | undefined,
    comingFromGoingTo: {
      comingFrom?: string | undefined
      comingFromTime?: string | undefined
      goingTo?: string | undefined
      goingToTime?: string | undefined
    }
  ) => void
}
export const SpecialWednesdayHuenenberg = ({ moduleChoices, primaryLocation, onModuleChange }: Props) => {
  const [careTime, setCareTime] = useState<'morning' | 'afternoon' | undefined>()

  const morningModule = moduleChoices.find((module) => parseInt(module.endTime.split(':')[0]) <= 12)
  const morningLocation = morningModule?.location
  const morningModules = moduleChoices.filter((module) => module.location === morningLocation)
  const afternoonModules = moduleChoices.filter((module) => module.location !== morningLocation)
  const justOneLocation = afternoonModules.length === 0

  const handleOnChangeCareTime = (e: RadioChangeEvent) => {
    if (e.target.value === 'morning') {
      setCareTime('morning')
    } else if (e.target.value === 'afternoon') {
      setCareTime('afternoon')
    }
    moduleChoices.forEach((module) => onModuleChange(module.id, false, module.ageGroup?.id, {}))
  }

  return (
    <div>
      {justOneLocation ? (
        moduleChoices.map((module) => (
          <SelectableModuleCardWithSchool
            primaryLocation={primaryLocation}
            module={module}
            key={module.id}
            onChange={onModuleChange}
            price={null}
          />
        ))
      ) : (
        <>
          <Paragraph>Bitte wählen Sie, ob Ihr Kind am Vormittag betreut werden soll:</Paragraph>
          <Radio.Group onChange={handleOnChangeCareTime}>
            <Radio value="morning">Vormittag</Radio>
            <Radio value="afternoon">Nur Nachmittag</Radio>
          </Radio.Group>
          {careTime === 'morning' && (
            <>
              {morningModules.map((module, i) => (
                <SelectableModuleCardWithSchool
                  primaryLocation={primaryLocation}
                  module={module}
                  key={module.id}
                  onChange={onModuleChange}
                  price={null}
                />
              ))}
            </>
          )}
          {careTime === 'afternoon' && (
            <>
              {afternoonModules.map((module, i) => (
                <SelectableModuleCardWithSchool
                  primaryLocation={primaryLocation}
                  module={module}
                  key={module.id}
                  onChange={onModuleChange}
                  price={null}
                />
              ))}
            </>
          )}
        </>
      )}
    </div>
  )
}
