import { t } from '@lingui/macro'
import { Button, Calendar, Typography } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { CSSProperties, Fragment, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import FormPage from '../../../../components/form/form-page'
import { toDateString } from '../../../../helper/format-date'
import { HolidayCare, HolidayCareBooking, Institution, Location } from '../../../../types'
import { useInstitutionContext } from '../../institution-context'
import HolidayCareCard from './holiday-care-card'
import ErrorMessage from '../../../../components/error-message'
const { Title } = Typography

const NewHolidayCarePage = () => {
  const navigate = useNavigate()
  let { childId } = useParams()
  const { institution } = useInstitutionContext()

  const [selectedDates, setSelectedDates] = useState<string[]>([])
  const [selectedHolidayCareItems, setSelectedHolidayCareItems] = useState<
    (HolidayCare &
      Pick<HolidayCareBooking, 'arrival' | 'arrivalTime' | 'departure' | 'departureTime' | 'destination'>)[]
  >([])
  const [bookedHolidayCareItems, setBookedHolidayCareItems] = useState<HolidayCare[]>([])
  const [bookingPending, setBookingPending] = useState(false)
  const [bookingError, setBookingError] = useState<string | undefined>()

  useEffect(() => {
    const getHolidayCareBookings = async () => {
      if (childId && institution) {
        const response = await fetch(
          `${process.env.REACT_APP_LEOBA_SERVER}/api/eltern-app/${institution.id}/guardian/holiday-care-booking?childId=${childId}`,
          {
            headers: { accept: 'application/json' },
          }
        )
        if (response.ok) {
          const result = (await response.json()) as (HolidayCareBooking & { holidayCare: HolidayCare })[]
          setBookedHolidayCareItems(result.map((booking) => booking.holidayCare))
        }
      }
    }
    getHolidayCareBookings()
  }, [childId, institution])

  const choosableDates: string[] | undefined = institution?.holidayCare.map((holidayCare) => {
    return dayjs(holidayCare.date).format('YYYY-MM-DD')
  })

  const bookableHolidayCareItems: HolidayCare[] | undefined = institution?.holidayCare.filter((holidayCare) =>
    selectedDates.includes(dayjs(holidayCare.date).format('YYYY-MM-DD'))
  )

  const dateRender = (currentDate: Dayjs | null) => {
    const thisDate = dayjs(currentDate).format('YYYY-MM-DD')
    const isSelected = selectedDates.includes(thisDate)
    const selectStyle: CSSProperties = isSelected
      ? {
          position: 'relative',
          zIndex: 2,
          display: 'inlineBlock',
          width: '24px',
          height: '22px',
          lineHeight: '22px',
          backgroundColor: '#87c7aa',
          color: '#fff',
          margin: 'auto',
          borderRadius: '2px',
          transition: 'background 0.3s, border 0.3s',
        }
      : {}
    return (
      <div onClick={onClickCalendarDate(currentDate)} style={selectStyle}>
        {' '}
        {currentDate ? currentDate.date() : ''}{' '}
      </div>
    )
  }

  const onClickCalendarDate = (date: Dayjs | null) => () => {
    const newDate = dayjs(date).format('YYYY-MM-DD')

    if (selectedDates.includes(newDate)) {
      setSelectedDates([...selectedDates.filter((item) => item !== newDate)])
      setSelectedHolidayCareItems(
        selectedHolidayCareItems.filter((item) => dayjs(item.date).format('YYYY-MM-DD') !== newDate)
      )
    } else {
      setSelectedDates([...selectedDates, newDate])
    }
  }

  const isDateDisabled = (date: Dayjs): boolean => {
    return choosableDates ? !choosableDates.includes(date.format('YYYY-MM-DD')) : true
  }

  const selectHolidayCare = (
    holidayCare: HolidayCare &
      Pick<HolidayCareBooking, 'arrival' | 'arrivalTime' | 'departure' | 'departureTime' | 'destination'>
  ) => {
    if (isSelected(holidayCare.id)) {
      setSelectedHolidayCareItems(selectedHolidayCareItems.filter((selected) => selected.id !== holidayCare.id))
    } else {
      setSelectedHolidayCareItems([...selectedHolidayCareItems, holidayCare])
    }
  }

  const isSelected = (holidayCareId: string) =>
    !!selectedHolidayCareItems.find((selected) => selected.id === holidayCareId)

  const isBooked = (holidayCareId: string) => !!bookedHolidayCareItems.find((item) => item.id === holidayCareId)

  const getBookableHolidayCareItemsByDate = (date: string) => {
    return bookableHolidayCareItems?.filter((bci: HolidayCare) => dayjs(bci.date).format('YYYY-MM-DD') === date) || []
  }
  const groupBookableHolidayCareItemsByLocation = (institution: Institution, hcItems: HolidayCare[]) => {
    return hcItems.reduce<{ location: Location; holidayCareItems: HolidayCare[] }[]>((acc, current) => {
      const existing = acc.find((item) => item.location.id === current.locationId)
      if (existing) {
        existing.holidayCareItems.push(current)
      } else {
        acc.push({
          location: institution.locations.find((loc) => loc.id === current.locationId)!,
          holidayCareItems: [current],
        })
      }
      return acc
    }, [])
  }

  const createHolidayCareBookings = async () => {
    setBookingPending(true)
    const body = JSON.stringify({
      holidayCareItems: selectedHolidayCareItems.map(
        ({ id: holidayCareId, arrival, arrivalTime, departure, departureTime, destination }) => ({
          holidayCareId,
          arrival,
          arrivalTime,
          departure,
          departureTime,
          destination,
        })
      ),
    })
    const result = await fetch(
      `${process.env.REACT_APP_LEOBA_SERVER}/api/eltern-app/${institution?.id}/guardian/holiday-care-booking?childId=${childId}`,
      {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
        },
        body,
      }
    )
    setBookingPending(false)
    if (result.ok) {
      navigate('../new-holiday-care-page/confirmation')
    } else {
      setBookingError(
        t({ message: `Es ist leider ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.` })
      )
    }
  }

  return (
    <FormPage title={t({ message: `Ferienbetreuung etc.` })}>
      <>
        <Title level={3}>{t({ message: 'Verfügbare Termine' })}</Title>
        <Calendar fullscreen={false} disabledDate={isDateDisabled} dateFullCellRender={dateRender} />
        <Title level={3}>{t({ message: 'Verfügbare Angebote' })}</Title>
        {institution !== undefined && (
          <div>
            {selectedDates.length === 0 && (
              <div style={{ paddingBlock: '1em' }}>{t({ message: 'Bitte Datum auswählen.' })}</div>
            )}
            <div>
              {selectedDates
                .sort((a: string, b: string) => {
                  return +new Date(a) - +new Date(b)
                })
                .map((selDate) => {
                  const hcByDate = getBookableHolidayCareItemsByDate(selDate)
                  const hcGroupedByLocation = groupBookableHolidayCareItemsByLocation(institution, hcByDate)
                  return (
                    <div key={selDate}>
                      {hcGroupedByLocation.map((hcAtLocation) => {
                        return (
                          <Fragment key={hcAtLocation.location.id}>
                            {(selectedDates.length > 1 || hcGroupedByLocation.length > 1) && (
                              <Title level={4}>
                                {toDateString(dayjs(selDate).toDate())}: {hcAtLocation.location.name}
                              </Title>
                            )}
                            {hcAtLocation.holidayCareItems.map((hc) => {
                              return (
                                <HolidayCareCard
                                  key={hc.id}
                                  holidayCareItem={hc}
                                  selectHolidayCare={selectHolidayCare}
                                  isSelected={isSelected(hc.id)}
                                  isBooked={isBooked(hc.id)}
                                  childId={childId}
                                ></HolidayCareCard>
                              )
                            })}
                          </Fragment>
                        )
                      })}
                    </div>
                  )
                })}
            </div>
          </div>
        )}

        {bookingError && <ErrorMessage message={bookingError} />}
        <Button style={{ marginTop: '1em', marginRight: '1em' }} onClick={() => navigate('../home')}>
          {t({ message: 'Zurück' })}
        </Button>

        {selectedHolidayCareItems.length > 0 && (
          <Button
            type="primary"
            style={{ marginTop: '1em' }}
            onClick={createHolidayCareBookings}
            loading={bookingPending}
          >
            {selectedHolidayCareItems.length === 1 && t({ message: 'Anfrage für Betreuungsangebot abschicken' })}
            {selectedHolidayCareItems.length > 1 &&
              t({ message: `Anfrage für ${selectedHolidayCareItems.length} Betreuungsangebote abschicken` })}
          </Button>
        )}
      </>
    </FormPage>
  )
}
export default NewHolidayCarePage
