import dayjs from 'dayjs'
import { dateString } from '../../helper/date-string'
import { getActiveContractOrUndefined } from '../../helper/get-active-contract'
import { Contract, Family, RegistrationConfiguration } from '../../types'

export const getNewSchoolYearTasks = (
  family?: Family,
  config?: RegistrationConfiguration,
  date: string = dateString(new Date())
) => {
  if (!family || !config || config.schoolYearStartDate < date) {
    return {
      total: 0,
      contractsToCreate: [],
      contractsCreated: [],
      guardiansChecked: true,
    }
  }
  const childrenAndContracts = family.children.map((child) => {
    const nextYearContract = findAnyContractAt(child.contracts, config.schoolYearStartDate)
    const activeContract = getActiveContractOrUndefined(child.contracts, date)
    return { child, nextYearContract, activeContract }
  })
  const contractsToCreate = childrenAndContracts
    .filter(({ nextYearContract }) => nextYearContract === undefined)
    .map(({ child }) => ({ childId: child.id, firstName: child.firstName, lastName: child.lastName }))
  const contractsCreated = childrenAndContracts
    .filter(({ nextYearContract }) => nextYearContract)
    .map(({ child }) => ({ childId: child.id, firstName: child.firstName, lastName: child.lastName }))
  const guardiansChecked = dayjs(date).diff(dayjs(family.lastChecked), 'months') <= 2

  return {
    contractsToCreate,
    contractsCreated,
    guardiansChecked,
    total: contractsToCreate.length + (guardiansChecked ? 0 : 1),
  }
}

const findAnyContractAt = (contracts: Contract[], now: string): Contract | undefined => {
  contracts.sort((a, b) => a.startDate.getTime() - b.startDate.getTime())
  const result = contracts.find((contract) => {
    const endDate = contract.endDate ? dateString(contract.endDate) : '9999-99-99'
    return now <= endDate
  })

  return result
}
