import { t } from '@lingui/macro'
import { WayHome } from '../types'

const translatedWayHome = (wayHome: WayHome) => {
  let result
  switch (wayHome) {
    case 'fetched':
      result = t({ message: 'Wird abgeholt' })
      break
    case 'partiallyFetched':
      result = t({ message: 'Wird manchmal abgeholt' })
      break
    case 'unfetched':
      result = t({ message: 'Geht alleine nach Hause' })
      break
  }
  return result
}

export default translatedWayHome
