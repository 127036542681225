import { Trans } from '@lingui/macro'
import { Radio, Space } from 'antd'
import { Control, Controller, UseFormRegister } from 'react-hook-form'

type Props = {
  control: Control<any, any>
  register: UseFormRegister<any>
  validate?: (value?: boolean) => boolean
  name: string
  disabled?: boolean
  required?: boolean
  firstButton?: 'yes' | 'no'
  onChange?: (value: boolean) => void
}

const YesNoRadioButtons = ({
  control,
  register,
  required = false,
  name,
  disabled,
  firstButton,
  onChange: onChangeComponentLevel,
}: Props) => {
  const defaultValidate = (value?: boolean) => {
    if (required && (value === null || value === undefined)) {
      return ''
    } else {
      return true
    }
  }

  return (
    <Controller
      disabled={disabled}
      control={control}
      {...register(name, {
        validate: defaultValidate,
      })}
      render={({ field: { onChange, value } }) => (
        <Radio.Group
          id={name}
          onChange={(e) => {
            if (e.target.value === 'yes') {
              onChange(true)
              onChangeComponentLevel && onChangeComponentLevel(true)
            } else if (e.target.value === 'no') {
              onChange(false)
              onChangeComponentLevel && onChangeComponentLevel(false)
            }
          }}
          disabled={disabled}
          value={value === undefined ? undefined : value === true ? 'yes' : 'no'}
        >
          <Space direction="horizontal">
            {firstButton === 'no' ? (
              <>
                <Radio value="no">
                  <Trans>Nein</Trans>
                </Radio>
                <Radio value="yes">
                  <Trans>Ja</Trans>
                </Radio>
              </>
            ) : (
              <>
                <Radio value="yes">
                  <Trans>Ja</Trans>
                </Radio>
                <Radio value="no">
                  <Trans>Nein</Trans>
                </Radio>
              </>
            )}
          </Space>
        </Radio.Group>
      )}
    />
  )
}

export default YesNoRadioButtons
