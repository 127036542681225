import { PlusOutlined } from '@ant-design/icons'
import { t } from '@lingui/macro'
import { Button, Form, Radio, RadioChangeEvent, Space, Typography } from 'antd'
import Decimal from 'decimal.js'
import BillingAddressCard from '../../../../components/billing-address-card'
import ChildCard from '../../../../components/child/child-card'
import NewModulesTotalPrice from '../../../../components/child/child-form/module-form/new-modules-total-price'
import ButtonSection from '../../../../components/form/button-section'
import FormPage from '../../../../components/form/form-page'
import GuardianCard from '../../../../components/guardian/guardian-card'
import { useInstitutionContext } from '../../institution-context'
import { useRegistrationContext } from '../registration-context'
import { useState } from 'react'
const { Title } = Typography

type Props = {
  handleSubmit: () => Promise<void>
  addChildHandler: () => void
}

const SummaryFormPage = ({ handleSubmit, addChildHandler }: Props) => {
  const {
    familyId,
    children,
    billingAddress,
    hasMoreThanOneChild,
    getGuardian1Data,
    setGuardian1Data,
    getGuardian2Data,
    setGuardian2Data,
    updateChildBaseInformation,
    updateChildFurtherInformation,
    updateChildImportantInformation,
    updateChildModules,
    getGuardianAddresses,
    setBillingAddress,
    getTaxInformation,
    setHasMoreThanOneChild,
  } = useRegistrationContext()
  const person1Data = getGuardian1Data()
  const person2Data = getGuardian2Data()
  const [hasMoreThanOneChildError, setHasMoreThanOneChildError] = useState(false)
  const taxInformation = getTaxInformation()
  const { institution } = useInstitutionContext()
  const allModules = institution?.locations.flatMap((location) => location.modules) || []
  const moduleChoices = children
    .flatMap((child) => child.moduleBooking)
    .flatMap((booking) => {
      return (
        booking?.bookings.map((modBooking) => {
          const module = allModules.find((mod) => mod.id === modBooking.moduleId)
          return {
            id: module?.id || '',
            name: module?.name || '',
            chosen: true,
            priceMonthlyInclVat: module?.priceMonthlyInclVat || new Decimal(0),
          }
        }) || []
      )
    })

  const askForFurtherChildrenInSchoolAge =
    institution?.registrationConfig.askForFurtherSchoolAgeChildren !== 'off' &&
    children.filter(
      (child) => child.baseInformation.schoolLevel === 'kindergarten' || child.baseInformation.schoolLevel === 'school'
    ).length <= 1

  const askIfChildHasSiblings = institution?.registrationConfig.askIfChildHasSiblings !== 'off' && children.length <= 1

  const textToAskForSiblings = askForFurtherChildrenInSchoolAge
    ? t({ message: `Unsere Familie hat mehr als ein Kind im schulpflichtigen Alter` })
    : askIfChildHasSiblings
    ? t({ message: `Unsere Familie hat mehr als ein Kind` })
    : ''

  const handleOnChangeHasMoreThanOneChild = (event: RadioChangeEvent) => {
    if (event.target.value === 'yes') {
      setHasMoreThanOneChild(true)
      setHasMoreThanOneChildError(false)
    } else if (event.target.value === 'no') {
      setHasMoreThanOneChild(false)
      setHasMoreThanOneChildError(false)
    }
  }

  const handleBeforeSubmit = () => {
    if (institution?.registrationConfig.askForFurtherSchoolAgeChildren === 'mandatory') {
      if (askForFurtherChildrenInSchoolAge) {
        if (hasMoreThanOneChild === undefined) {
          setHasMoreThanOneChildError(true)
          return
        }
      }
    }
    handleSubmit()
  }

  return (
    <FormPage title={t({ message: 'Zusammenfassung' })} progress={8}>
      <>
        <Title level={3}>{t({ message: 'Erziehungsberechtigte' })}</Title>
        {person1Data && (
          <GuardianCard
            isSecondParent={false}
            onSubmitGuardianInformation={setGuardian1Data}
            isSoleCustodyLockedIn={true}
            isRegistration={true}
            guardian={person1Data}
            registrationConfiguration={institution!.registrationConfig}
          />
        )}
        {person2Data && (
          <GuardianCard
            isSecondParent={true}
            onSubmitGuardianInformation={setGuardian2Data}
            guardian={person2Data}
            isRegistration={true}
            registrationConfiguration={institution!.registrationConfig}
          />
        )}

        <Title level={3}>{t({ message: 'Kinder' })}</Title>
        {children.map((child) => (
          <ChildCard
            key={child.id}
            onSubmitEditedBaseInformation={updateChildBaseInformation}
            onSubmitEditedFurtherInformation={updateChildFurtherInformation}
            onSubmitEditedImportantInformation={updateChildImportantInformation}
            onSubmitEditedModules={updateChildModules}
            child={child}
          />
        ))}
        <Button icon={<PlusOutlined />} style={{ width: '100%', marginBottom: '0.5em' }} onClick={addChildHandler}>
          {t({ message: 'Zusätzliches Kind' })}
        </Button>

        {askForFurtherChildrenInSchoolAge && (
          <Typography.Paragraph>
            <Form.Item
              label={textToAskForSiblings}
              validateStatus={hasMoreThanOneChildError ? 'error' : 'success'}
              help={hasMoreThanOneChildError ? t({ message: `Bitte ausfüllen` }) : null}
            >
              <Radio.Group
                id={'hasMoreThanOneChild'}
                onChange={handleOnChangeHasMoreThanOneChild}
                value={hasMoreThanOneChild === undefined ? undefined : hasMoreThanOneChild ? 'yes' : 'no'}
              >
                <Space direction="horizontal">
                  <Radio value="yes">{t({ message: `Ja` })}</Radio>
                  <Radio value="no">{t({ message: `Nein` })}</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Typography.Paragraph>
        )}

        <Title level={3}>{t({ message: 'Rechnungsadresse' })}</Title>
        <BillingAddressCard
          billingAddress={billingAddress}
          familyId={familyId}
          guardianAddresses={getGuardianAddresses()}
          onSubmitBillingAddress={setBillingAddress}
        />

        <NewModulesTotalPrice taxInformation={taxInformation} moduleChoices={moduleChoices} />

        <ButtonSection onNext={handleBeforeSubmit} />
      </>
    </FormPage>
  )
}

export default SummaryFormPage
