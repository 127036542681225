import { Card } from 'antd'
import EditButton from '../../edit-button'

type Props = {
  onEdit: () => void
  children: JSX.Element
  title: string
}

const ChildCardSection = ({ title, onEdit, children }: Props) => {
  return (
    <Card
      size="small"
      type="inner"
      title={
        <div
          style={{
            whiteSpace: 'pre-wrap',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <>{title}</>
          <EditButton onClick={onEdit} />
        </div>
      }
      style={{ marginBottom: 8 }}
    >
      {children}
    </Card>
  )
}

export default ChildCardSection
