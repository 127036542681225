import { t } from '@lingui/macro'
import { SchoolLevel } from '../types'

const translateSchoolLevel = (schoolLevel?: SchoolLevel): string => {
  let result
  switch (schoolLevel) {
    case 'baby':
      result = t({ message: 'Säugling' })
      break
    case 'toddler':
      result = t({ message: 'Kleinkind' })
      break
    case 'kindergarten':
      result = t({ message: 'Kindergarten' })
      break
    case 'school':
      result = t({ message: 'Schule' })
      break
    default:
      result = ''
      break
  }
  return result
}

export default translateSchoolLevel
