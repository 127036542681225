import { t } from '@lingui/macro'
import { Modal } from 'antd'
import { useState } from 'react'
import translateContactPreference from '../../../helper/translate-contact-preference'
import { useRegistrationContext } from '../../../routes/institution/registration/registration-context'
import TextField from '../../text-field'
import ChildFurtherInformationForm, { NewChildFurtherInformation } from '../child-form/child-further-information-form'
import ChildCardSection from './child-card-section'

type Props = {
  childId: string
  newChildFurtherInformation: NewChildFurtherInformation
  onSubmitEditedEditFurtherInformation: (information: NewChildFurtherInformation, childId: string) => void
}

const ChildCardFurtherInformationSection = ({
  newChildFurtherInformation,
  onSubmitEditedEditFurtherInformation,
  childId,
}: Props) => {
  const { getGuardian1Data, getGuardian2Data } = useRegistrationContext()
  const guardians = [getGuardian1Data(), getGuardian2Data()]

  const [isEditModalOpen, setIsEditModalOpen] = useState(false)

  const showEditModal = () => {
    setIsEditModalOpen(true)
  }

  const handleEditModalOk = (data: NewChildFurtherInformation) => {
    onSubmitEditedEditFurtherInformation(data, childId)
    setIsEditModalOpen(false)
  }

  const handleEditModalCancel = () => {
    setIsEditModalOpen(false)
  }

  const handleOnEdit = () => {
    showEditModal()
  }
  return (
    <ChildCardSection onEdit={handleOnEdit} title={t({ message: 'Weitere Informationen' })}>
      <>
        <TextField
          label={t({ message: 'Korrespondenz über' })}
          value={translateContactPreference(guardians, newChildFurtherInformation.contactPreference)}
        />
        <TextField
          label={t({ message: 'Notfallkontakt Vorname' })}
          value={newChildFurtherInformation.emergencyContactFirstName}
        />
        <TextField
          label={t({ message: 'Notfallkontakt Nachname' })}
          value={newChildFurtherInformation.emergencyContactLastName}
        />
        <TextField
          label={t({ message: 'Notfallkontakt Telefon' })}
          value={newChildFurtherInformation.emergencyContactPhone}
        />
        <TextField
          label={t({ message: 'Notfallkontakt Beziehung zu Kind' })}
          value={newChildFurtherInformation.emergencyContactRemarks}
        />
        <Modal
          title={t({ message: 'Weitere Informationen bearbeiten' })}
          open={isEditModalOpen}
          onCancel={handleEditModalCancel}
          footer={null}
        >
          <ChildFurtherInformationForm
            defaultValues={newChildFurtherInformation}
            handleSubmit={handleEditModalOk}
            submitButtonText={t({ message: 'Speichern' })}
          />
        </Modal>
      </>
    </ChildCardSection>
  )
}

export default ChildCardFurtherInformationSection
