import { Field } from './form/field'

type Props = {
  label: string
  value: string | undefined
}

const TextField = ({ label, value }: Props) => (
  <>
    {value !== undefined && (
      <Field label={label}>
        <>{value}</>
      </Field>
    )}
  </>
)

export default TextField
