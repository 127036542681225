import dayjs from 'dayjs'
import { Fragment } from 'react'
import weekdays from '../../lib/weekdays'
import SelectableModuleDay from './selectable-module-day'
import { Module } from '../../types'

type Props = {
  startDate: string
  endDate: string
  setSelectedModuleIds: (selectedModuleIds: string[]) => void
  selectedModuleIds: string[]
  modules: Module[]
  showPrices?: boolean
  prices: Array<{ moduleId: string; price: number | null }>
}

const SelectableModuleList = ({
  modules,
  setSelectedModuleIds,
  selectedModuleIds,
  startDate,
  endDate,
  showPrices = false,
  prices,
}: Props) => {
  const availableDays = getDaysOfWeekBetweenDates(startDate, endDate)
  return (
    <ul style={{ listStyle: 'none', padding: 0 }}>
      {weekdays.map((day) => {
        if (
          availableDays.find((d) => {
            return day.value === d
          }) !== undefined
        ) {
          return (
            <SelectableModuleDay
              showPrices={showPrices}
              prices={prices}
              key={day.name}
              selectedModuleIds={selectedModuleIds}
              setSelectedModuleIds={setSelectedModuleIds}
              day={day}
              modules={modules}
            />
          )
        } else {
          return <Fragment key={day.name}></Fragment>
        }
      })}
    </ul>
  )
}

const getDaysOfWeekBetweenDates = (startDate: string, endDate: string) => {
  const localStartDate = dayjs(startDate)
  const localEndDate = dayjs(endDate)

  if (localStartDate.isSame(localEndDate)) {
    return [localStartDate.weekday()]
  }

  const daysOfWeek = []

  let currentDate = localStartDate

  while (daysOfWeek.length < 7 && currentDate.isBefore(localEndDate.add(1, 'day'))) {
    daysOfWeek.push(currentDate.weekday())
    currentDate = currentDate.add(1, 'day')
  }
  return daysOfWeek
}

export default SelectableModuleList
