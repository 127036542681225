import { Select } from 'antd'
import { Controller, ControllerProps, Path } from 'react-hook-form'

export const SelectInput = <T extends Record<string, any>, P extends Path<T>>({
  options,
  ...props
}: {
  options: Array<{ value: T[P]; label: string }>
} & Omit<ControllerProps<T, P>, 'render'>) => (
  <Controller {...props} render={({ field }) => <Select {...field} options={options} style={{ width: '100%' }} />} />
)
