import { CookieHandlerInterface } from 'supertokens-website/utils/cookieHandler/types'
import { Preferences } from '@capacitor/preferences'

const frontendCookiesKey = 'frontendCookies'

/**
 * This cookie handler is from https://github.com/RobSchilderr/capacitor-supertokens-nextjs-turborepo
 * It provides a storage for cookies via capacitor preferences API (https://capacitorjs.com/docs/apis/preferences)
 * We need to use this because cookies are not well supported with capacitors webview.
 */
async function getCookiesFromStorage(): Promise<string> {
  const cookiesFromStorage = await Preferences.get({
    key: frontendCookiesKey,
  })

  if (cookiesFromStorage.value === null) {
    Preferences.set({
      key: frontendCookiesKey,
      value: '[]',
    })
    return ''
  }

  /**
   * Because we store cookies in local storage, we need to manually check
   * for expiry before returning all cookies
   */
  const cookieArrayInStorage: string[] = JSON.parse(cookiesFromStorage.value)
  let cookieArrayToReturn: string[] = []

  for (let cookieIndex = 0; cookieIndex < cookieArrayInStorage.length; cookieIndex++) {
    const currentCookieString = cookieArrayInStorage[cookieIndex]
    const parts = currentCookieString.split(';')
    let expirationString: string = ''

    for (let partIndex = 0; partIndex < parts.length; partIndex++) {
      const currentPart = parts[partIndex]

      if (currentPart.toLocaleLowerCase().includes('expires=')) {
        expirationString = currentPart
        break
      }
    }

    if (expirationString !== '') {
      const expirationValueString = expirationString.split('=')[1]
      const expirationDate = new Date(expirationValueString)
      const currentTimeInMillis = Date.now()

      // if the cookie has expired, we skip it
      if (expirationDate.getTime() < currentTimeInMillis) {
        continue
      }
    }

    cookieArrayToReturn.push(currentCookieString)
  }

  return cookieArrayToReturn.join('; ')
}

async function setCookieToStorage(cookieString: string) {
  const cookieName = cookieString.split(';')[0].split('=')[0]
  const cookiesFromStorage = await Preferences.get({
    key: frontendCookiesKey,
  })
  let cookiesArray: string[] = []

  if (cookiesFromStorage.value !== null) {
    const cookiesArrayFromStorage: string[] = JSON.parse(cookiesFromStorage.value)
    cookiesArray = cookiesArrayFromStorage
  }

  let cookieIndex = -1

  for (let i = 0; i < cookiesArray.length; i++) {
    const currentCookie = cookiesArray[i]

    if (currentCookie.indexOf(`${cookieName}=`) !== -1) {
      cookieIndex = i
      break
    }
  }

  /**
   * If a cookie with the same name already exists (index != -1) then we
   * need to remove the old value and replace it with the new one.
   *
   * If it does not exist then simply add the new cookie
   */
  if (cookieIndex !== -1) {
    cookiesArray[cookieIndex] = cookieString
  } else {
    cookiesArray.push(cookieString)
  }

  await Preferences.set({
    key: frontendCookiesKey,
    value: JSON.stringify(cookiesArray),
  })
}

export function getCookieHandler(original: CookieHandlerInterface): CookieHandlerInterface {
  return {
    ...original,
    getCookie: async function () {
      const cookies = await getCookiesFromStorage()
      return cookies
    },
    setCookie: async function (cookieString: string) {
      await setCookieToStorage(cookieString)
    },
  }
}
