import { PlusOutlined } from '@ant-design/icons'
import { Trans } from '@lingui/macro'
import { Button, Grid } from 'antd'

const NewButton = ({ onClick, label }: { onClick?: () => void; label?: string }) => {
  const { useBreakpoint } = Grid
  const screens = useBreakpoint()

  return (
    <>
      {screens.sm === true ? (
        <Button onClick={onClick} icon={<PlusOutlined />}>
          &nbsp;{label ? label : <Trans>Hinzufügen</Trans>}
        </Button>
      ) : (
        <Button onClick={onClick} icon={<PlusOutlined />} />
      )}
    </>
  )
}

export default NewButton
