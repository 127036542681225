import { Layout, theme, Typography } from 'antd'
import { SafeArea } from 'capacitor-plugin-safe-area'
import { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import LoginButton from '../components/login-button'
import { useInstitutionContext } from './institution/institution-context'
import './root.css'
import { Capacitor } from '@capacitor/core'
import styles from './ios-input-autozoom-fix.module.css'
import { isEmailVerified } from 'supertokens-web-js/recipe/emailverification'
import { getUser } from './institution/guardian/load-guardian'
import { signOut } from 'supertokens-web-js/recipe/emailpassword'
import { useGuardianContext } from './institution/guardian/guardian-context'
import { Guardian } from '../types'
import { uniq } from 'remeda'
import { fetchInstitution } from './institution/load-institution'

const { Text } = Typography
const { Header, Content, Footer } = Layout

function Root() {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [sessionVerified, setSessionVerified] = useState(false)

  useEffect(() => {
    if (!sessionVerified) {
      if (needsPropperAuthentication(pathname)) {
        isEmailVerified()
          .then((response) => {
            if (response.isVerified) {
              setSessionVerified(true)
            } else {
              navigate('/auth')
            }
          })
          .catch(() => {
            navigate('/auth')
          })
      }
    }
  }, [setSessionVerified, navigate, pathname, sessionVerified])
  useEffect(() => {
    const setUserState = async () => {
      if (sessionVerified && pathname === '/institution-index') {
        const user = await getUser()
        if (user === 'not allowed') {
          await signOut()
          useGuardianContext.setState({ user: undefined, guardians: undefined })
          navigate('/auth')
        } else {
          const guardians = user.guardians as (Guardian & { institutionId: string })[]
          useGuardianContext.setState({ user: user.user, guardians })

          if (guardians.length === 1) {
            navigate(`/${guardians[0].institutionId}/guardian/${guardians[0].id}/home`)
          }

          Promise.all(
            uniq(guardians.map((guardian) => guardian.institutionId)).map((institutionId) =>
              fetchInstitution(institutionId)
            )
          ).then((institutions) => useGuardianContext.setState({ institutions }))
        }
      }
    }

    setUserState()
  }, [navigate, pathname, sessionVerified])
  const [needsIosInputAutozoomFix, setNeedsiosInputAutozoomFix] = useState<boolean>(false)
  const {
    token: { colorPrimary },
  } = theme.useToken()

  const showLoginButton = pathname.indexOf('/registration') === -1 && pathname !== '/auth'
  const [mobileInsets, setMobileInsets] = useState<
    | {
        top: number
        right: number
        bottom: number
        left: number
      }
    | undefined
  >()

  const { institution } = useInstitutionContext()
  const institutionName = institution?.name || 'Leoba'
  const defaultURL = institution
    ? `${process.env.REACT_APP_LEOBA_SERVER}/api/eltern-app/${institution?.id}/logo`
    : undefined
  const [logoURL, setLogoURL] = useState(defaultURL)

  useEffect(() => {
    if (institution) {
      setLogoURL(defaultURL)
    }
  }, [defaultURL, institution, setLogoURL])

  const handleErrorLoadingImage = (data: any) => {
    setLogoURL(undefined)
  }

  useEffect(() => {
    if (Capacitor.getPlatform() === 'ios') {
      SafeArea.getSafeAreaInsets().then(({ insets }) => {
        setMobileInsets(insets)
        setNeedsiosInputAutozoomFix(true)
      })
    } else if (Capacitor.getPlatform() === 'web') {
      if (window.navigator.userAgent.indexOf('iPhone') >= 0) {
        setNeedsiosInputAutozoomFix(true)
      }
    }
  }, [])

  return (
    <Layout className={`${needsIosInputAutozoomFix ? styles.ios : ''}`} style={{ minHeight: '100vh' }}>
      <div style={{ height: mobileInsets ? `${mobileInsets.top}px` : '0px', background: colorPrimary }} />

      <Header
        style={{
          background: colorPrimary,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingInline: '0 1em',
        }}
      >
        {logoURL && (
          <img
            style={
              institution?.id === 'cCJtg4Off1HhGbqe4bh0i'
                ? { padding: '10px 10px 10px 1.2rem' }
                : { mixBlendMode: 'multiply', padding: '10px 10px 10px 1.2rem' }
            }
            height="64px"
            onError={handleErrorLoadingImage}
            alt={institutionName}
            src={logoURL}
          />
        )}
        <div style={{ paddingInlineStart: '1.2rem', lineHeight: '1.2em', fontWeight: 'bold', fontSize: '1.3em' }}>
          {institutionName}
        </div>
        {showLoginButton && <LoginButton />}
      </Header>
      <Content>
        <Outlet />
      </Content>
      <Footer style={{ textAlign: 'center' }}>
        <Text>©2023 Leoba GmbH</Text>
      </Footer>
      <div style={{ height: mobileInsets ? `${mobileInsets.bottom}px` : '0px' }} />
    </Layout>
  )
}

export default Root

const needsPropperAuthentication = (pathName: string) => {
  if (pathName.match(/^\/auth/)) {
    return false
  } else if (pathName.match(/^\/.*\/registration.*/)) {
    return false
  } else {
    return true
  }
}
