import { t } from '@lingui/macro'
import { Form, Radio } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { useState } from 'react'
import ButtonSection from '../../../../components/form/button-section'
import FormPage from '../../../../components/form/form-page'

type Props = {
  defaultOutOfTown: boolean | undefined
  handleSubmit: () => void
  handleOutOfTownChild: () => void
  handlePrevious?: () => void
  submitButtonText?: string
}

const ResidenceFormPage = ({
  defaultOutOfTown,
  handleSubmit,
  handleOutOfTownChild,
  handlePrevious,
  submitButtonText,
}: Props) => {
  const [form] = useForm()

  const [outOfTown, setOutOfTown] = useState(defaultOutOfTown)

  const onSubmitHandler = () => {
    if (outOfTown) {
      handleOutOfTownChild()
    } else {
      handleSubmit()
    }
  }

  return (
    <FormPage title={t({ message: 'Angebot' })} progress={2.5}>
      <Form form={form} onFinish={onSubmitHandler}>
        <Form.Item
          name="residence"
          rules={[{ required: true, message: t({ message: 'Dieses Feld ist zwingend' }) }]}
          initialValue={outOfTown}
        >
          <Radio.Group onChange={(e) => setOutOfTown(e.target.value)}>
            <Radio value={false}>{t({ message: `Wir möchten regelmässige Module buchen.` })}</Radio>
            <Radio value={true}>{t({ message: `Wir möchten nur Ferienbetreuung buchen.` })}</Radio>
          </Radio.Group>
        </Form.Item>
        <ButtonSection
          submitButtonText={submitButtonText}
          onNext={form.submit}
          onPrevious={handlePrevious && handlePrevious}
        />
      </Form>
    </FormPage>
  )
}

export default ResidenceFormPage
