import { t } from '@lingui/macro'
import { CollectorsStrategy } from '../types'

const translateCollectorsStrategy = (strategy?: CollectorsStrategy): string => {
  let result
  switch (strategy) {
    case 'only_guardians':
      result = t({ message: 'Nur erziehungsberechtigten Personen' })
      break
    case 'exclusive':
      result = t({ message: 'Erziehungsberechtigte und hier aufgelistete Personen' })
      break
    case 'not_specified':
    case undefined:
      result = t({ message: 'Keine Angaben' })
      break
  }
  return result
}

export default translateCollectorsStrategy
