import { SmileOutlined } from '@ant-design/icons'
import { t } from '@lingui/macro'
import { Button, Modal, Result, Row, Select, Typography } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import dayjs from 'dayjs'
import { nanoid } from 'nanoid'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { notEmpty } from '../../helper/not-empty'
import weekdays from '../../lib/weekdays'
import {
  Contract,
  Institution,
  ModuleMutation,
  ModuleMutationCancellationReason,
  ModuleMutationType,
} from '../../types'
import { Field } from '../form/field'
import { LocalizedDatePicker } from '../localized-date-picker'
import SelectableModuleList from './selectable-module-list'
const { Title } = Typography

type Props = {
  contract: Contract
  institution: Institution
}

const ModuleCancellationButton = ({ institution, contract }: Props) => {
  const [moduleCancellationModalOpen, setModuleCancellationModalOpen] = useState(false)
  const [showResult, setShowResult] = useState(false)

  const [selectedModuleIds, setSelectedModuleIds] = useState<string[]>([])
  const [message, setMessage] = useState('')
  const [reason, setReason] = useState<ModuleMutationCancellationReason | undefined>(undefined)
  const contractStartDate = dayjs(contract.startDate).format('YYYY-MM-DD')
  const todayStartDate = dayjs(new Date()).format('YYYY-MM-DD')
  const defaultStartDate = contractStartDate < todayStartDate ? todayStartDate : contractStartDate
  const [startDate, setStartDate] = useState<string>(defaultStartDate)
  const [endDate, setEndDate] = useState<string>(defaultStartDate)
  const navigate = useNavigate()

  const allModules = institution.locations.flatMap((location) => location.modules)

  const bookedModules = contract.bookedModules
    .map((booking) => allModules.find((module) => booking.moduleId === module.id))
    .filter(notEmpty)

  const compileModuleCancellationBody = (): Omit<ModuleMutation, 'approvalState'>[] => {
    let result: Omit<ModuleMutation, 'approvalState'>[] = []
    if (selectedModuleIds.length > 0 && reason !== undefined && message !== '') {
      for (
        let currentDate = dayjs(startDate);
        !currentDate.isAfter(dayjs(endDate));
        currentDate = currentDate.add(1, 'day')
      ) {
        const currentWeekdayValue = currentDate.weekday()
        const currentWeekday = weekdays.find((weekday) => weekday.value === currentWeekdayValue)
        let moduleMutationsForCurrentDay: Omit<ModuleMutation, 'approvalState'>[] = []

        selectedModuleIds.forEach((id) => {
          const module = bookedModules.find((m) => m.id === id)
          if (
            module &&
            currentWeekday &&
            weekdays.find((weekday) => weekday.name === module.weekday)?.value === currentWeekday.value
          ) {
            moduleMutationsForCurrentDay.push({
              id: nanoid(),
              createdAt: new Date(),
              contractId: contract.id,
              moduleId: id,
              comment: message,
              effectiveAt: dayjs(currentDate).format('YYYY-MM-DD'),
              mutationType: 'cancellation' as ModuleMutationType,
              reason: reason,
            })
          }
        })
        result = [...result, ...moduleMutationsForCurrentDay]
      }
    } else {
      throw new Error('module Cancellation incomplete before submit')
    }
    return result
  }

  const onSubmitModuleCancellation = async () => {
    const body = JSON.stringify(compileModuleCancellationBody())

    await fetch(
      `${process.env.REACT_APP_LEOBA_SERVER}/api/eltern-app/${institution?.id}/guardian/module-mutation?contractId=${contract.id}`,
      {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
        },
        body,
      }
    )

    setMessage('')
    setReason(undefined)
    setSelectedModuleIds([])
    setShowResult(true)
  }
  const onCloseResult = () => {
    setModuleCancellationModalOpen(false)
    navigate('../cancellations')
  }

  const isReadyToSubmit = (): boolean => {
    return selectedModuleIds.length > 0 && reason !== undefined && message !== ''
  }

  return (
    <>
      <Button
        style={{ flex: '1' }}
        onClick={() => {
          setModuleCancellationModalOpen(true)
        }}
      >
        {t({ message: 'Modul abmelden' })}
      </Button>
      <Modal open={moduleCancellationModalOpen} footer={null} onCancel={() => setModuleCancellationModalOpen(false)}>
        {!showResult && (
          <>
            <Row>
              <Title level={3}>{t({ message: 'Modul abmelden' })}</Title>
            </Row>
            <Field label={t({ message: 'Datum' })}>
              <LocalizedDatePicker
                futureStartDate={dayjs(contract.startDate)}
                value={startDate}
                onlyAllow="future"
                onChange={(value: string) => {
                  setStartDate(value)
                  if (value > endDate) {
                    setEndDate(value)
                  }
                }}
              />
            </Field>
            <Field label={t({ message: 'Bis' })}>
              <LocalizedDatePicker
                futureStartDate={dayjs(contract.startDate)}
                value={endDate}
                onlyAllow="future"
                onChange={(value: string) => {
                  setEndDate(value)
                  if (value < startDate) {
                    setStartDate(value)
                  }
                }}
              />
            </Field>

            <SelectableModuleList
              startDate={startDate}
              endDate={endDate}
              selectedModuleIds={selectedModuleIds}
              setSelectedModuleIds={setSelectedModuleIds}
              modules={bookedModules}
              prices={[]}
            />

            <Field label={t({ message: 'Grund' })}>
              <Select id="sex" style={{ width: '100%' }} value={reason} onChange={setReason}>
                <Select.Option key={'holiday'} value={'holiday'}>
                  {t({ message: 'Ferien' })}
                </Select.Option>
                <Select.Option key={'sickness'} value={'sickness'}>
                  {t({ message: 'Krankheit' })}
                </Select.Option>
                <Select.Option key={'other'} value={'other'}>
                  {t({ message: 'Sonstiges' })}
                </Select.Option>
              </Select>
            </Field>

            <Field label={t({ message: 'Notiz' })}>
              <TextArea
                style={{ width: '100%' }}
                autoSize={{ minRows: 4, maxRows: 6 }}
                placeholder={t({ message: 'Sie müssen eine Notiz hinterlegen um die Buchung abzuschliessen.' })}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </Field>
            <Row>
              <Button
                disabled={!isReadyToSubmit()}
                onClick={onSubmitModuleCancellation}
                type="primary"
                style={{ marginTop: '1em', width: '100%' }}
              >
                {t({ message: 'Modul abmelden' })}
              </Button>
            </Row>
          </>
        )}
        {showResult && (
          <Result
            icon={<SmileOutlined style={{ color: 'var(--main-accent-color)' }} />}
            title={<Title level={4}>{t({ message: `Die Abmeldung wurde an ${institution.name} übermittelt.` })}</Title>}
            extra={
              <Button style={{ marginTop: '1em', width: '100%' }} type="primary" onClick={onCloseResult}>
                {t({ message: 'Weiter' })}
              </Button>
            }
          />
        )}
      </Modal>
    </>
  )
}

export default ModuleCancellationButton
