import { Checkbox } from 'antd'
import { useEffect, useState } from 'react'
import formatChf from '../../helper/format-chf'
import { Module } from '../../types'

type Props = {
  module: Module
  onChange: (selectedModuleIds: string[]) => void
  selectedModuleIds: string[]
  showPrices: boolean
  price: number | null
}

const SelectableModule = ({ module, onChange, selectedModuleIds, showPrices, price }: Props) => {
  const time = `${module.startTime} - ${module.endTime}`
  const moduleText = `${time} ${module.name} ${price && showPrices ? ' - ' + formatChf(price) : ''}`
  const [selected, setSelected] = useState(selectedModuleIds.find((id) => module.id === id) ? true : false)

  const onChangeHandler = (value: boolean) => {
    if (value) {
      onChange([...selectedModuleIds, module.id])
    } else {
      const foundIndex = selectedModuleIds.findIndex((id) => module.id === id)
      const arrayCopy = selectedModuleIds
      arrayCopy.splice(foundIndex, 1)
      onChange([...arrayCopy])
    }
  }

  useEffect(() => {
    setSelected(selectedModuleIds.find((id) => module.id === id) ? true : false)
  }, [module.id, selectedModuleIds])

  return (
    <li>
      <Checkbox checked={selected} onChange={() => onChangeHandler(!selected)}>
        {moduleText}
      </Checkbox>
    </li>
  )
}

export default SelectableModule
