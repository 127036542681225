import { Select } from 'antd'
import dayjs from 'dayjs'
import { useEffect, useRef } from 'react'

export const TimeStringInput = ({
  min = '00:00',
  max = '24:00',
  step = 5,
  value,
  onChange,
  autoFocus = false,
  className,
}: {
  min?: string
  max?: string
  step?: number
  value: string | null
  onChange: (value: string | null) => void
  autoFocus?: boolean
  className?: string
}) => {
  const ref = useRef<React.ElementRef<typeof Select>>(null)
  useEffect(() => {
    if (autoFocus) ref.current?.focus()
  }, [autoFocus])
  return (
    <Select
      onClick={(e) => e.preventDefault()}
      ref={ref}
      className={className}
      style={{ minWidth: '6em' }}
      value={value + ''}
      onChange={onChange}
      showSearch
      options={generateTimes(min, max, step).map((time) => ({ label: time, value: time }))}
      filterOption={(search, option) =>
        option?.label.includes(search) || option?.label.replace(':', '').includes(search) || false
      }
    />
  )
}

export const generateTimes = (startTime: string, endTime: string, step = 30) => {
  const start = dayjs(startTime, 'HH:mm')
  const end = dayjs(endTime, 'HH:mm')

  const times: string[] = []
  let currentTime = start

  while (currentTime.isBefore(end) || currentTime.isSame(end)) {
    times.push(currentTime.format('HH:mm'))
    currentTime = currentTime.add(step, 'minutes')
  }

  return times
}
