import { Capacitor } from '@capacitor/core'

export const appInfo = (apiDomain: string) => {
  const websiteDomainForBrowser = window?.location ? `${window.location.protocol}//${window.location.host}` : apiDomain
  const websiteDomainForAllPlatforms = Capacitor.isNativePlatform() ? apiDomain : websiteDomainForBrowser
  return {
    // learn more about this on https://supertokens.com/docs/thirdpartyemailpassword/appinfo
    appName: 'Leoba',
    apiDomain: apiDomain,
    websiteDomain: websiteDomainForAllPlatforms,
    apiBasePath: '/api/auth',
    websiteBasePath: '/auth',
  }
}
