import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Modal } from 'antd'
import { useState } from 'react'
import formatChf from '../../../helper/format-chf'
import { toDateWithWeekday } from '../../../helper/format-date'
import { useInstitutionContext } from '../../../routes/institution/institution-context'
import ChildHolidayCareForm, {
  NewHolidayCareBookingType,
  compareHolidayCare,
} from '../child-form/child-holiday-care-form'
import ChildCardSection from './child-card-section'

type Props = {
  childId: string
  defaultValues?: NewHolidayCareBookingType[]
  onSubmitEditedHolidayCare: (information: NewHolidayCareBookingType[], childId: string) => void
}

const ChildCardHolidayCareSection = ({ childId, defaultValues, onSubmitEditedHolidayCare }: Props) => {
  const { i18n } = useLingui()
  const { institution } = useInstitutionContext()
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)

  const showEditModal = () => {
    setIsEditModalOpen(true)
  }

  const handleEditModalOk = async (data: NewHolidayCareBookingType[]) => {
    onSubmitEditedHolidayCare(data, childId)
    setIsEditModalOpen(false)
  }

  const handleEditModalCancel = () => {
    setIsEditModalOpen(false)
  }

  const handleOnEdit = () => {
    showEditModal()
  }

  const holidayCare = (institution?.holidayCare || []).sort(compareHolidayCare)
  const bookedHolidayCare = holidayCare.filter((hc) => defaultValues?.some((booking) => booking.id === hc.id))

  if (holidayCare.length > 0) {
    return (
      <ChildCardSection onEdit={handleOnEdit} title={t({ message: 'Gewählte Ferienbetreuung' })}>
        <>
          {defaultValues && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {bookedHolidayCare.map((careModule) => (
                <div key={careModule.id}>
                  {toDateWithWeekday(careModule.date, i18n.locale)} {careModule.startTime} - {careModule.endTime}:{' '}
                  {careModule.title}, {formatChf(careModule.cost.toNumber())}
                </div>
              ))}
            </div>
          )}
          {isEditModalOpen && (
            <Modal
              title={t({ message: 'Ferienbetreuung bearbeiten' })}
              open={isEditModalOpen}
              onCancel={handleEditModalCancel}
              footer={null}
              width="fit-content"
            >
              <ChildHolidayCareForm
                holidayCare={holidayCare}
                isEditMode
                onSubmit={handleEditModalOk}
                defaultValues={defaultValues}
              />
            </Modal>
          )}
        </>
      </ChildCardSection>
    )
  } else {
    return <></>
  }
}

export default ChildCardHolidayCareSection
