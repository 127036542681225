import { Trans, t } from '@lingui/macro'
import { Row, Typography } from 'antd'
import dayjs from 'dayjs'
import { useEffect, useMemo, useState } from 'react'
import { useInstitutionContext } from '../../../../routes/institution/institution-context'
import { ModuleBooking } from '../../../../routes/institution/registration/registration-context'
import {
  AgeGroup,
  Institution,
  Location,
  Module,
  School,
  TaxInformation,
  ModuleBooking as DomainModuleBooking,
  Contract,
  RegistrationConfiguration,
} from '../../../../types'
import ErrorMessage from '../../../error-message'
import ButtonSection from '../../../form/button-section'
import { Field } from '../../../form/field'
import { LocalizedDatePicker } from '../../../localized-date-picker'
import ModuleBookingViewAllLocations from './module-booking-view-all-locations'
import NewModulesTotalPrice from './new-modules-total-price'
import { guessAgeGroup } from './guess-age-gruop'
import { useModulePrices } from './use-module-prices'
import { getAgeGroupFromPreviousContract } from '../../../../routes/institution/guardian/next-school-year/get-age-group-from-previous-contract'
const { Title } = Typography

type Props = {
  handleSubmit: (data: ModuleBooking) => Promise<void>
  handlePrevious?: (data: ModuleBooking) => void
  defaultValues?: ModuleBooking
  submitButtonText?: string
  modulesToExclude?: DomainModuleBooking[]
  schoolId: string
  taxInformation: TaxInformation | undefined
  previousContract?: Contract | undefined
}

export type ModuleChoice = Module & {
  chosen: boolean
  ageGroup?: AgeGroup
  location: Location
  comingFrom?: string
  comingFromTime?: string
  goingTo?: string
  goingToTime?: string
}

const ModuleFormWithSchool = ({
  handleSubmit,
  handlePrevious,
  defaultValues,
  submitButtonText,
  modulesToExclude = [],
  schoolId,
  taxInformation,
  previousContract,
}: Props) => {
  const { institution } = useInstitutionContext()
  const locations = institution ? institution.locations : []
  const school = institution?.schools.find((school) => schoolId === school.id)
  const possibleStartDates = useMemo(() => institution?.registrationConfig.possibleStartDates || [], [institution])
  const [moduleChoices, setModuleChoices] = useState<ModuleChoice[]>(
    createModuleChoices(institution, defaultValues?.bookings || [], modulesToExclude, school, previousContract ?? null)
  )
  const [startDate, setStartDate] = useState<Date | undefined>(
    defaultValues?.startDate ? defaultValues?.startDate : undefined
  )

  const [adjustmentPeriodStartDate, setAdjustmentPeriodStartDate] = useState<Date | undefined>(
    defaultValues?.adjustmentPeriodStartDate ? defaultValues?.adjustmentPeriodStartDate : undefined
  )

  useEffect(() => {
    if (startDate === undefined) {
      if (possibleStartDates.length > 0) {
        const today = dayjs(Date.now())
        for (let dayShift = 0; dayShift < 366; dayShift++) {
          const testDate = today.add(dayShift, 'day')
          const day = testDate.get('date')
          const month = testDate.get('month') + 1
          const matches = possibleStartDates.some((possibleDate) => {
            const split = possibleDate.split('.')
            const possibleDay = parseInt(split[0])
            const possibleMonth = parseInt(split[1])
            return possibleDay === day && possibleMonth === month
          })
          if (matches) {
            setStartDate(testDate.toDate())
            break
          }
        }
      }
    }
  }, [possibleStartDates, possibleStartDates.length, startDate])

  const primaryLocation =
    locations.length === 0
      ? undefined
      : locations
          .map((location) => ({
            location,
            moduleCount: moduleChoices.filter((mod) => mod.location.id === location.id).length,
          }))
          .reduce((acc, curr) => (acc.moduleCount > curr.moduleCount ? acc : curr)).location

  const [validationError, setValidationError] = useState<string | undefined>(undefined)

  const prices = useModulePrices({
    institutionId: institution!.id,
    locationId: '',
    ageGroupId: '',
    selection: useMemo(
      () =>
        moduleChoices.map((choice) => ({
          moduleId: choice.id,
          ageGroupId: choice.ageGroup?.id ?? '',
        })),
      [moduleChoices]
    ),
  })

  const handleOnChangeStartDate = (date: string) => {
    setStartDate(dayjs(date).toDate())
  }

  const handleOnChangeAdjustmentPeriodStartDate = (date: string) => {
    setAdjustmentPeriodStartDate(dayjs(date).toDate())
  }

  const handleModuleChange = (
    moduleId: string,
    checked: boolean,
    ageGroupId: string | undefined,
    comingFromGoingTo: { comingFrom?: string; comingFromTime?: string; goingTo?: string; goingToTime?: string }
  ) => {
    if (validationError) {
      setValidationError(undefined)
    }
    const currentChoices = [...moduleChoices]
    const currentModule = currentChoices.find((choice) => choice.id === moduleId)
    if (currentModule) {
      currentModule.chosen = checked
      currentModule.ageGroup = currentModule.location.ageGroups.find((ag) => ag.id === ageGroupId)
      currentModule.comingFrom = comingFromGoingTo.comingFrom
      currentModule.comingFromTime = comingFromGoingTo.comingFromTime
      currentModule.goingTo = comingFromGoingTo.goingTo
      currentModule.goingToTime = comingFromGoingTo.goingToTime
      if (institution?.features.subsidiesHuenenberg) {
        moduleChoices.forEach((moduleChoice) => {
          if (
            !moduleChoice.ageGroup &&
            moduleChoice.ageGroups.some((ag) => ag.ageGroupId === currentModule.ageGroup?.id)
          ) {
            moduleChoice.ageGroup = currentModule.ageGroup
          }
        })
      }
      setModuleChoices(currentChoices)
    }
  }

  const handleModuleSubmit = async () => {
    const chosenModules = moduleChoices.filter((choice) => choice.chosen)
    if (!startDate) {
      setValidationError(t({ message: 'Bitte Startdatum wählen.' }))
    } else if (moduleChoicesIncomplete(institution, chosenModules)) {
      setValidationError('Bitte füllen Sie die rot markierten Felder aus.')
    } else if (
      institution?.registrationConfig.askForAdjustmentPeriodStartDate === 'mandatory' &&
      !adjustmentPeriodStartDate
    ) {
      setValidationError(t({ message: 'Bitte Startdatum der Eingewöhnung wählen.' }))
    } else {
      const moduleBooking: ModuleBooking = {
        startDate,
        adjustmentPeriodStartDate,
        locationId: guessLocation(locations, chosenModules).id,
        ageGroup: guessAgeGroup(locations, chosenModules),
        flexibleBooking: false,
        bookings: chosenModules.map((chosen) => ({
          moduleId: chosen.id,
          ageGroupId: chosen.ageGroup!.id,
          comingFrom: chosen.comingFrom,
          comingFromTime: chosen.comingFromTime,
          goingTo: chosen.goingTo,
          goingToTime: chosen.goingToTime,
        })),
      }
      await handleSubmit(moduleBooking)
    }
  }
  const moveBack = () => {
    if (handlePrevious) {
      const chosenModules = moduleChoices.filter((choice) => choice.chosen)
      const moduleBooking: ModuleBooking = {
        startDate,
        locationId: guessLocation(locations, chosenModules).id,
        ageGroup: guessAgeGroup(locations, chosenModules),
        flexibleBooking: false,
        bookings: chosenModules.map((chosen) => ({
          moduleId: chosen.id,
          ageGroupId: chosen.ageGroup!.id,
          comingFrom: chosen.comingFrom,
          comingFromTime: chosen.comingFromTime,
          goingTo: chosen.goingTo,
          goingToTime: chosen.goingToTime,
        })),
      }
      handlePrevious(moduleBooking)
    }
  }

  return (
    <>
      <Field label={t({ message: 'Startdatum der Betreuung' })}>
        <LocalizedDatePicker
          value={startDate}
          onChange={handleOnChangeStartDate}
          onlyAllow="future"
          onlyAllowSpecificDates={possibleStartDates}
        />
      </Field>

      {startDate && institution?.registrationConfig.askForAdjustmentPeriodStartDate !== 'off' && (
        <Field label={t({ message: 'Startdatum der Eingewöhnung' })}>
          <LocalizedDatePicker
            value={adjustmentPeriodStartDate}
            max={startDate}
            onChange={handleOnChangeAdjustmentPeriodStartDate}
          />
        </Field>
      )}

      {institution && school && primaryLocation && startDate && (
        <>
          <Title level={2}>
            {institution.schools.length > 1 ? (
              <>
                <Trans>Betreuungsangebote für</Trans> {school.name}
              </>
            ) : (
              <Trans>Betreuungsangebote</Trans>
            )}
          </Title>

          {moduleChoices.length > 0 && startDate && (
            <ModuleBookingViewAllLocations
              primaryLocation={primaryLocation}
              moduleChoices={moduleChoices}
              onModuleChange={handleModuleChange}
              prices={prices}
            />
          )}
        </>
      )}

      <NewModulesTotalPrice taxInformation={taxInformation} moduleChoices={moduleChoices} />

      <Row style={{ flexDirection: 'row-reverse' }}>
        {!!validationError && <ErrorMessage message={validationError} />}
      </Row>

      <ButtonSection
        submitButtonText={submitButtonText}
        onNext={handleModuleSubmit}
        onPrevious={handlePrevious && moveBack}
      />
    </>
  )
}

const guessLocation = (locations: Location[], chosenModules: Module[]) => {
  const chosenModuleIds = chosenModules.map((mod) => mod.id)
  return locations
    .map((loc) => ({
      location: loc,
      moduleCount: loc.modules.filter((mod) => chosenModuleIds.indexOf(mod.id) >= 0).length,
    }))
    .reduce((acc, curr) => (acc.moduleCount > curr.moduleCount ? acc : curr)).location
}

const createModuleChoices = (
  institution: Institution | undefined,
  bookings: {
    moduleId: string
    ageGroupId: string
    comingFrom?: string
    comingFromTime?: string
    goingTo?: string
    goingToTime?: string
  }[],
  modulesToExclude: DomainModuleBooking[],
  school: School | undefined,
  previousContract: Contract | null
): ModuleChoice[] => {
  if (institution) {
    return institution.locations
      .flatMap((location) => {
        return location.modules.map((module) => {
          const booking = bookings.find((bk) => bk.moduleId === module.id)
          const chosen = booking === undefined ? false : true
          const ageGroup =
            booking !== undefined
              ? location.ageGroups.find((ag) => ag.id === booking.ageGroupId)
              : location.ageGroups.length === 1
              ? location.ageGroups[0]
              : module.ageGroups.length === 1
              ? location.ageGroups.find((ag) => ag.id === module.ageGroups[0].ageGroupId)
              : institution.features.adoptGroupFromPreviousYear && previousContract
              ? getAgeGroupFromPreviousContract({ contract: previousContract, location, module })
              : undefined
          const comingFrom = booking?.comingFrom
          const comingFromTime = booking?.comingFromTime
          const goingTo = booking?.goingTo
          const goingToTime = booking?.goingToTime
          return { location, chosen, ...module, ageGroup, comingFrom, comingFromTime, goingTo, goingToTime }
        })
      })
      .filter((module) => school === undefined || school.moduleIds.indexOf(module.id) >= 0)
      .filter((module) => !modulesToExclude.some((booking) => module.id === booking.moduleId))
  } else {
    return []
  }
}

const moduleChoicesIncomplete = (
  institution: (Institution & { registrationConfig: RegistrationConfiguration }) | undefined,
  moduleChoices: ModuleChoice[]
) => {
  const comingFromGoingToIsMandatory =
    institution?.registrationConfig.askForLocationBeforeAndAfterModule === 'mandatory'
  const comingFromGoingToIncomplete = moduleChoices.some(
    (mod) => !mod.comingFrom || !mod.comingFromTime || !mod.goingTo || !mod.goingToTime || !mod.ageGroup
  )
  const comingFromGoingToIncompleteHuenenberg = moduleChoices.some(
    (mod) => !mod.comingFrom || !mod.goingTo || (mod.name === 'Modul D' && !mod.goingToTime)
  )

  return (
    comingFromGoingToIsMandatory &&
    (institution?.features.subsidiesHuenenberg ? comingFromGoingToIncompleteHuenenberg : comingFromGoingToIncomplete)
  )
}

export default ModuleFormWithSchool
