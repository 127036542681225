import { t } from '@lingui/macro'
import { useLocation } from 'react-router-dom'
import ModuleFormWithSchool from '../../../../../components/child/child-form/module-form/module-form-with-school'
import ModuleFormNoSchool from '../../../../../components/child/child-form/module-form/module-form-no-school'
import FormPage from '../../../../../components/form/form-page'
import { ModuleBooking } from '../../registration-context'
import './module-form-page-styles.css'
import { TaxInformation, ModuleBooking as DomainModuleBooking } from '../../../../../types'

type Props = {
  progress?: number
  handleSubmit: (data: ModuleBooking) => Promise<void>
  handlePrevious: (data: ModuleBooking) => void
  defaultValues?: ModuleBooking
  schoolId?: string
  taxInformation: TaxInformation | undefined
}

const ModuleFormPage = ({
  handleSubmit,
  handlePrevious,
  defaultValues,
  progress = 6,
  schoolId,
  taxInformation,
}: Props) => {
  const location = useLocation()
  const alreadyBookedModules: DomainModuleBooking[] | undefined = location.state?.alreadyBookedModules

  return (
    <FormPage title={t({ message: 'Modulauswahl' })} progress={progress}>
      {schoolId ? (
        <ModuleFormWithSchool
          modulesToExclude={alreadyBookedModules && alreadyBookedModules}
          schoolId={schoolId}
          handleSubmit={handleSubmit}
          handlePrevious={handlePrevious}
          defaultValues={defaultValues}
          taxInformation={taxInformation}
        />
      ) : (
        <ModuleFormNoSchool
          modulesToExclude={alreadyBookedModules && alreadyBookedModules}
          handleSubmit={handleSubmit}
          handlePrevious={handlePrevious}
          defaultValues={defaultValues}
        />
      )}
    </FormPage>
  )
}

export default ModuleFormPage
