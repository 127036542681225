import { t } from '@lingui/macro'
import { Button, Row, Typography } from 'antd'
import { nanoid } from 'nanoid'
import { useEffect, useState } from 'react'
import LeobaMarkdown from '../../../../components/LeobaMarkdown'
import ButtonSection from '../../../../components/form/button-section'
import FormPage from '../../../../components/form/form-page'
import SignatureInput from '../../../../components/signature-input'
import { Guardian, SignatureData } from '../../../../types'
import getLeafletUrl from '../../get-leaflet-url'
import { useInstitutionContext } from '../../institution-context'

const { Text } = Typography

type Props = {
  handleSubmit: (data: SignatureData) => Promise<void>
  handlePrevious: () => void
  defaultValues?: SignatureData
  progress?: number
  guardian1: Guardian | undefined
  guardian2: Guardian | undefined
}

const ConfirmationFormPage = ({
  handleSubmit,
  handlePrevious,
  defaultValues,
  progress = 9,
  guardian1,
  guardian2,
}: Props) => {
  const { institution } = useInstitutionContext()

  const [leafletUrl, setLeafletUrl] = useState<string | undefined>(undefined)
  const [signature1, setSignature1] = useState<string | undefined>(defaultValues?.signature1?.signature || undefined)
  const [signature2, setSignature2] = useState<string | undefined>(defaultValues?.signature2?.signature || undefined)
  const [signature1Error, setSignature1Error] = useState<string | undefined>(undefined)
  const [signature2Error, setSignature2Error] = useState<string | undefined>(undefined)
  const leafletName = institution?.registrationConfig.leafletName || t({ message: 'Merkblatt' })

  const currentDate = new Date()

  const showSecondSignature = !institution?.registrationConfig.enableSingleSignature && guardian2 !== undefined
  const guardiansSignature1 = [guardian1, showSecondSignature ? undefined : guardian2].filter((g): g is Guardian => !!g)
  const guardiansSignature2 = [showSecondSignature ? guardian2 : undefined].filter((g): g is Guardian => !!g)

  useEffect(() => {
    if (institution) {
      const getLeaflet = async () => {
        const url = await getLeafletUrl(institution.id)
        setLeafletUrl(url)
      }
      getLeaflet()
    }
  }, [institution])

  const handleSignatureBegin = (guardian: Guardian) => {
    if (guardian1?.id === guardian.id) {
      setSignature1Error(undefined)
    } else {
      setSignature2Error(undefined)
    }
  }

  const handleSignatureEnd = (guardian: Guardian, dataURL: string) => {
    if (guardian1?.id === guardian.id) {
      setSignature1(dataURL)
    } else {
      setSignature2(dataURL)
    }
  }

  const handleSignatureClear = (guardian: Guardian) => {
    if (guardian1?.id === guardian.id) {
      setSignature1(undefined)
    } else {
      setSignature2(undefined)
    }
  }

  const onSubmit = async () => {
    let sig1Error: string | undefined = undefined
    let sig2Error: string | undefined = undefined
    if (!signature1) {
      sig1Error = 'error'
    }

    if (!signature2) {
      sig2Error = 'error'
    }

    if (!guardian2) {
      sig2Error = undefined
    }

    if (institution?.registrationConfig.enableSingleSignature && (signature1 || signature2)) {
      sig1Error = undefined
      sig2Error = undefined
    }

    if (!sig1Error && !sig2Error) {
      const signatureData: SignatureData = {
        contractId: defaultValues?.contractId || nanoid(),
        signatureDate: currentDate,
      }
      if (!!signature1) {
        signatureData.signature1 = {
          guardianId: guardian1?.id,
          signature: signature1,
        }
      }
      if (!!signature2) {
        signatureData.signature2 = {
          guardianId: guardian2?.id,
          signature: signature2,
        }
      }
      await handleSubmit(signatureData)
    }
    setSignature1Error(sig1Error)
    setSignature2Error(sig2Error)
  }

  const institutionName = institution ? institution.name : t({ message: 'Institution' })

  return (
    <FormPage title={t({ message: 'Abschluss' })} progress={progress}>
      <>
        {institution?.registrationConfig.beforeSubmitText ? (
          <LeobaMarkdown markdown={institution?.registrationConfig.beforeSubmitText} />
        ) : (
          <>
            <Row style={{ marginBlock: '1em' }}>
              <Text>
                {institution?.registrationConfig.leafletName !== ''
                  ? 'Anbei blenden wir Ihnen unser ' +
                    institution?.registrationConfig.leafletName +
                    ' ein. Bitte lesen Sie dieses sorgfältig durch.'
                  : t({
                      message:
                        'Anbei blenden wir Ihnen unser Elternmerkblatt ein. Bitte lesen Sie dieses sorgfältig durch.',
                    })}
              </Text>
            </Row>
            <Row style={{ marginBlock: '1em' }}>
              <Button type="link" href={leafletUrl} target="_blank" rel="noopener noreferrer">
                {institution?.registrationConfig.leafletName !== ''
                  ? institution?.registrationConfig.leafletName
                  : t({ message: `Merkblatt` })}
              </Button>
            </Row>
            <Row style={{ marginBlock: '1em' }}>
              <Text>
                {t({
                  message: `Mit Ihrer Unterschrift bestätigen Sie, das ${leafletName} gelesen zu haben, und erklären sich mit sämtlichen erläuterten Bedingungen des ${institutionName} einverstanden.`,
                })}
              </Text>
            </Row>
          </>
        )}

        <SignatureInput
          onBegin={handleSignatureBegin}
          onEnd={handleSignatureEnd}
          onClear={handleSignatureClear}
          signatureError={guardiansSignature1.length === 1 ? signature1Error : signature1Error && signature2Error}
          guardians={guardiansSignature1}
        />

        {guardiansSignature2.length > 0 && (
          <SignatureInput
            onBegin={handleSignatureBegin}
            onEnd={handleSignatureEnd}
            onClear={handleSignatureClear}
            signatureError={signature2Error}
            guardians={guardiansSignature2}
          />
        )}

        <ButtonSection onNext={onSubmit} onPrevious={handlePrevious} submitButtonText={t({ message: 'Absenden' })} />
      </>
    </FormPage>
  )
}
export default ConfirmationFormPage
