import { BorderOutlined, CheckSquareOutlined } from '@ant-design/icons'
import { t } from '@lingui/macro'
import { List, Typography } from 'antd'
import { Link } from 'react-router-dom'
import FormPage from '../../../../components/form/form-page'
import { getNewSchoolYearTasks } from '../../../../domain/family/new-school-year-tasks'
import { useInstitutionContext } from '../../institution-context'
import { useGuardianContext } from '../guardian-context'

const { Title } = Typography

const OverviewPage = () => {
  const { family } = useGuardianContext()
  const { institution } = useInstitutionContext()

  const tasks = getNewSchoolYearTasks(family, institution?.registrationConfig)

  const childCount = family?.children.length || 0

  return (
    <FormPage title={t({ message: 'Anmeldung für nächstes Schuljahr' })}>
      <>
        <section>
          {childCount === 1
            ? t({
                message:
                  'Bitte verfollständigen Sie folgende Angaben um Ihr Kind für das nächste Schuljahr wieder anzumelden:',
              })
            : t({
                message:
                  'Bitte verfollständigen Sie folgende Angaben um Ihre Kinder für das nächste Schuljahr wieder anzumelden:',
              })}
        </section>
        <section>
          <Title level={4}>{t`Angaben überprüfen`}</Title>
          <List bordered size="small">
            <List.Item>
              <div>
                {tasks.guardiansChecked ? (
                  <>
                    <CheckSquareOutlined style={{ marginRight: '1em' }} />
                    {t({ message: 'Erziehungsberechtigte Personen' })}
                  </>
                ) : (
                  <>
                    <BorderOutlined style={{ marginRight: '1em' }} />
                    <Link to={'./check-guardian-data/guardian-one'}>
                      {t({ message: 'Erziehungsberechtigte Personen' })}
                    </Link>
                  </>
                )}
              </div>
            </List.Item>
          </List>
        </section>
        <section>
          <Title level={4}>{t`Ausstehende Anmeldungen`}</Title>
          <List
            bordered
            dataSource={tasks.contractsToCreate}
            size="small"
            renderItem={(item) => (
              <List.Item key={item.childId}>
                <div>
                  <BorderOutlined style={{ marginRight: '1em' }} />
                  <Link to={`./add-child-contract/${item.childId}/base-information`}>{item.firstName}</Link>
                </div>
              </List.Item>
            )}
          />
        </section>
        <section>
          <Title level={4}>{t`Bereits erledigte Anmeldungen`}</Title>
          <List
            bordered
            dataSource={tasks.contractsCreated}
            size="small"
            renderItem={(item) => (
              <List.Item key={item.childId}>
                <div>
                  <CheckSquareOutlined style={{ marginRight: '1em' }} />
                  {item.firstName}
                </div>
              </List.Item>
            )}
          />
        </section>
      </>
    </FormPage>
  )
}

export { OverviewPage }
