import { t } from '@lingui/macro'
import { BookingMutationApprovalState } from '../types'

const translateBookingMutationApprovalState = (bookingMutationApprovalState: BookingMutationApprovalState): string => {
  let result
  switch (bookingMutationApprovalState) {
    case 'new':
      result = t({ message: 'Bestätigung noch offen' })
      break
    case 'accepted':
      result = t({ message: 'Bestätigt' })
      break
    case 'rejected':
      result = t({ message: 'Abgelehnt' })
      break
  }
  return result
}

export default translateBookingMutationApprovalState
