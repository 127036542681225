import { Input } from 'antd'
import { HTMLInputTypeAttribute } from 'react'
import { Control, Controller, ControllerProps } from 'react-hook-form'

type Props = {
  control: Control<any, any>
  name: string
  required?: boolean
  disabled?: boolean
  type?: HTMLInputTypeAttribute
  maxLength?: number
  validate?: Exclude<ControllerProps['rules'], undefined>['validate']
  autocomplete?: string | undefined
}

const SingleLineTextInput = ({
  name,
  control,
  required = false,
  disabled = false,
  type,
  validate,
  maxLength = undefined,
  autocomplete,
}: Props) => {
  return (
    <Controller
      name={name}
      rules={{
        required,
        ...(type === 'email' && { pattern: /\S+@\S+\.\S+/ }),
        ...(validate && !disabled && { validate }),
      }}
      control={control}
      render={({ field }) => (
        <Input disabled={disabled} {...field} type={type || 'text'} maxLength={maxLength} autoComplete={autocomplete} />
      )}
    />
  )
}

export default SingleLineTextInput
