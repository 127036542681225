import { ClearOutlined } from '@ant-design/icons'
import { t } from '@lingui/macro'
import { Button, Col, Radio, RadioChangeEvent, Row, Space } from 'antd'
import { useRef, useState } from 'react'
import ReactSignatureCanvas from 'react-signature-canvas'
import { ErrorMark } from './error-message'
import { Typography } from 'antd'
import { Guardian } from '../types'
import { toDateString } from '../helper/format-date'

const { Text } = Typography

type Props = {
  onBegin: (guardian: Guardian) => void
  onEnd: (guardian: Guardian, signatureDataURL: string) => void
  onClear: (guardian: Guardian) => void
  signatureError?: string
  guardians: Guardian[]
}

const SignatureInput = ({ onBegin, onEnd, onClear, signatureError, guardians }: Props) => {
  const signatureRef = useRef<ReactSignatureCanvas>(null)
  const [activeGuardian, setActiveGuardian] = useState(guardians[0])

  const handleOnChangeGuardian = (e: RadioChangeEvent) => {
    const selected = guardians.find((g) => g.id === e.target.value)
    if (selected) {
      handleOnClear()
      setActiveGuardian(selected)
    }
  }

  const handleOnClear = () => {
    signatureRef.current?.clear()
    onClear(activeGuardian)
  }

  const handleOnEnd = () => {
    if (signatureRef.current) {
      const dataURL = signatureRef.current.toDataURL('image/jpeg').split(',')[1]
      onEnd(activeGuardian, dataURL)
    }
  }

  return (
    <Row style={{ justifyContent: 'center', marginBlock: '2em' }}>
      <Col>
        <Radio.Group onChange={handleOnChangeGuardian} value={activeGuardian.id}>
          <Space direction="vertical">
            {guardians.map((guardian) => (
              <Radio key={guardian.id} value={guardian.id}>{`${toDateString(new Date())}, ${
                guardian.address.firstName
              } ${guardian.address.lastName}`}</Radio>
            ))}
          </Space>
        </Radio.Group>
        <Row gutter={5}>
          <ReactSignatureCanvas
            backgroundColor="white"
            canvasProps={{
              width: 300,
              height: 150,
              style: {
                display: 'block',
                border: '1px solid var(--main-fg-color)',
              },
            }}
            ref={signatureRef}
            onBegin={() => onBegin(activeGuardian)}
            onEnd={handleOnEnd}
          />
          <Col style={{ display: 'flex', flexDirection: 'column' }}>
            <Button
              type="primary"
              onClick={handleOnClear}
              icon={<ClearOutlined />}
              style={{
                margin: '0.5em 0.5em auto',
              }}
            />
            {!!signatureError && <ErrorMark style={{ margin: '1em auto' }} />}
          </Col>
        </Row>
        <small>
          <Text>{t({ message: 'Bitte in diesem Feld mit Maus oder Finger unterschreiben.' })}</Text>
        </small>
      </Col>
    </Row>
  )
}

export default SignatureInput
