import { Trans, t } from '@lingui/macro'
import { Form, InputNumber, Radio, RadioChangeEvent, Typography } from 'antd'
import Decimal from 'decimal.js'
import { nanoid } from 'nanoid'
import { useState } from 'react'
import formatChf from '../helper/format-chf'
import { useInstitutionContext } from '../routes/institution/institution-context'
import { TaxInformation } from '../types'
import ButtonSection from './form/button-section'
const { Text, Title } = Typography

type Props = {
  initialValues?: TaxInformation
  defaultOutOfTown: boolean | undefined
  familyId: string
  submitButtonText?: string
  handleSubmit: (data: TaxInformation) => void
  handleOutOfTownChild: () => void
  handlePrevious?: () => void
}

const TaxInformationForm = ({
  initialValues,
  defaultOutOfTown,
  familyId,
  submitButtonText,
  handleSubmit,
  handleOutOfTownChild,
  handlePrevious,
}: Props) => {
  const [form] = Form.useForm<TaxInformation>()
  const { institution } = useInstitutionContext()
  const [income, setIncome] = useState<number | undefined>()
  const [isOutOfTown, setOutOfTown] = useState(defaultOutOfTown)

  const initial: Partial<TaxInformation> = initialValues || { contactMeForExtraordinarySubsidy: false }

  const onSubmitHandler = (values: TaxInformation) => {
    const submitValues: TaxInformation = {
      ...values,
      id: initialValues?.id || nanoid(),
      familyId,
    }
    if (submitValues.person1) {
      submitValues.person1.id = initialValues?.person1?.id || nanoid()
    }
    if (submitValues.person2) {
      submitValues.person2.id = initialValues?.person2?.id || nanoid()
    }
    if (isOutOfTown) {
      handleOutOfTownChild()
    } else {
      handleSubmit(submitValues)
    }
  }

  const onChangeResidence = (e: RadioChangeEvent) => {
    setOutOfTown(e.target.value === 'other')
  }

  const watchResidence = Form.useWatch('residence', form)
  const watchWaiveSubsidies = Form.useWatch('waiveSubsidies', form)
  const watchNetWorthExceedsLimit = Form.useWatch('netWorthExceedsLimit', form)
  const watchBothGuardiansLiveInHousehold = Form.useWatch('bothGuardiansLiveInHousehold', form)
  const watchGuardiansAreMarried = Form.useWatch('guardiansAreMarried', form)
  const watchPerson1Workload = Form.useWatch(['person1', 'workload'], form)
  const watchPerson2Workload = Form.useWatch(['person2', 'workload'], form)

  const isHuenenberg = watchResidence === 'huenenberg'
  const stateTaxInformation = isHuenenberg && watchWaiveSubsidies === false
  const collectTaxInformation = stateTaxInformation && watchNetWorthExceedsLimit === false

  const handleOnValuesChange = async (changedValues: any, taxInformation: TaxInformation) => {
    if (institution) {
      const response = await fetch(
        `${process.env.REACT_APP_LEOBA_SERVER}/api/eltern-app/${institution.id}/registration/calculate-income`,
        {
          headers: { accept: 'application/json' },
          method: 'POST',
          body: JSON.stringify(taxInformation),
        }
      )
      if (response.ok) {
        const result = await response.json()
        setIncome(result.income)
      }
    }
  }

  return (
    <Form<TaxInformation>
      form={form}
      name="tax-information"
      initialValues={initial}
      onFinish={onSubmitHandler}
      colon={false}
      wrapperCol={{ style: { paddingLeft: 8 } }}
      labelCol={{
        span: 12,
        style: {
          paddingRight: 8,
          paddingBottom: 8,
          fontWeight: 'bold',
        },
      }}
      labelWrap={true}
      labelAlign="left"
      onValuesChange={handleOnValuesChange}
      requiredMark={false}
    >
      <Form.Item
        name="residence"
        label={t({ message: 'Wohnsitz' }) + ' *'}
        extra={t({
          message:
            'Einwohner aus Hünenberg und Cham können vom Naschu-Angebot von Familie Plus gebrauch machen. Kinder von anderen Gemeinden können nur an Ferienbetreuungen teilnehmen.',
        })}
        rules={[{ required: true, message: t({ message: 'Dieses Feld ist zwingend' }) }]}
        initialValue={isOutOfTown ? 'other' : initialValues?.residence}
      >
        <Radio.Group onChange={onChangeResidence}>
          <Radio value="huenenberg">
            <Trans>Hünenberg</Trans>
          </Radio>
          <Radio value="cham">
            <Trans>Cham</Trans>
          </Radio>
          <Radio value="other">
            <Trans>Andere Gemeinde (Nur Ferienbetreuung)</Trans>
          </Radio>
        </Radio.Group>
      </Form.Item>

      {isHuenenberg && (
        <Form.Item
          name="waiveSubsidies"
          label={
            t({
              message: 'Ich möchte auf finanzielle Angaben verzichten und der Höchsttarif kann verrechnet werden',
            }) + ' *'
          }
          rules={[{ required: true, message: t({ message: 'Dieses Feld ist zwingend' }) }]}
        >
          <Radio.Group>
            <Radio value={true}>
              <Trans>Ja</Trans>
            </Radio>
            <Radio value={false}>
              <Trans>Nein</Trans>
            </Radio>
          </Radio.Group>
        </Form.Item>
      )}

      {stateTaxInformation && (
        <Form.Item
          name="netWorthExceedsLimit"
          label={
            t({
              message: "Das Reinvermögen (Steuererklärung Code 660) übersteigt CHF 350'000",
            }) + ' *'
          }
          rules={[{ required: true, message: t({ message: 'Dieses Feld ist zwingend' }) }]}
        >
          <Radio.Group>
            <Radio value={true}>
              <Trans>Ja</Trans>
            </Radio>
            <Radio value={false}>
              <Trans>Nein</Trans>
            </Radio>
          </Radio.Group>
        </Form.Item>
      )}

      {collectTaxInformation && (
        <>
          <Form.Item
            name={'childrenDeducedInTaxes'}
            label={t({ message: 'Anzahl Kinder (für die ein Steuerabzug gewährt wird)' }) + ' *'}
            rules={[{ required: true, message: t({ message: 'Dieses Feld ist zwingend' }) }]}
          >
            <InputNumber style={{ width: '100%' }} min={0} />
          </Form.Item>

          <Form.Item
            name="bothGuardiansLiveInHousehold"
            label={
              t({
                message: 'Beide Elternteile leben im Haushalt',
              }) + ' *'
            }
            rules={[{ required: true, message: t({ message: 'Dieses Feld ist zwingend' }) }]}
          >
            <Radio.Group>
              <Radio value={true}>
                <Trans>Ja</Trans>
              </Radio>
              <Radio value={false}>
                <Trans>Nein</Trans>
              </Radio>
            </Radio.Group>
          </Form.Item>

          {watchBothGuardiansLiveInHousehold && (
            <Form.Item
              name="guardiansAreMarried"
              label={
                t({
                  message: 'Beide Elternteile sind miteinander verheiratet',
                }) + ' *'
              }
              rules={[{ required: true, message: t({ message: 'Dieses Feld ist zwingend' }) }]}
            >
              <Radio.Group>
                <Radio value={true}>
                  <Trans>Ja</Trans>
                </Radio>
                <Radio value={false}>
                  <Trans>Nein</Trans>
                </Radio>
              </Radio.Group>
            </Form.Item>
          )}

          <Form.Item
            name="adultNonGuardians"
            label={t({ message: 'Nicht erziehungsberechtigte erwachsene Personen im gleichen Haushalt' }) + ' *'}
            rules={[
              {
                required: true,
                message: t({ message: 'Geben Sie "0" an, falls keine solche Person im Haushalt lebt.' }),
              },
            ]}
          >
            <InputNumber min={0} style={{ width: '100%' }} />
          </Form.Item>

          <Form.Item
            label={t({ message: 'Erwerbspensum Person 1 in %' }) + ' *'}
            name={['person1', 'workload']}
            rules={[
              { required: true, message: t({ message: 'Geben Sie "0" an, falls Person 1 nicht erwerbstätig ist.' }) },
            ]}
          >
            <InputNumber min={0} style={{ width: '100%' }} addonAfter="%" />
          </Form.Item>

          {watchBothGuardiansLiveInHousehold && (
            <>
              <Form.Item
                label={t({ message: 'Erwerbspensum Person 2 in %' }) + ' *'}
                name={['person2', 'workload']}
                rules={[
                  {
                    required: true,
                    message: t({ message: 'Geben Sie "0" an, falls Person 2 nicht erwerbstätig ist.' }),
                  },
                ]}
              >
                <InputNumber min={0} style={{ width: '100%' }} addonAfter="%" />
              </Form.Item>

              <Title level={4} />

              <Form.Item label={t({ message: 'Total Erwerbspensum des Haushaltes' })}>
                <Text>{(watchPerson1Workload ?? 0) + (watchPerson2Workload ?? 0)}%</Text>
              </Form.Item>
            </>
          )}

          <Form.Item label={t({ message: 'Anspruchsberechtigung Subventionierung aufgrund Erwerbstätigkeit' })}>
            <Text>
              {watchBothGuardiansLiveInHousehold
                ? Decimal.max((watchPerson1Workload ?? 0) + (watchPerson2Workload ?? 0) - 100, 0).toNumber()
                : new Decimal(watchPerson1Workload ?? 0).toNumber()}
              %
            </Text>
          </Form.Item>

          <Title level={4}>
            <Trans>Massgebendes Einkommen</Trans>
          </Title>

          <Title level={5}>
            <Trans>Person 1</Trans>
          </Title>

          <Form.Item
            name={['person1', 'totalIncome_190']}
            label={
              t({
                message: 'Total aller Einkünfte gemäss Steuererklärung Code 190',
              }) + ' *'
            }
            extra={t({ message: 'Für Quellensteuerpflichtige Nettolohn II des Lohnausweises' })}
            rules={[{ required: true, message: t({ message: 'Dieses Feld ist zwingend' }) }]}
          >
            <InputNumber min={0} style={{ width: '100%' }} addonAfter="CHF" />
          </Form.Item>

          <Form.Item
            name={['person1', 'netIncomeChanges']}
            label={
              t({
                message: 'Aktuelle Einkommensveränderung netto',
              }) + ' *'
            }
            extra={t({ message: '+Zunahme -Abnahme' })}
            rules={[
              {
                required: true,
                message: t({ message: 'Falls es keine Einkommensänderungen gibt, tragen Sie bitte "0" ein.' }),
              },
            ]}
          >
            <InputNumber style={{ width: '100%' }} addonAfter="CHF" />
          </Form.Item>

          <Form.Item
            name={['person1', 'incomeFromSelfEmployment_115_116']}
            label={
              t({
                message: 'Einkünfte aus selbstständiger Erwerbstätigkeit gemäss Steuererklärung Code 115/116',
              }) + ' *'
            }
            rules={[
              {
                required: true,
                message: t({
                  message: 'Falls in Ihrer Steuererklärung Code 115/116 leer ist, tragen Sie bitte "0" ein.',
                }),
              },
            ]}
            extra={t({ message: '+Zunahme -Abnahme' })}
          >
            <InputNumber style={{ width: '100%' }} addonAfter="CHF" />
          </Form.Item>

          <Form.Item
            name={['person1', 'incomeFromPartnership_125_126']}
            label={
              t({
                message: 'Einkünfte aus Personengesellschaft gemäss Steuererklärung Code 125/126',
              }) + ' *'
            }
            extra={t({ message: '+Zunahme -Abnahme' })}
            rules={[
              {
                required: true,
                message: t({
                  message: 'Falls in Ihrer Steuererklärung Code 125/126 leer ist, tragen Sie bitte "0" ein.',
                }),
              },
            ]}
          >
            <InputNumber style={{ width: '100%' }} addonAfter="CHF" />
          </Form.Item>

          {watchBothGuardiansLiveInHousehold && (
            <>
              <Title level={5}>
                <Trans>Person 2</Trans>
              </Title>

              {!watchGuardiansAreMarried && (
                <Form.Item
                  name={['person2', 'totalIncome_190']}
                  label={
                    t({
                      message: 'Total aller Einkünfte gemäss Steuererklärung Code 190',
                    }) + ' *'
                  }
                  extra={t({ message: 'Für Quellensteuerpflichtige Nettolohn II des Lohnausweises' })}
                  rules={[{ required: true }]}
                >
                  <InputNumber min={0} style={{ width: '100%' }} addonAfter="CHF" />
                </Form.Item>
              )}
              {!watchGuardiansAreMarried && (
                <Form.Item
                  name={['person2', 'netIncomeChanges']}
                  label={
                    t({
                      message: 'Aktuelle Einkommensveränderung netto',
                    }) + ' *'
                  }
                  extra={t({ message: '+Zunahme -Abnahme' })}
                  rules={[{ required: true }]}
                >
                  <InputNumber style={{ width: '100%' }} addonAfter="CHF" />
                </Form.Item>
              )}
              <Form.Item
                name={['person2', 'incomeFromSelfEmployment_115_116']}
                label={
                  t({
                    message: 'Einkünfte aus selbstständiger Erwerbstätigkeit gemäss Steuererklärung Code 115/116',
                  }) + ' *'
                }
                extra={t({ message: '+Zunahme -Abnahme' })}
                rules={[
                  {
                    required: true,
                    message: t({
                      message: 'Falls in Ihrer Steuererklärung Code 115/116 leer ist, tragen Sie bitte "0" ein.',
                    }),
                  },
                ]}
              >
                <InputNumber style={{ width: '100%' }} addonAfter="CHF" />
              </Form.Item>
              <Form.Item
                name={['person2', 'incomeFromPartnership_125_126']}
                label={
                  t({
                    message: 'Einkünfte aus Personengesellschaft gemäss Steuererklärung Code 125/126',
                  }) + ' *'
                }
                extra={t({ message: '+Zunahme -Abnahme' })}
                rules={[
                  {
                    required: true,
                    message: t({
                      message: 'Falls in Ihrer Steuererklärung Code 125/126 leer ist, tragen Sie bitte "0" ein.',
                    }),
                  },
                ]}
              >
                <InputNumber style={{ width: '100%' }} addonAfter="CHF" />
              </Form.Item>
            </>
          )}

          <Title level={5} />

          <Form.Item
            name="yearlyAlimonyPayments_211"
            label={
              t({
                message: 'Effektiv bezahlte Unterhaltsbeiträge gemäss Steuererklärung Code 211',
              }) + ' *'
            }
            rules={[
              {
                required: true,
                message: t({ message: 'Falls in Ihrer Steuererklärung Code 211 leer ist, tragen Sie bitte "0" ein.' }),
              },
            ]}
          >
            <InputNumber min={0} style={{ width: '100%' }} addonAfter="CHF" />
          </Form.Item>

          <Title level={4} />

          {!!income && (
            <Form.Item
              label={
                <Text style={{ fontWeight: 'bold' }}>
                  {t({ message: 'Massgebendes Einkommen für die Berechnung der Elternbeiträge' })}
                </Text>
              }
            >
              <Text style={{ fontWeight: 'bold' }}>{formatChf(income)}</Text>
            </Form.Item>
          )}

          <Title level={4} />

          <Form.Item
            name="contactMeForExtraordinarySubsidy"
            label={
              t({
                message: 'Ich wünsche Kontaktaufnahme von der Gemeinde für eine ausserordentliche Subventionierung',
              }) + ' *'
            }
            rules={[{ required: true, message: t({ message: 'Dieses Feld ist zwingend' }) }]}
          >
            <Radio.Group>
              <Radio value={true}>
                <Trans>Ja</Trans>
              </Radio>
              <Radio value={false}>
                <Trans>Nein</Trans>
              </Radio>
            </Radio.Group>
          </Form.Item>
        </>
      )}

      <ButtonSection
        submitButtonText={submitButtonText}
        onNext={form.submit}
        onPrevious={handlePrevious && handlePrevious}
      />
    </Form>
  )
}

export default TaxInformationForm
