import { t } from '@lingui/macro'
import { Content } from 'antd/es/layout/layout'
import { useEffect } from 'react'
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { FamilyInformation } from '../../../components/family-information-form'
import { NewGuardian } from '../../../components/guardian/guardian-form-fields'
import { Address, SignatureData, TaxInformation } from '../../../types'
import { useInstitutionContext } from '../institution-context'
import AcceptFromWaitingListPage from './accept-from-waiting-list-page'
import BillingAddressFormPage from './form-pages/billing-address-form-page'
import NewChildFormPage from './form-pages/child-form-pages/new-child-form-page'
import ConfirmationFormPage from './form-pages/confirmation-form-page'
import DoneFormPage from './form-pages/done-form-page'
import ErrorFormPage from './form-pages/error-form-page'
import FamilyInformationFormPage from './form-pages/family-information-form-page'
import GuardianFormPage from './form-pages/guardian-form-page'
import ResidenceFormPage from './form-pages/residence-form-page'
import SummaryFormPage from './form-pages/summary-form-page'
import TaxInformationFormPage from './form-pages/tax-information-form-page'
import { useRegistrationContext } from './registration-context'
import { RegistrationErrorPage } from './registration-error-page'

const RegistrationPage = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const { institution } = useInstitutionContext()
  const {
    familyId,
    taxInformation,
    isOutOfTown,
    getGuardian1Data,
    getGuardian2Data,
    setGuardian1Data,
    setGuardian2Data,
    soleCustodyFileContent,
    setSoleCustody,
    getChildren,
    addChild,
    getGuardianAddresses,
    getBillingAddress,
    setBillingAddress,
    getSignatureData,
    setSignatureData,
    getTaxInformation,
    setTaxInformation,
    getFamilyInformation,
    setFamilyInformation,
    getFamily,
    setOutOfTown,
  } = useRegistrationContext()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  const routesBeforeSummary: { path: string; active: () => boolean }[] = [
    { path: 'person1', active: () => true },
    { path: 'person2', active: () => getGuardian1Data()?.soleCustody === false },
    { path: 'tax-information', active: () => institution?.features.subsidiesHuenenberg === true },
    {
      path: 'residence',
      active: () =>
        institution?.registrationConfig.allowOutOfTownChildrenForHolidayCare === true &&
        institution?.features.subsidiesHuenenberg !== true,
    },
    {
      path: 'family-information',
      active: () => institution?.features.surbtalPricing === true || institution?.features.ennetbadenPricing === true,
    },
    { path: 'add-child/base-information', active: () => true },
    { path: 'billing-address', active: () => true },
    { path: 'summary', active: () => true },
    { path: 'confirmation', active: () => true },
  ]

  const getActiveRoutes = () => routesBeforeSummary.filter((route) => route.active())
  const getActiveRouteIndex = () => getActiveRoutes().findIndex((route) => location.pathname.endsWith(`/${route.path}`))
  const getLastActiveRoute = () => getActiveRoutes()[getActiveRoutes().length - 1]
  const getNextRoute = () => getActiveRoutes()[getActiveRouteIndex() + 1]
  const getPrevRoute = () => getActiveRoutes()[getActiveRouteIndex() - 1] ?? getLastActiveRoute()

  const navigateToNextPage = () => {
    const activeRouteIndex = getActiveRouteIndex()
    if (activeRouteIndex >= 0 && activeRouteIndex < getActiveRoutes().length - 1) {
      navigate(getNextRoute().path)
    }
  }

  const navigateToPrevPage = () => {
    if (getActiveRouteIndex() > 0) {
      navigate(getPrevRoute().path)
    }
  }

  const isModuleSelectionPage = location.pathname.endsWith('add-child/modules')

  const person1SubmitHandler = async (data: NewGuardian) => {
    setSoleCustody(data.soleCustody)
    setGuardian1Data(data)
    navigateToNextPage()
  }

  const person2SubmitHandler = async (data: NewGuardian) => {
    setGuardian2Data(data)
    navigateToNextPage()
  }
  const person2PreviousHandler = () => {
    navigateToPrevPage()
  }

  const taxInformationSubmitHandler = async (data: TaxInformation) => {
    setTaxInformation(data)
    setOutOfTown(false)
    navigateToNextPage()
  }
  const taxInformationPreviousHandler = async () => {
    navigateToPrevPage()
  }

  const outOfTownChildSubmitHandler = async () => {
    setOutOfTown(true)
    setTaxInformation(undefined)
    navigateToNextPage()
  }

  const residenceSubmitHandler = async () => {
    setOutOfTown(false)
    navigateToNextPage()
  }
  const residencePreviousHandler = async () => {
    navigateToPrevPage()
  }

  const familyInformationSubmitHandler = async (data: FamilyInformation) => {
    setFamilyInformation(data)
    navigateToNextPage()
  }

  const familyInformationPreviousHandler = async () => {
    navigateToPrevPage()
  }

  const addChildHandler = () => {
    navigate('add-child/base-information')
  }
  const newChildPreviousHandler = () => {
    if (getChildren().length > 0) {
      navigate('summary')
      return
    } else {
      navigateToPrevPage()
    }
  }

  const billingAddressSubmitHandler = (data: Address) => {
    setBillingAddress(data)
    navigateToNextPage()
  }

  const summarySubmitHandler = async () => {
    navigateToNextPage()
  }

  const confirmationSubmitHandler = async (data: SignatureData) => {
    setSignatureData(data)

    const body = JSON.stringify({
      family: getFamily(),
      familyInformation: getFamilyInformation(),
      soleCustodyFileContent: soleCustodyFileContent(),
      signatureData: getSignatureData(),
      taxInformation: getTaxInformation(),
    })
    const response = await fetch(
      `${process.env.REACT_APP_LEOBA_SERVER}/api/eltern-app/${institution?.id}/registration/${familyId}/anonymous`,
      {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
        },
        body,
      }
    )

    if (response.ok) {
      navigate('done')
    } else {
      navigate('error')
    }
  }
  const confirmationPreviousHandler = () => {
    navigateToPrevPage()
  }

  const allGuardiansRecorded = !!getGuardian1Data()?.soleCustody || !!getGuardian2Data()
  const anyChildRecorded = !!getChildren().length
  const residenceInfoNeeded =
    institution?.registrationConfig.allowOutOfTownChildrenForHolidayCare &&
    institution?.features.subsidiesHuenenberg !== true
  const familyInformationNeeded =
    institution?.features.surbtalPricing === true || institution?.features.ennetbadenPricing === true

  const residenceOrTaxInfoNotNeededOrRecorded = () => {
    if (institution?.features.subsidiesHuenenberg === true) {
      return !!getTaxInformation() || isOutOfTown === true
    } else if (institution?.registrationConfig.allowOutOfTownChildrenForHolidayCare) {
      return isOutOfTown !== undefined
    } else {
      return true
    }
  }

  return (
    <Content style={{ padding: '0 1.2rem', maxWidth: isModuleSelectionPage ? '90em' : '60em', marginInline: 'auto' }}>
      <div className="site-layout-content">
        <Outlet />
        <Routes>
          <Route
            path="person1"
            element={
              <GuardianFormPage
                title={t({ message: 'Erziehungsberechtigte Person erfassen' })}
                isSecondParent={false}
                handleSubmit={person1SubmitHandler}
                defaultValues={getGuardian1Data()}
                registrationConfiguration={institution!.registrationConfig}
              />
            }
            errorElement={<RegistrationErrorPage />}
          />
          <Route
            path="person2"
            element={
              getGuardian1Data() && !getGuardian1Data()?.soleCustody ? (
                <GuardianFormPage
                  title={t({ message: 'Zweite Erziehungsberechtigte Person erfassen' })}
                  isSecondParent={true}
                  handlePrevious={person2PreviousHandler}
                  handleSubmit={person2SubmitHandler}
                  defaultValues={getGuardian2Data()}
                  guardianBluePrint={getGuardian1Data()}
                  registrationConfiguration={institution!.registrationConfig}
                />
              ) : (
                <Navigate to={`../${getPrevRoute().path}`} replace />
              )
            }
            errorElement={<RegistrationErrorPage />}
          />
          <Route
            path="tax-information"
            element={
              institution?.features.subsidiesHuenenberg === true && allGuardiansRecorded ? (
                <TaxInformationFormPage
                  defaultValues={getTaxInformation()}
                  defaultOutOfTown={isOutOfTown}
                  familyId={familyId}
                  handlePrevious={taxInformationPreviousHandler}
                  handleSubmit={taxInformationSubmitHandler}
                  handleOutOfTownChild={outOfTownChildSubmitHandler}
                />
              ) : (
                <Navigate to={`../${getPrevRoute().path}`} replace />
              )
            }
            errorElement={<RegistrationErrorPage />}
          />
          <Route
            path="residence"
            element={
              residenceInfoNeeded && allGuardiansRecorded ? (
                <ResidenceFormPage
                  defaultOutOfTown={isOutOfTown}
                  handlePrevious={residencePreviousHandler}
                  handleSubmit={residenceSubmitHandler}
                  handleOutOfTownChild={outOfTownChildSubmitHandler}
                />
              ) : (
                <Navigate to={`../${getPrevRoute().path}`} replace />
              )
            }
            errorElement={<RegistrationErrorPage />}
          />
          <Route
            path="family-information"
            element={
              familyInformationNeeded ? (
                <FamilyInformationFormPage
                  defaultValues={getFamilyInformation()}
                  handleSubmit={familyInformationSubmitHandler}
                  handlePrevious={familyInformationPreviousHandler}
                />
              ) : (
                <Navigate to={`../${getPrevRoute().path}`} replace />
              )
            }
            errorElement={<RegistrationErrorPage />}
          />
          <Route
            path="add-child/*"
            element={
              allGuardiansRecorded && residenceOrTaxInfoNotNeededOrRecorded() ? (
                <NewChildFormPage
                  handleSubmit={addChild}
                  handlePrevious={newChildPreviousHandler}
                  taxInformation={taxInformation}
                />
              ) : (
                <Navigate to={`../${getPrevRoute().path}`} replace />
              )
            }
            errorElement={<RegistrationErrorPage />}
          />
          <Route
            path="billing-address"
            element={
              anyChildRecorded ? (
                <BillingAddressFormPage
                  defaultValues={getBillingAddress()}
                  familyId={familyId}
                  guardianAddresses={getGuardianAddresses()}
                  handleSubmit={billingAddressSubmitHandler}
                />
              ) : (
                <Navigate to={`../${getPrevRoute().path}`} replace />
              )
            }
            errorElement={<RegistrationErrorPage />}
          />
          <Route
            path="summary"
            element={
              allGuardiansRecorded && anyChildRecorded ? (
                <SummaryFormPage addChildHandler={addChildHandler} handleSubmit={summarySubmitHandler} />
              ) : (
                <Navigate to={`../${getPrevRoute().path}`} replace />
              )
            }
            errorElement={<RegistrationErrorPage />}
          />
          <Route
            path="confirmation"
            element={
              allGuardiansRecorded && anyChildRecorded ? (
                <ConfirmationFormPage
                  guardian1={getGuardian1Data()}
                  guardian2={getGuardian2Data()}
                  handleSubmit={confirmationSubmitHandler}
                  handlePrevious={confirmationPreviousHandler}
                  defaultValues={getSignatureData()}
                />
              ) : (
                <Navigate to={`../${getPrevRoute().path}`} replace />
              )
            }
            errorElement={<RegistrationErrorPage />}
          />
          <Route path="done" element={<DoneFormPage />} errorElement={<RegistrationErrorPage />} />
          <Route path="error" element={<ErrorFormPage />} errorElement={<RegistrationErrorPage />} />
          <Route
            path="accept-from-waitinglist"
            element={<AcceptFromWaitingListPage />}
            errorElement={<RegistrationErrorPage />}
          />
          <Route path="*" element={<>404</>} errorElement={<RegistrationErrorPage />} />
        </Routes>
      </div>
    </Content>
  )
}

export default RegistrationPage
