import { Institution } from '../types'

const findModuleNameFromInstitution = (moduleId: string, institution: Institution) => {
  const location = institution.locations.find((l) => l.modules.find((m) => m.id === moduleId))
  if (!location) throw new Error('no Location with module Id ' + moduleId)
  const module = location.modules.find((m) => m.id === moduleId)
  if (!module) throw new Error('no module with module Id ' + moduleId)
  return module.name
}

export default findModuleNameFromInstitution
