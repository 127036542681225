import { t } from '@lingui/macro'
import { NewGuardian } from '../components/guardian/guardian-form-fields'

const translateContactPreference = (
  guardians: (NewGuardian | undefined)[],
  preference?: string
): string | undefined => {
  if (!preference) {
    return undefined
  }

  const preferredGuardian = guardians.find((guardian) => guardian?.id === preference)

  if (preferredGuardian) {
    return `${preferredGuardian.address.firstName} ${preferredGuardian.address.lastName}`
  }

  return t({ message: 'Beide' })
}

export default translateContactPreference
