import { BookingMutation } from '../components/guardian-page/booking-mutation-card'
import { Child, Institution, ModuleMutation } from '../types'
import findAgeGroupNameFromInstitution from './age-group-name-from-institution'
import findModuleNameFromInstitution from './module-name-from-institution'

const moduleMutationToBookingMutation = (
  moduleMutation: ModuleMutation,
  institution: Institution,
  currentChild?: Child
): BookingMutation => ({
  id: moduleMutation.id,
  contractId: moduleMutation.contractId,
  effectiveAt: moduleMutation.effectiveAt,
  title: findModuleNameFromInstitution(moduleMutation.moduleId, institution),
  date: moduleMutation.effectiveAt,
  comment: moduleMutation.comment,
  approvalState: moduleMutation.approvalState,
  approvedNote: moduleMutation.approvedNote,
  mutationType: moduleMutation.mutationType,
  reason: moduleMutation.reason,
  ageGroupName: findAgeGroupNameFromInstitution(
    moduleMutation.ageGroupId || currentChild?.contracts.find(({ state }) => state === 'accepted')?.ageGroupId || '',
    institution
  ),
})

export default moduleMutationToBookingMutation
