import { Trans } from '@lingui/macro'
import { Typography } from 'antd'
import { useEffect } from 'react'
import { isRouteErrorResponse, useLocation, useRouteError } from 'react-router-dom'
import { useRegistrationContext } from './registration-context'
import { useNewChildContext } from './form-pages/child-form-pages/new-child-context'

const { Title, Paragraph } = Typography

export const RegistrationErrorPage = () => {
  const location = useLocation()
  const error = useRouteError()

  useEffect(() => {
    useRegistrationContext.getState().reset()
    useNewChildContext.getState().reset()
  }, [])

  useEffect(() => {
    console.error('Error causing the reload', error)

    let errorMessage = 'Unknown error'

    if (isRouteErrorResponse(error)) {
      // error is type `ErrorResponse`
      errorMessage = error.statusText
    } else if (error instanceof Error) {
      errorMessage = error.message
    } else if (typeof error === 'string') {
      errorMessage = error
    }

    fetch(`${process.env.REACT_APP_LEOBA_SERVER}/api/eltern-app/log?path=${location.pathname}`, {
      method: 'PUT',
      body: errorMessage,
    })

    const clearedTimestamp = parseInt(localStorage.getItem('clearedTimestamp') || '0')
    const newTimestamp = Date.now()
    if (newTimestamp - clearedTimestamp > 10000) {
      console.log('clearing local storage and refreshing')
      localStorage.clear()
      localStorage.setItem('clearedTimestamp', newTimestamp.toString())
      window.location.reload()
    }
  }, [error, location.pathname])
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '2em', gap: '2em' }}>
      <Title>
        <Trans>Es tut uns leid...</Trans>
      </Title>
      <img
        style={{ width: '80%', maxWidth: '20em', height: 'auto' }}
        src="/fixing-bug.png"
        alt="Indicating that ther was a bug in the software"
      />
      <Paragraph>
        <Trans>
          Leider ist ein Fehler aufgetreten. Bitte entschuldigen Sie die Umstände. Wir haben die Fehlermeldung in einer
          anonymen Form an unseren Server geschickt und kümmern uns darum.
        </Trans>
      </Paragraph>
    </div>
  )
}
