import { t } from '@lingui/macro'
import { Button, Typography } from 'antd'
import { Layout } from 'antd'

import Title from 'antd/es/typography/Title'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useInstitutionContext } from '../institution-context'

const { Content } = Layout
const { Paragraph } = Typography

const AcceptFromWaitingListPage = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { institution } = useInstitutionContext()

  const contractId = searchParams.get('contractId')

  if (!contractId) {
    navigate('/auth')
  }

  const acceptFromWaitingList = async () => {
    if (institution) {
      const response = await fetch(
        `${process.env.REACT_APP_LEOBA_SERVER}/api/eltern-app/${institution.id}/registration/contract/${contractId}/accept-or-decline-contract?decision=accepted`,
        {
          method: 'PUT',
          headers: { accept: 'application/json' },
        }
      )
      if (response.ok) {
        navigate('/auth')
      } else {
        console.log('Error while updating contract')
        navigate('error')
      }
    }
  }

  const cancelFromWaitlingList = async () => {
    if (institution) {
      const response = await fetch(
        `${process.env.REACT_APP_LEOBA_SERVER}/api/eltern-app/${institution.id}/registration/contract/${contractId}/accept-or-decline-contract?decision=cancelled`,
        {
          method: 'PUT',
          headers: { accept: 'application/json' },
        }
      )
      if (response.ok) {
        navigate('/')
      } else {
        console.log(JSON.stringify(response))
        navigate('error')
      }
    }
  }

  return (
    <Content style={{ width: 'fit-content', marginInline: 'auto', padding: '0 50px' }}>
      <div className="site-layout-content">
        <Title level={2}>{t({ message: 'Anmeldung aus Warteliste bestätigen' })}</Title>
        <Paragraph
          style={{
            whiteSpace: 'pre-wrap',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Button onClick={cancelFromWaitlingList}>{t({ message: 'Anmeldung ablehnen' })}</Button>
          <Button type="primary" onClick={acceptFromWaitingList}>
            {t({ message: 'Anmeldung bestätigen und registrieren' })}
          </Button>
        </Paragraph>
      </div>
    </Content>
  )
}

export default AcceptFromWaitingListPage
