import { Institution, RegistrationConfiguration } from '../../types'
import GuardianFormFields, { NewGuardian } from './guardian-form-fields'

type Props = {
  handleSubmit: (data: NewGuardian) => Promise<void>
  defaultValues?: NewGuardian
  handlePrevious?: () => void
  isSecondParent: boolean
  submitButtonText?: string
  familyId: string
  soleCustodyLockedIn?: boolean
  isRegistration?: boolean
  registrationConfiguration: RegistrationConfiguration
  institutionFeatures?: Institution['features']
}

const GuardianForm1 = ({
  handleSubmit,
  isSecondParent,
  familyId,
  handlePrevious,
  submitButtonText,
  defaultValues,
  soleCustodyLockedIn,
  isRegistration = false,
  registrationConfiguration,
  institutionFeatures,
}: Props) => (
  <GuardianFormFields
    familyId={familyId}
    handleSubmit={handleSubmit}
    isSecondParent={isSecondParent}
    handlePrevious={handlePrevious}
    submitButtonText={submitButtonText}
    soleCustodyLockedIn={soleCustodyLockedIn}
    defaultValues={defaultValues}
    isRegistration={isRegistration}
    registrationConfiguration={registrationConfiguration}
    institutionFeatures={institutionFeatures}
  />
)

export default GuardianForm1
