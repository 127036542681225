import { Progress } from 'antd'
import { Typography } from 'antd'
const { Title } = Typography

type Props = {
  children: JSX.Element
  title: string
  progress?: number
}

const FormPage = ({ children, title, progress }: Props) => (
  <div style={{ minWidth: 'fit-content' }}>
    <Title level={2}>{title}</Title>
    {!!progress && <Progress percent={(100 / 9) * progress} showInfo={false} strokeColor="var(--main-accent-color)" />}
    <>{children}</>
  </div>
)

export default FormPage
