import { z } from 'zod'
import { DecimalSchema } from '../../../helper/common-zod-schemas'
import { Guardian, TaxInformation } from '../../../types'

export const loadTaxInformation = async (guardian: Guardian & { institutionId: string }) => {
  const response = await fetch(
    `${process.env.REACT_APP_LEOBA_SERVER}/api/eltern-app/${guardian.institutionId}/guardian/tax-information-data?familyId=${guardian?.familyId}`,
    {
      headers: { accept: 'application/json' },
    }
  )

  if (response.ok) {
    return TaxInformationSchema.parse(await response.json())
  } else {
    return undefined
  }
}

export const persistTaxInformation = async (props: {
  institutionId: string
  familyId: string
  taxInformation: TaxInformation
}) => {
  const body = JSON.stringify(props.taxInformation)
  fetch(
    `${process.env.REACT_APP_LEOBA_SERVER}/api/eltern-app/${props.institutionId}/guardian/tax-information-data?familyId=${props.familyId}`,
    {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
      },
      body,
    }
  )
}

const PersonSchema = z.object({
  id: z.string(),
  workload: DecimalSchema,
  totalIncome_190: DecimalSchema,
  netIncomeChanges: DecimalSchema,
  incomeFromSelfEmployment_115_116: DecimalSchema,
  incomeFromPartnership_125_126: DecimalSchema,
})

const TaxInformationSchema = z.object({
  id: z.string(),
  familyId: z.string(),
  residence: z.enum(['huenenberg', 'cham']),
  contactMeForExtraordinarySubsidy: z.boolean().optional(),
  netWorthExceedsLimit: z.boolean().optional(),
  waiveSubsidies: z.boolean().optional(),
  adultNonGuardians: z.number().optional(),
  childrenDeducedInTaxes: z.number().optional(),
  yearlyAlimonyPayments_211: DecimalSchema,
  bothGuardiansLiveInHousehold: z.boolean().optional(),
  guardiansAreMarried: z.boolean().optional(),
  person1: PersonSchema.optional(),
  person2: PersonSchema.optional(),
  amount: DecimalSchema.optional(),
  createdAt: z.preprocess(() => {
    return new Date()
  }, z.date()),
})
