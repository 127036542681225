import { HomeOutlined } from '@ant-design/icons'
import { t } from '@lingui/macro'
import { Menu } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useGuardianContext } from '../../routes/institution/guardian/guardian-context'

const GuardianNavigation = () => {
  const navigate = useNavigate()
  const { currentChild } = useGuardianContext()

  const routes: { key: string; label: JSX.Element | string; route: string }[] = [
    {
      key: '1',
      label: <HomeOutlined />,
      route: 'home',
    },
    {
      key: '2',
      label: t({ message: 'Familie' }),
      route: 'family',
    },
  ]

  if (currentChild && !currentChild.isOutOfTown) {
    routes.push({
      key: '3',
      label: t({ message: 'Abmeldungen' }),
      route: 'cancellations',
    })
  }
  routes.push({
    key: '4',
    label: t({ message: 'Zusatzbuchungen' }),
    route: 'bookings',
  })

  const navigationItems = routes.map((route) => ({
    key: route.key,
    label: route.label,
    onClick: () => navigate(route.route),
  }))

  const selectedKeys = routes
    .filter((route) => window.location.pathname.includes(route.route))
    .map((route) => route.key)

  return <Menu mode="horizontal" items={navigationItems} selectedKeys={selectedKeys} />
}

export default GuardianNavigation
