import { ExclamationCircleOutlined } from '@ant-design/icons'

const ErrorMessage = ({ message, ...props }: { message: string }) => (
  <div {...props}>
    <ErrorMark />
    <small style={{ fontStyle: 'italic', color: 'red' }}>{message}</small>
  </div>
)

export const ErrorMark = ({ ...props }) => (
  <ExclamationCircleOutlined style={{ color: 'red', marginRight: '0.5em', ...props.style }} />
)

export default ErrorMessage
