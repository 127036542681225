import { Trans } from '@lingui/macro'
import { Field } from './form/field'

type Props = {
  label: string
  value: boolean | undefined
}

const YesNoTranslatedField = ({ label, value }: Props) => (
  <>
    {value !== undefined && (
      <Field label={label}>
        <>{value === true ? <Trans>Ja</Trans> : <Trans>Nein</Trans>}</>
      </Field>
    )}
  </>
)

export default YesNoTranslatedField
