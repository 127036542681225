import { t } from '@lingui/macro'
import { Button, Row } from 'antd'
import dayjs from 'dayjs'
import { useState } from 'react'

type Props = {
  data: any[]
  dateTimeKey: string
  mapFunction: (info: any) => JSX.Element
  noDataText: string
}

const ExpandableInfoList = ({ data, dateTimeKey, mapFunction, noDataText }: Props) => {
  const [showAll, setShowAll] = useState(false)
  const shownData = data
    .sort((a, b) => new Date(b[dateTimeKey]).getTime() - new Date(a[dateTimeKey]).getTime())
    .filter((info, index) => (showAll ? true : index < 5 || +new Date(info[dateTimeKey]) >= +dayjs().startOf('date')))
  return (
    <>
      {data.length > 0 ? shownData.map(mapFunction) : <div>{noDataText}</div>}
      {!showAll && shownData.length < data.length && (
        <Row style={{ display: 'flex', justifyContent: 'center', marginTop: '1em' }}>
          <Button onClick={() => setShowAll(true)}>{t({ message: 'Alle anzeigen' })}</Button>
        </Row>
      )}
    </>
  )
}

export default ExpandableInfoList
