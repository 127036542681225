import { nanoid } from 'nanoid'
import create from 'zustand'
import { persist } from 'zustand/middleware'
import { NewChildBaseInformation } from '../../../../../components/child/child-form/child-base-information-form'
import { NewChildFurtherInformation } from '../../../../../components/child/child-form/child-further-information-form'
import { NewHolidayCareBookingType } from '../../../../../components/child/child-form/child-holiday-care-form'
import { NewChildImportantInformation } from '../../../../../components/child/child-form/child-important-information-form'
import { Address } from '../../../../../types'
import { ModuleBooking } from '../../registration-context'

export type NewChild = {
  id: string
  baseInformation: NewChildBaseInformation
  importantInformation: NewChildImportantInformation
  furtherInformation: NewChildFurtherInformation
  moduleBooking?: ModuleBooking
  holidayCareBookings?: NewHolidayCareBookingType[]
}

type State = {
  id: string
  newChildBaseInformation: NewChildBaseInformation | undefined
  newChildImportantInformation: NewChildImportantInformation | undefined
  newChildFurtherInformation: NewChildFurtherInformation | undefined
  familyId: string | undefined
  guardianAddresses: Address[]
  moduleBooking: ModuleBooking | undefined
  holidayCareBookings: NewHolidayCareBookingType[]
}

type Actions = {
  getChild: () => NewChild
  getBaseInformationData: () => NewChildBaseInformation | undefined
  getImportantInformationData: () => NewChildImportantInformation | undefined
  getFurtherInformationData: () => NewChildFurtherInformation | undefined
  getModulesData: () => ModuleBooking | undefined
  setBaseInformationData: (data: NewChildBaseInformation) => void
  setImportantInformationData: (data: NewChildImportantInformation) => void
  setFurtherInformationData: (data: NewChildFurtherInformation) => void
  setModulesData: (data: ModuleBooking) => void
  setHolidayCareData: (data: NewHolidayCareBookingType[]) => void
  reset: () => void
  resetForNextChild: () => void
}

const getInitialState = (): State => {
  return {
    id: nanoid(),
    newChildBaseInformation: undefined,
    newChildImportantInformation: undefined,
    newChildFurtherInformation: undefined,
    familyId: undefined,
    moduleBooking: undefined,
    holidayCareBookings: [],
    guardianAddresses: [],
  }
}

export const useNewChildContext = create<State & Actions>()(
  persist(
    (set, get) => ({
      ...getInitialState(),
      getBaseInformationData: (): NewChildBaseInformation | undefined => {
        return get().newChildBaseInformation
      },
      getImportantInformationData: (): NewChildImportantInformation | undefined => {
        return get().newChildImportantInformation
      },
      getFurtherInformationData: (): NewChildFurtherInformation | undefined => {
        return get().newChildFurtherInformation
      },
      getModulesData: (): ModuleBooking | undefined => {
        return get().moduleBooking
      },
      setBaseInformationData: (data: NewChildBaseInformation) =>
        set(() => {
          return {
            newChildBaseInformation: data,
          }
        }),
      setImportantInformationData: (data: NewChildImportantInformation) =>
        set(() => {
          return {
            newChildImportantInformation: data,
          }
        }),
      setFurtherInformationData: (data: NewChildFurtherInformation) =>
        set(() => {
          return {
            newChildFurtherInformation: data,
          }
        }),
      setModulesData: (data: ModuleBooking) =>
        set(() => {
          return {
            moduleBooking: data,
          }
        }),
      setHolidayCareData: (data: NewHolidayCareBookingType[]) =>
        set(() => {
          return {
            holidayCareBookings: data,
          }
        }),
      getChild: (): NewChild => {
        const baseInformation = get().newChildBaseInformation
        const importantInformation = get().newChildImportantInformation
        const furtherInformation = get().newChildFurtherInformation
        const moduleBooking = get().moduleBooking
        if (
          baseInformation &&
          importantInformation &&
          furtherInformation &&
          (moduleBooking || baseInformation.isOutOfTown)
        ) {
          return {
            id: get().id,
            baseInformation,
            importantInformation,
            furtherInformation,
            moduleBooking,
          }
        } else {
          throw new Error('incomplete Child')
        }
      },
      reset: () => {
        set(getInitialState())
      },
      resetForNextChild: () => {
        const currentState = get()
        const state = getInitialState()
        state.newChildBaseInformation = {
          lastName: currentState.newChildBaseInformation?.lastName,
          nationality: currentState.newChildBaseInformation?.nationality,
          familyLanguage: currentState.newChildBaseInformation?.familyLanguage,
          healthInsuranceName: currentState.newChildBaseInformation?.healthInsuranceName,
          liablityInsuranceName: currentState.newChildBaseInformation?.liablityInsuranceName,
          familyDoctorName: currentState.newChildBaseInformation?.familyDoctorName,
          familyDoctorAddress: currentState.newChildBaseInformation?.familyDoctorAddress,
          familyDoctorPhone: currentState.newChildBaseInformation?.familyDoctorPhone,
          dentistName: currentState.newChildBaseInformation?.dentistName,
          dentistAddress: currentState.newChildBaseInformation?.dentistAddress,
        } as NewChildBaseInformation
        state.newChildFurtherInformation = {
          emergencyContactFirstName: currentState.newChildFurtherInformation?.emergencyContactFirstName || '',
          emergencyContactLastName: currentState.newChildFurtherInformation?.emergencyContactLastName || '',
          emergencyContactPhone: currentState.newChildFurtherInformation?.emergencyContactPhone || '',
          contactPreference: currentState.newChildFurtherInformation?.contactPreference,
          emergencyContactRemarks: currentState.newChildFurtherInformation?.emergencyContactRemarks,
        }
        set(state)
      },
    }),
    { name: 'registration-new-child' }
  )
)
